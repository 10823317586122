import { types } from "../types";

const initialState = {
    stateCertification: null,
    stateStatus: null,
    paySchedule: null
}

const editEmployeePayrollReducer = (state= initialState, action)=> {
    switch (action.type) {
        case types.EDIT_EMPLOYEE_PAYROLL:
            
            return {
                ...state,
                stateCertification: action.stateCertification,
                stateStatus: action.stateStatus,
                paySchedule: action.paySchedule
            }
    
        default:
            return state
    }
}
export default editEmployeePayrollReducer