// @vendors
import React, {useState} from 'react';
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Link,
  Paper,
  Toolbar,
} from '@mui/material';
import {useLocation} from 'react-router-dom';

// @components
import CreateEmployee from '../Employer/components/CreateEmployee/CreateEmployee';
import AccountCreated from "./AccountCreated";

// @assets
import {MultiQuestStyles} from "../../assets/css/questionnaire-style";
import logo from '../../assets/images/logoDashboardPublic.svg';
import { ROUTENAME } from '../../navigation/RouteName';

const ExternalEmployer = () => {
  const location = useLocation();
  let token = location.search.split('=')[1] ?? '';
  const [message, setMessage] = useState(null);
  const styles = MultiQuestStyles();

  if (!token) {
    return null;
  } else {
    token = decodeURIComponent(token);
  }
  
  return (
    <Box>
       <>
      <CssBaseline />
      <AppBar position='fixed' sx={styles.appBar}>
        <Toolbar disableGutters sx={styles.toolbar}>
          <Link href={`${ROUTENAME.dashboard}?page=1`} sx={{ display: 'flex' }}>
            <img src={logo} alt='logo' />
          </Link>
        </Toolbar>
      </AppBar>
    </>
      <Container>
        <Paper elevation={4} sx={styles.paper}>
          {
            message?.link ? <AccountCreated message={message}/> :
              <CreateEmployee
                token={token}
                setMessage={setMessage}
              />
          }
        </Paper>
      </Container>
    </Box>
  );
};

export default ExternalEmployer;
