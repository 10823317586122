import { createTheme } from '@mui/material';

export let theme = createTheme({
  root: {
    padding: { xl: '0 192px', md: '0 64px' },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#80BB57',
      text: '#101010',
    },
    secondary: {
      main: '#2A7FEF',
    },
    error: {
      main: '#FF4238',
    },
    success: {
      main: '#15C15D',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: '4.8rem',
    },
    h2: {
      fontFamily: 'Nunito',
      fontSize: '2.8rem',
      fontWeight: 600,
    },
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeightLight: 300,
    body1: {
      fontSize: '2.4rem',
      lineHeight: 1.25,
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
      fontSize: '2.2rem',
    },
    h3: {
      fontSize: '2.2rem',
    },
    h6: {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
  },
  focused: {},
  disabled: {},
  error: {},
  overrides: {
    MuiAlert: {
      standardInfo: {
        backgroundColor: 'transparent',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #484646',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '1px solid #484646',
        },
        '&:focused:after': {
          borderBottom: '1px solid #484646',
        },
      },
    },
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#FFF',
        color: '#000',
      },
    },
  },
});

theme.components.MuiToolbar = {
  [theme.breakpoints.down('md')]: { alignItems: 'space-evenly' },
};

theme.typography.h2 = {
  [theme.breakpoints.down('lg')]: { fontSize: '22px' },
};
