// @vendors
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @state
import { useUI } from '../../../app/context/ui';

// @assets
import { StatsStyles } from '../../../assets/css/statsBoxes-style';

// @components
import {
  AssignmentTurnedInOutlinedIcon,
  Button,
  Card,
  CardContent,
  Grid,
  PersonAddAlt1OutlinedIcon,
  PersonOutlineOutlinedIcon,
  PersonRemoveOutlinedIcon,
  Typography,
} from '../../../components/shared/MaterialUI';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @services
import StatsServiceNewApi from '../../../services/newApi/StatsServiceNewApi';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

export const StatsBoxes = () => {
  const styles = StatsStyles();
  const [stats, setStats] = useState({});
  const statsServicesNewApi = new StatsServiceNewApi();
  const { snackbarUI } = useUI();
  const history = useHistory()
  const location = useLocation()
  const organizationId = useSelector((state) => state?.organizationReducer?.organizationSelected?.id)

  const getStats = async () => {
    try {
      const response = await statsServicesNewApi.getStats(organizationId, '');
      const data = response?.data?.data;
      setStats({ ...data });
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const activateFocusAdmin = () => {
    const input = document.getElementById('searchCompany')
    if(location?.pathname === ROUTENAME.dashboard)return input?.focus()
    else{
      history.push(ROUTENAME.dashboard)
      input?.focus()
    }
  }

  const activateFocusEmployee = () => {
    const input = document.getElementById('searchByEmployee')
    if(location?.pathname === ROUTENAME.searchEmployees)return input?.focus()
    else{
      history.push(ROUTENAME.searchEmployees)
      input?.focus()
    }
  }

  return (
    <Grid
      component={'section'}
      disableGutters 
      sx={styles.container}
    >
      <Card elevation={3} sx={styles.box}>
        <Grid sx={styles.gridCard}>
          <Grid sx={styles.gridCardText} >
            <PersonOutlineOutlinedIcon sx={styles.icon}/>
            <CardContent>
              <Typography sx={styles.statsTitle}>Number of employers</Typography>
              <Typography sx={styles.statsData}>{stats.employers || '0'}</Typography>
            </CardContent>
          </Grid>
          <Button
            variant='outlined'
            size='large'
            sx={styles.buttonSearch}
            onClick={() => activateFocusAdmin()}
          >
            Search
          </Button>
        </Grid>
      </Card>
      <Card elevation={3} sx={styles.box}>
        <Grid sx={styles.gridCard}>
          <Grid sx={styles.gridCardText}>
            <PersonAddAlt1OutlinedIcon sx={styles.icon} />
            <CardContent>
              <Typography sx={styles.statsTitle}>Number of employees</Typography>
              <Typography sx={styles.statsData}>{stats.employees || '0'}</Typography>
            </CardContent>
          </Grid>
          <Button
            variant='outlined'
            size='large'
            sx={styles.buttonSearch}
            onClick={() => activateFocusEmployee()}
          >
            Search
          </Button>
        </Grid>
      </Card>
      <Card elevation={3} sx={styles.box}>
        <AssignmentTurnedInOutlinedIcon sx={styles.icon} />
        <CardContent>
          <Typography sx={styles.statsTitle}>Completed questionnaires</Typography>
          <Typography sx={styles.statsData}>{stats.questionnaireCompleted || '0'}</Typography>
        </CardContent>
      </Card>
      <Card elevation={3} sx={styles.box}>
        <PersonRemoveOutlinedIcon sx={styles.icon} />
        <CardContent>
          <Typography sx={styles.statsTitle}>Abandoned questionnaires</Typography>
          <Typography sx={styles.statsData}>{stats.questionnaireAbandoned || '0'}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
