// @vendors
import React, { useEffect, useState } from 'react'
import { Box, Container } from '@mui/system'
import {Form, Formik} from "formik";
import * as Yup from "yup";
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Button, Paper, Typography } from '@mui/material';

// @assets
import {
    CheckboxStyles,
    MultiQuestStyles,
    QustionVeteranStyles,
    Styles,
} from '../../../assets/css/questionnaire-style';
import logo from '../../../assets/images/logoDashboardPublic.svg';

// @context
import { useUI } from '../../../app/context/ui';

// @services
import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';

// components
import { AppCheckbox } from '../../../components/forms';
import AppHelper from '../../../helpers/AppHelper';

const QuestionForVeteran = () => {
    const styles = Styles();
    const style = QustionVeteranStyles()
    const multiQuestStyles = MultiQuestStyles()
    const checkboxStyles = CheckboxStyles()
    const { blockUI, snackbarUI } = useUI();
    const newQuestionService = new QuestionnaireServiceNewApi()
    const [companyLogo, setCompanyLogo] = useState()
    const {hash} = useParams()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang');
    const questionLang = (lang === 'es') ? '¿Quieres subir tu DD214?': 'Do you want to upload your DD214?';
    const history = useHistory();
    const [btnDisabled, setBtnDisabled] = useState(false)

    const validationSchema = Yup.object().shape({
        document: Yup.string()
          .required('This field is required')
          .oneOf(["1", "2", "3"], 'This field is required')
          .nullable()
          .typeError(null),
    });

    const getLogo = async () => {
        try {
          blockUI.current.open(true);
          const resp = await newQuestionService.callCompanyLogo(hash)
          setCompanyLogo(resp?.data?.data?.logo)
          blockUI.current.open(false);
        } catch (e) {
          blockUI.current.open(false);
        }
    }

    useEffect(() => {
        getLogo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (values) => {
        let answer = ''
        if(values.document === '1')answer = 'HAVE IT'
        if(values.document === '2')answer = 'HAVE IT BUT NOT NOW'
        if(values.document === '3')answer = 'DON´T HAVE IT'
        return saveAnswer(answer)
    }

    const saveAnswer = async (answer) => {
        try {
            blockUI.current.open(true);
            await newQuestionService.saveDd214(hash, answer)
            if(answer === 'HAVE IT'){
                blockUI.current.open(false);
                return history.push(`/upload-dd214/${hash}`)
            }
            setBtnDisabled(true)
            blockUI.current.open(false);
            lang ? history.push(`/saved-veteran-question/${hash}?lang=${lang}`) : history.push(`/saved-veteran-question/${hash}`)
        } catch (e) {
            blockUI.current.open(false);
            AppHelper.checkError(e, snackbarUI);
        }
    }

  return (
    <>
        <Box sx={styles.logo}>
            {companyLogo 
                ? <img
                    src={companyLogo}
                    alt='dashboard public'
                    style={{
                        borderRadius: '5px',
                        objectFit: 'cover',
                        marginTop: '20px',
                        width: '200px',
                        maxHeight: '150px'
                    }}
                    />
                : <img src={logo} alt='dashboard public' width='250px' />
            }
        </Box>
        <Container>
            <Paper
                elevation={4}
                sx={style.paperVeterean}
            >
                <Box sx={style.topBoxVeteran}>
                    <Typography>WOTC QUESTIONNAIRE</Typography>
                </Box>
                <Box sx={{width: '100%'}}>
                    <Formik
                        initialValues={{ document: ""}}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                    >
                        {() => {
                        return (
                            <Form
                                autoComplete="false"
                                noValidate
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        display: 'flex',
                                        whiteSpace: 'pre-line',
                                        fontSize: '1.3rem',
                                        margin: '24px 0 10px',
                                    }}
                                >{questionLang}</p>
                                <AppCheckbox
                                    sx={{ width: '100%', ...checkboxStyles.checkboxCtn}}
                                    id={questionLang}
                                    name='document'
                                    options={[
                                    {'label': `${(lang === 'es') ? 'Subir' : 'Upload'}`, 'value': '1', 'disabled': btnDisabled ? true : false},
                                    {'label':  `${(lang === 'es') ? 'Lo tengo pero no puedo cargarlo' : 'Have it but not able to upload'}`, 'value': '2', 'disabled': btnDisabled ? true : false},
                                    {'label':  `${(lang === 'es') ? 'No lo tengo' : "don't have it"}`, 'value': "3", 'disabled': btnDisabled ? true : false},
                                    ]}
                                />
                                {!btnDisabled && <Box sx={{width: '100% !important', ...multiQuestStyles.buttonWrapper}}>
                                    <Button
                                        color="inherit"
                                        type="submit"
                                        disabled={btnDisabled}
                                    >save</Button>
                                </Box>}
                            </Form>
                        )
                        }}
                    </Formik>
                </Box>
            </Paper>
        </Container>
    </>
  )
}

export default QuestionForVeteran