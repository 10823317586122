// @vendors
import * as React from 'react';
import { useRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { Button, Typography } from '@mui/material';
import { ModalConfirmPayrollStyle } from '../../assets/css/payroll-style';
import sx from 'mui-sx';

const Style = () => ({
    dialog:{
        '& .MuiDialog-paper': { 
            width: '80%', maxHeight: 435
        }
    },
  body:{
    padding: '40px'
  },
  title:{
    fontSize: "20px",
    display: 'flex',
    fontWeight: '500',
    color: '#101010',
    margin: '0px',
    textAlign: 'center',
  },
  containerButtons:{
    width: '100%',
    display: 'flex',
    gap: '15px',
    justifyContent: 'end',
    marginTop: '30px'
  },
  buttonAccept: {
    fontSize: '1.5rem',
    color: '#fff',
    padding: '10px 15px',
    backgroundColor: '#101010',
    borderRadius: 4,
    ':hover': {
      boxShadow: 5,
    },
  },
  buttonClose: {
    fontSize: '1.5rem',
    color: '#fff',
    padding: '10px 15px',
    backgroundColor: 'rgba(36, 35, 35, 0.3)',
    borderRadius: 4,
    border: 'none',
    ':hover': {
      boxShadow: 5,
    },
  },
})

function ConfirmationDialog(props) {
  const { onClose, open, view, message} = props;
  const radioGroupRef = useRef(null);
  const style = view === 'payroll' ? ModalConfirmPayrollStyle() : Style()

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      sx={style.dialog}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogContent sx={style.body}>
        <Typography sx={style.title}>{message}</Typography>
        <div style={style.containerButtons}>
            <Button
                autoFocus
                onClick={() => handleCancel(false)}
                style={sx(style.buttonClose, style.styleCommontButtom)}
                variant="contained"
            >
                Cancel
            </Button>
            <Button
                style={sx(style.buttonAccept, style.styleCommontButtom)}
                onClick={() => handleCancel(true)}
                variant="contained"
            >
                {view === 'payroll' ? 'Allow' : 'Accept'}
            </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
export default ConfirmationDialog