import React from 'react';

import { Box, CssBaseline } from './shared/MaterialUI';

const Footer = () => {
  // Styles
  const styles = useStyles();

  return (
    <>
      <CssBaseline />
      <Box component='footer' sx={styles.footer} />
    </>
  );
};

export default Footer;

const useStyles = (theme) => ({
  footer: {
    width: '100%',
    height: '80px',
    backgroundColor: '#101010',
  },
});
