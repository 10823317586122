/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useId, useState } from 'react'
import { TextareaAutosize } from "@mui/material";
import { useDispatch } from 'react-redux';

// @assets
import { NotesPayrollStyle } from "../../../assets/css/payroll-style";

// @material-ui
import { Box, Popover, Typography } from "../../../components/shared/MaterialUI";

// @services
import PayrollService from '../../../services/newApi/PayrollService';

// @state
import { useUI } from '../../../app/context/ui';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { callListPayroll } from '../../../helpers/FunctionsUtils';

const DialogNotes = ({
  anchorEl,
  handleClose,
  idItemSelected,
}) => {
  const style = NotesPayrollStyle()
  const open = Boolean(anchorEl);
  const id = useId()
  const payrollService = new PayrollService()
  const { blockUI, snackbarUI } = useUI();
  const [valueNote, setValueNote] = useState('')
  const dispatch = useDispatch()

  const updateNote = (e) => {
    const DATA = {
      stateCertification: idItemSelected?.stateCertification,
      stateStatus: idItemSelected?.stateStatusId,
      paySchedule: idItemSelected?.payScheduleId,
      note: valueNote,
    }
    callForUpdateEmployee(DATA)
  }

  const callForUpdateEmployee = async (data) => {
    try {
      blockUI.current.open(true);
      await payrollService.updateEmploye(idItemSelected?.employeeId, data)
      callListPayroll(dispatch, true)
      handleClose()
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const handleChange = (e) => {
    setValueNote(e.target.value)
  }

  useEffect(() => {
    setValueNote(idItemSelected?.note)
  }, [idItemSelected])
  

  return (
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
        <Box sx={style.containerNotes}>
            <Typography sx={style.title}>Notes</Typography>

            <TextareaAutosize
                aria-label="Notes for employee"
                minRows={7}
                maxRows={8}
                value={valueNote}
                placeholder=""
                style={style.textTarea}
                onBlur={(e) => updateNote(e)}
                onChange={(e) => handleChange(e)}
            />
        </Box>
      </Popover>
  )
}

export default DialogNotes