import * as React from 'react';

import { NavLink } from 'react-router-dom';

import { MenuItem, Typography } from '@mui/material';

import Navigation from './Navigation';
import NavigationPublic from './NavigationPublic';

const MobileListItems = (props) => {
  let nav = props?.type ? NavigationPublic : Navigation;
  let func = props?.func;

  return (
    <div>
      {nav.map((nav, i) => {
        return <ChildrenItems key={i} menu={nav} funcion={func} />;
      })}
    </div>
  );
};

const ChildrenItems = ({ menu, funcion }) => {
  return (
    <MenuItem onClick={funcion}>
      <NavLink
        exact={true}
        to={menu.url}
        className="liNavLink"
        activeStyle={{
          borderBottom: "4px solid #80BB57",
          color: "#80BB57",
        }}
      >
        <Typography textAlign="center" color="#101010">
          {menu.title}
        </Typography>
      </NavLink>
    </MenuItem>
  );
};

export { MobileListItems };
