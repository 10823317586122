import React from 'react';

import { useField } from 'formik';
import { at } from 'lodash';
import {TextField} from "@mui/material";


export default function AppTextField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <TextField
      type='text'
      variant='outlined'
      size='small'
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
  );
}
