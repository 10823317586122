// @vendors
import React from 'react'
import {
    Button,
    FormControl,
    InputLabel,
    NativeSelect,
    Popover,
  } from "../../../../../components/shared/MaterialUI";
import { toolbarPropStyles } from '../../../../../assets/css/tableComponents-styles';

const FilterStatus = ({
  id,
  open,
  anchorEl,
  handleClose,
  onSelectChange,
  clearSearch,
}) => {
  return (
    <>
        <Popover
          PaperProps={toolbarPropStyles}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <FormControl fullWidth className={"statusSelect"}>
            <InputLabel variant="standard" className={"selectLabel"}>
              Questionnaire Status
            </InputLabel>
            <NativeSelect
              defaultValue={"status"}
              onChange={(e) => onSelectChange(e.target.value)}
            >
              <option name="status" hidden>
                Questionnaire Status
              </option>
              <option value={"sent"}>Sent</option>
              <option value={"completed"}>Completed</option>
              <option value={"expired"}>Expired</option>
            </NativeSelect>
          </FormControl>
          <Button onClick={() => clearSearch()}>Clear filter</Button>
        </Popover>
    </>
  )
}

export default FilterStatus