// @vendors
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// @context
import { useUI } from '../../app/context/ui';

// @constants
import { ROUTENAME } from '../../navigation/RouteName';

const Logout = () => {
  const { blockUI } = useUI();
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = async () => {
    blockUI.current.open(true);
    setTimeout(() => {
      dispatch({ type: "LOGOUT" });
      history.push(ROUTENAME.login);        
      blockUI.current.open(false);
    }, 2000);
  };

  useEffect(() => {
      logout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div></div>
  )
}

export default Logout