// @vendors
import * as React from 'react';
import { useRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { Button } from 'react-scroll';
import { Typography } from '@mui/material';

const Style = () => ({
  body:{
    padding: '40px'
  },
  title:{
    fontSize: "20px",
    display: 'flex',
    fontWeight: '500',
    color: '#101010',
    margin: '0px',
    textAlign: 'center',
  },
  containerButtons:{
    width: '100%',
    display: 'flex',
    gap: '15px',
    justifyContent: 'end',
    marginTop: '30px'
  },
  buttonAccept: {
    fontSize: '1.5rem',
    color: '#fff',
    padding: '10px 15px',
    backgroundColor: '#101010',
    borderRadius: 4,
    ':hover': {
      boxShadow: 5,
    },
  },
  buttonClose: {
    fontSize: '1.5rem',
    color: '#fff',
    padding: '10px 15px',
    backgroundColor: 'rgba(36, 35, 35, 0.3)',
    borderRadius: 4,
    border: 'none',
    ':hover': {
      boxShadow: 5,
    },
  },
})

function ConfirmDialog(props) {
  const { onClose, open, row} = props;
  const radioGroupRef = useRef(null);
  const style = Style()

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = (value) => {
    onClose(value, row);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogContent sx={style.body}>
        <Typography sx={style.title}>Are you sure you would like to make this change?</Typography>
        <div style={style.containerButtons}>
        <Button autoFocus onClick={() => handleCancel('no')} style={style.buttonClose}>
          Cancel
        </Button>
        <Button style={style.buttonAccept} onClick={() => handleCancel('yes')}>Accept</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
export default ConfirmDialog
