import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import {
  cloneDeep as _cloneDeep,
  keys as _keys,
  merge as _merge,
  pick as _pick,
} from 'lodash';

import CreateEmployee
  from '../../pages/Employer/components/CreateEmployee/CreateEmployee';
import AppButton from '../forms/AppButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from './MaterialUI.js';

const template = {
  main: {
    title: '',
    content: '',
  },
  btn: {
    confirm: 'Yes',
    close: 'Ok',
  },
  confirm: false,
  onConfirm: () => {},
  onBackdropClick: () => {},
  styles: {},
  type: '',
  getEmployees: () => {},
  companyId: ''
};

const DialogUI = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState(template);

  const onClose = () => {
    setOpen(false);
  };
  
  const onConfirm = () => {
    settings.onConfirm();
  };
  
  useImperativeHandle(ref, () => ({
    open: (title, content, settings = null) => {
      let defaultSettings = _cloneDeep(template);
      if (settings !== null) {
        const clean = _pick(settings, _keys(template));
        const copy = _cloneDeep(template);
        defaultSettings = _merge(copy, clean);
      }
      defaultSettings.main.title = title;
      defaultSettings.main.content = content;
      setSettings(defaultSettings);
      setOpen(true);
    },
    close: () => setOpen(false),
    isOpen: () => open,
  }));

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={settings.styles.maxWidth}
        open={open}
        // onClose={onClose}
        sx={settings.styles.dialogBox}
        onBackdropClick={settings.onBackdropClick}
      >
        {!settings.confirm ? (
          <>
            <DialogContent sx={settings.styles.content}>
              <CreateEmployee companyId={settings.companyId} getEmployees={settings.getEmployees} />
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle sx={settings.styles.title}>{settings.main.title}</DialogTitle>
            <DialogContent>
              <DialogContentText sx={settings.styles.content} whiteSpace="pre-line">
                {settings.main.content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container direction='row' alignItems='center' spacing={1}>
                <Grid item xs={6} sx={{ display: settings.styles.showButton }}>
                  <AppButton
                    onPress={onClose}
                    label={settings.btn.close}
                    type={settings.type}
                    color={'primary'}
                    bgColor={settings.styles.cancelButton}
                  />
                </Grid>
                <Grid item xs={settings.styles.showButton ? 12 : 6}>
                  {settings.confirm && (
                    <AppButton
                      onPress={onConfirm}
                      label={settings.btn.confirm}
                      type={settings.type}
                      color={'primary'}
                      bgColor={settings.styles.ctaButton}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
});

export default DialogUI;
