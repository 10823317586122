export const unsubscribeEmailStyles = () => ({
    containerUnsubscribe: {
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgb(249, 249, 249)'
    },
    containerBodyUnsubscribe: {
        width: "40%",
        minHeight: '200px',
        maxHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        borderTop: '2px solid #1676F3',
        backgroundColor: '#fff',
        borderRadius: '4px',
    },
    containerBodyUnsubscribeSx: {
        width: "100%",
        minHeight: '200px',
        maxHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        borderTop: '2px solid #1676F3',
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '10px',
        margin: '0 10px'
    },
    title: {
        fontSize: "28px",
        fontWeight: '700',
        color: '#101010',
        margin: '0 0 30px 0',
        display: 'flex',
        textAlign: 'center'
    },
    subtitle: {
        fontSize: "18px",
        display: 'flex',
        fontWeight: '400',
        color: '#242323',
        margin: '0px',
        textAlign: 'center',
        opacity: '0.8'
    }
})
