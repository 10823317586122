// @vendors
import React, { useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

// @assets
import { PayrollStyle } from '../../../assets/css/payroll-style'

// @constants
import { types } from '../../../redux/types'
import { updateStateFilterPayroll } from '../../../helpers/FunctionsUtils'

const ToolbarPayroll = () => {
  const currentTab = useSelector((state) => state.tabPayroll.tab)
  const style = PayrollStyle()
  const [valueTab, setValueTab] = useState(currentTab);
  const dispatch = useDispatch()

  const handleChangeTab = (event, newValue) => {
    updateStateFilterPayroll(dispatch)
    setValueTab(newValue);
    dispatch({
      type: types.TAB_PAYROLL,
      tab: newValue
    })
  };

  return (
    <>
      <Tabs
        value={valueTab}
        onChange={handleChangeTab}
        textColor="inherit"
        indicatorColor="string"
        aria-label="secondary tabs example"
        sx={style.toolbar}
      >
        <Tab value={1} label="Active" sx={style.toolbarItem}/>
        <Tab value={2} label="Maxed Out" sx={style.toolbarItem}/>
        <Tab value={3} label="Invoice Summary" sx={style.toolbarItem}/>
      </Tabs>
    </>
  )
}

export default ToolbarPayroll