import { theme } from '../theme';

export const CreateEmployerStyles = () => ({
  dashCtn: {
    color: '#101010',
    padding: '0 48px'
  },
  paper: {
    borderRadius: '6px',
    position: 'relative'
  },
  stepper: {
    position: 'absolute',
    top: '60px',
    left: 'calc(50% - 48px)',
    '& .MuiStepLabel-root': {
      '& .MuiStepIcon-root': {
        text: {
          display: 'none'
        },
        color: 'rgba(36, 35, 35, 0.3)'
      },
      '& .Mui-active': {
        color: '#000'
      },
      '& .Mui-completed': {
        fill: theme.palette.primary.main,
        color: '#000'
      }
    }
  },
  ctnInner: {
    padding: '112px 8.5rem',
    height: '100%',
    maxWidth: '100%',
    margin: '0',
    '& h3': {
      fontWeight: '700',
      fontSize: '4rem',
      margin: '20px 0'
    }
  },
  boxCtn: {
    marginTop: '1.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  boxItem: {
    position: 'relative',
    width: '45%',
    marginBottom: '2.4rem',
    '& > p': {
      fontSize: '1.125rem',
      margin: '0 0 16px',
      display: 'block'
    }
  },
  inputCustom: {
    width: '100%',
    '& .MuiInputLabel-root': { padding: '0 8px' },
    '& .MuiOutlinedInput-root': {
      height: '56px',
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: '2px'
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #101010'
      },
      '& .MuiInputBase-input': { padding: '12px 20px' }
    },
    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      padding: '12px'
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-14px',
      left: '10px',
      background: '#FFF',
      padding: '0 10px',
      fontSize: '0.875rem',
      margin: '0'
    },
    '> .MuiFormLabel-root.Mui-focused': {
      top: '0',
      color: '#000'
    }
  },
  select: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '16px 20px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #101010'
    }
  },
  selectError: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #d32f2f',
      '&:hover': {
        border: '1px solid #d32f2f'
      }
    }
  },
  formButtonWrapper: {
    margin: '48px auto 0',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: 'rgba(36, 35, 35, 0.3)',
    color: 'white',
    height: '56px',
    '&:hover': {
      backgroundColor: 'rgba(36, 35, 35, 0.3)',
      boxShadow: 6
    },
    '& .MuiButton-root': {
      height: '56px',
      width: '45%',
      textTransform: 'none',
      backgroundColor: 'rgba(36, 35, 35, 0.3)',
      opacity: '0.5',
      color: '#FFF',
      fontWeight: '600',
      fontSize: '1.5rem',
      boxShadow: 4,
      '&:hover': {
        backgroundColor: 'rgba(36, 35, 35, 0.3)',
        boxShadow: 6
      }
    },
    '& .MuiButton-root:last-of-type': {
      height: '56px',
      width: '45%',
      backgroundColor: '#000',
      opacity: '1',
      color: '#FFF',
      '&:hover': {
        boxShadow: 12
      }
    }
  },
  cancelButton: {
    width: '100%',
    marginRight: '10px',
    height: '48px',
    borderRadius: '4px',
    textTransform: 'none',
    backgroundColor: 'rgba(36, 35, 35, 0.5)',
    color: '#FFF',
    fontSize: '1.3rem',
    '&:hover': {
      backgroundColor: 'rgba(36, 35, 35, 0.4)',
      boxShadow: 'none',
      color: '#101010'
    }
  },
  passwordInput: {
    '& .MuiInputBase-input': {
      position: 'relative'
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-10px',
      fontSize: '12px',
      left: '10px',
      margin: '0px',
      padding: '0 10px',
      backgroundColor: '#FFF'
    }
  },
  formCtn: {
    height: 'calc(100% - 4rem)'
  },
  optButton: {
    '& .MuiFormControlLabel-label': {
      fontSize: '16px'
    }
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  stateError: {
    position: 'absolute',
    bottom: '-25px',
    left: '15px',
    background: '#FFF',
    color: '#d32f2f',
    padding: '0 8px',
    fontSize: '0.875rem !important',
    '&::first-letter': {
      textTransform: 'capitalize'
    }
  },
  stateIDError: {
    position: 'absolute',
    bottom: '-25px',
    left: '15px',
    background: '#FFF',
    color: '#d32f2f',
    padding: '0 8px',
    fontSize: '0.875rem !important',
    '&::first-letter': {
      textTransform: 'capitalize'
    }
  },
  countyError: {
    position: 'absolute',
    bottom: '-25px',
    left: '15px',
    background: '#FFF',
    color: '#d32f2f',
    padding: '0 8px',
    fontSize: '0.875rem !important',
    '&::first-letter': {
      textTransform: 'capitalize'
    }
  },
  cityError: {
    position: 'absolute',
    bottom: '-25px',
    left: '15px',
    background: '#FFF',
    color: '#d32f2f',
    padding: '0 8px',
    fontSize: '0.875rem !important',
    '&::first-letter': {
      textTransform: 'capitalize'
    }
  },
  showPassword: {
    position: 'absolute',
    right: '16px',
    top: '7px'
  },
  dialog: {
    maxWidth: 'sm',
    title: {
      fontSize: '2rem',
      fontWeight: '700',
      color: '#101010'
    },
    content: {
      fontSize: '1.5rem',
      color: '#101010'
    },
    dialogBox: {
      '& .MuiDialog-paper': {
        padding: '48px'
      }
    },
    cancelButton: 'rgba(36, 35, 35, 0.5)',
    ctaButton: '#101010',
    showButton: 'none'
  },
  formEmployerContainerCheckbox:{
    marginTop: '0px',
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'start',
    margin: '0px',
    padding: 0,
    gap: '10px',
    width: { md: '320px', xs: '100%' }
  }
});
