import { Menu, MenuItem } from '@mui/material'
import React, { useContext } from 'react'
import { Link  } from "react-router-dom";
import { ROUTENAME } from '../../../../navigation/RouteName'
import { EmployeeContext } from '../../Employer';

const MenuAddBranch = ({anchorElMenu, openMenu, handleCloseMenu}) => {
    const {employerDetails} = useContext(EmployeeContext);
    const parentId = employerDetails?.data?.id;

  return (
    <Menu
        id="basic-menu"
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          component={Link}
          to={{
              pathname: `${ROUTENAME.createEmployer}`,
              state: {parentCompanyId: parentId, data: employerDetails?.data },
          }}
        >
          Copy from Parent
        </MenuItem>
        <MenuItem
          component={Link}
          to={{
              pathname: `${ROUTENAME.createEmployer}`,
              state: {parentCompanyId: parentId, data: {} },
          }}
        >
          From Scratch
        </MenuItem>
      </Menu>
  )
}

export default MenuAddBranch