import * as Yup from "yup";
import { camelCase as _camelCase, includes as _includes } from "lodash";

export const createValidationSchema = (question, t, lang) => {
  const questionLang = lang === "es" ? question.spanishTitle : question.title;

  // basic_date
  // digital_signature
  // matrix_box
  // multi_question
  // numerical
  // single_list
  // single_text
  // state_federal
  // text
  // yes_no
  // yes_no_unsure
  if (question.type === "yes_no") {
    return Yup.object().shape({
      [_camelCase(question.title)]: Yup.string()
        .required(t("question.yesNoRequired"))
        .oneOf(["yes", "no"], t("question.yesNoRequired"))
        .nullable()
        .typeError(null),
    });
  }

  if (question.type === "yes_no_unsure") {
    return Yup.object().shape({
      [_camelCase(question.title)]: Yup.string()
        .required(t("question.yesNoRequired"))
        .oneOf(["yes", "no", "unsure"], t("question.yesNoRequired"))
        .nullable()
        .typeError(null),
    });
  }

  switch (question.title) {
    case "Birthdate":
      return Yup.object({
        [_camelCase(question.title)]: Yup.date()
          .required(`${t("question.requiredCustom")}`)
          .typeError(t("question.basicDateTypeError"))
          .max(new Date(), t("question.basicDateTypeErrorMax"))
          .test("is-adult", t("question.basicDateTest"), (value) => {
            if (value) {
              const birthdate = new Date(value);
              const today = new Date();
              let age = today.getFullYear() - birthdate.getFullYear();
              const monthDiff = today.getMonth() - birthdate.getMonth();
              if (
                monthDiff < 0 ||
                (monthDiff === 0 && today.getDate() < birthdate.getDate())
              ) {
                age--;
              }
              return age >= 18;
            }
            return false;
          }),
      });

    case "Enter the date of the conviction":
    case "Enter the date of release from prison":
    case "Applicant's Community Supervision(probation/parole) start date":
    case "Date applicant entered into Work Release Program":
      return Yup.object({
        [_camelCase(question.title)]: Yup.date()
          .nullable()
          .typeError(t("question.basicDateTypeError"))
          .max(new Date(), t("question.basicDateTypeErrorMax"))
          .test(
            "is-valid-date",
            `${t("question.basicDateTestValidDate")}`,
            function (value) {
              return !value || new Date(value) < new Date();
            }
          ),
      });
    case "Enter the city where the benefits were received":
    case "Enter the county where the benefits were received":
    case "Enter the state where the benefits were received":
    case "What state did you receive unemployment compensation in?":
    case "Select branch of military":
      return Yup.object({
        [_camelCase(question.title)]: Yup.string()
          .notOneOf(["none"], t("question.optionInvalid"))
          .typeError(null)
          .nullable(false)
          .required(`${questionLang} ${t("question.requiredCustom")}`)
          .test(
            "is-required",
            `${questionLang} ${t("question.requiredCustom")}`,
            function (value) {
              return value !== null;
            }
          ),
      });

    case "Enter the last name of the primary recipient":
    case "Enter the first name of the primary recipient":
      return Yup.object({
        [_camelCase(question.title)]: Yup.string()
          .nullable(false)
          .required(`${questionLang} ${t("question.requiredCustom")}`),
        telephone: Yup.string()
          .min(7, t("question.telephoneMin"))
          .max(15, t("question.telephoneMax")),
      });
    case "Was this a Federal or State felony conviction?":
      return Yup.object().shape({
        [_camelCase(question.title)]: Yup.string()
          .required(t("question.yesNoRequired"))
          .oneOf(["state", "federal"], t("question.yesNoRequired"))
          .nullable()
          .typeError(null),
      });
    default:
      if (question.type === "basic_date") {
        return Yup.object({
          [_camelCase(question.title)]: Yup.date()
            .required(`${t("question.requiredCustom")}`)
            .max(new Date(), t("question.basicDateTypeErrorMax"))
            .typeError(t("question.basicDateTypeError")),
        });
      }
      if (question.type === "single_text") {
        return Yup.object({
          [_camelCase(question.title)]: _includes(
            _camelCase(question.title),
            "email"
          )
            ? Yup.string()
                .email(t("question.validateEmail"))
                .nullable(false)
                .required(`${questionLang} ${t("question.requiredCustom")}`)
            : Yup.string()
                .nullable(false)
                .required(`${questionLang} ${t("question.requiredCustom")}`)
                .test(
                  "is-required",
                  `${questionLang} ${t("question.requiredCustom")}`,
                  function (value) {
                    return value !== null;
                  }
                ),
          telephone: Yup.string()
            .min(7, t("question.telephoneMin"))
            .max(15, t("question.telephoneMax")),
        });
      }
  }
};