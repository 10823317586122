// @vendors
import React from 'react'
import { FormControl, Grid, OutlinedInput } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// @assets
import { EditPayrollStyle } from '../../../assets/css/payroll-style'

const initialvalues = {
    january: '',
    february: '',
    march: '',
    april: '',
    may: '',
    june: '',
    july: '',
    august: '',
    september: '',
    october: '',
    november: '',
    december: '',
}

const FormEditHours = () => {
    const style = EditPayrollStyle()

    const validationForm = Yup.object({
        january: Yup.string().notRequired().nullable(),
        february: Yup.string().notRequired().nullable(),
        march: Yup.string().notRequired().nullable(),
        april: Yup.string().notRequired().nullable(),
        may: Yup.string().notRequired().nullable(),
        june: Yup.string().notRequired().nullable(),
        july: Yup.string().notRequired().nullable(),
        august: Yup.string().notRequired().nullable(),
        september: Yup.string().notRequired().nullable(),
        october: Yup.string().notRequired().nullable(),
        november: Yup.string().notRequired().nullable(),
        december: Yup.string().notRequired().nullable(),
    })

    const formik = useFormik({
        initialValues:initialvalues,
        onSubmit:(values) => {
            submit(values)
        },
        validationSchema: validationForm
    })

    const submit = () =>{
        console.log(formik.values)
    }

  return (
    <Box sx={{flex:1}}>
        <form onSubmit={formik.handleSubmit}>
            <Grid container sx={style.containerFormHours}>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="january" 
                      variant="outlined"
                      name='january'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.january && Boolean(formik.errors.january)}
                      helperText={formik.touched.january && formik.errors.january}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="february" 
                      variant="outlined"
                      name='february'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.february && Boolean(formik.errors.february)}
                      helperText={formik.touched.february && formik.errors.february}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="march" 
                      variant="outlined"
                      name='march'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.march && Boolean(formik.errors.march)}
                      helperText={formik.touched.march && formik.errors.march}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="april" 
                      variant="outlined"
                      name='april'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.april && Boolean(formik.errors.april)}
                      helperText={formik.touched.april && formik.errors.april}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="may" 
                      variant="outlined"
                      name='may'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.may && Boolean(formik.errors.may)}
                      helperText={formik.touched.may && formik.errors.may}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="june" 
                      variant="outlined"
                      name='june'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.june && Boolean(formik.errors.june)}
                      helperText={formik.touched.june && formik.errors.june}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="july" 
                      variant="outlined"
                      name='july'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.july && Boolean(formik.errors.july)}
                      helperText={formik.touched.july && formik.errors.july}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="august" 
                      variant="outlined"
                      name='august'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.august && Boolean(formik.errors.august)}
                      helperText={formik.touched.august && formik.errors.august}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="september" 
                      variant="outlined"
                      name='september'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.september && Boolean(formik.errors.september)}
                      helperText={formik.touched.september && formik.errors.september}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="october" 
                      variant="outlined"
                      name='october'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.october && Boolean(formik.errors.october)}
                      helperText={formik.touched.october && formik.errors.october}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="november" 
                      variant="outlined"
                      name='november'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.november && Boolean(formik.errors.november)}
                      helperText={formik.touched.november && formik.errors.november}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="december" 
                      variant="outlined"
                      name='december'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      onChange={formik.handleChange}
                      error={formik.touched.december && Boolean(formik.errors.december)}
                      helperText={formik.touched.december && formik.errors.december}
                    />
                  </FormControl>
                </Grid>
            </Grid>
        </form>
    </Box>
  )
}

export default FormEditHours