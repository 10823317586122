// @vendors
import React, {useContext} from "react";
import {Form, Formik} from "formik";
import {
  camelCase as _camelCase,
  includes as _includes,
  startCase as _startCase,
  toLower as _toLower,
  isUndefined as _isUndefined,
} from "lodash";
import * as Yup from "yup";

// @assets
import {
  CheckboxStyles,
  MultiQuestStyles,
  SingleListStyles,
} from "../../../../assets/css/questionnaire-style";

// @material-ui
import {
  Box,
  Button,
  HelpOutlined,
  IconButton,
  Tooltip,
  Visibility,
  VisibilityOff,
} from "../../../../components/shared/MaterialUI";

// @state
import {QuestionnaireContext} from "../../Prescreening";

// @components
import {AppCheckbox, AppDatePicker, AppSelect, AppTextField} from "../../../../components/forms";

// @validations
import { createValidationSchema } from "./Validations";

export const DateQuestion = ({onSubmit}) => {
  const {lang, t, initial} = useContext(QuestionnaireContext);
  const styles = MultiQuestStyles();
  const questionLang = (lang === 'es') ? initial?.spanishTitle : initial?.title;
  const validationSchema = createValidationSchema(initial, t, lang);

  return (
    <>
      <p className="title">
        {questionLang}
        <Tooltip
          sx={styles.tip}
          title={<p>{initial?.info} </p>}
          placement="right"
          arrow
        >
          <IconButton>
            <HelpOutlined/>
          </IconButton>
        </Tooltip>
      </p>
      <Formik
        initialValues={{[_camelCase(initial?.title)]: _isUndefined(initial?.answer.answer) ? '' : initial?.answer?.answer || ""}}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form
              autoComplete="off"
              className="formCreateUser"
              noValidate
            >
              <AppDatePicker
                id={`${initial?.id}`}
                name={_camelCase(initial?.title)}
                sx={styles.inputCustom}
              />
              <Box sx={styles.buttonWrapper}>
                <Button color="inherit" type="submit">
                  {t("question.btnNext")}
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export const SingleTextQuestion = ({onSubmit}) => {
  const {lang, t, initial} = useContext(QuestionnaireContext);
  const styles = MultiQuestStyles();
  const questionLang = (lang === 'es') ? initial?.spanishTitle : initial?.title;
  const validationSchema = createValidationSchema(initial, t, lang);

  return (
    <>
      <p className="title">
        {questionLang}
        <Tooltip
          style={{color: "#80BB57", marginTop: "-0.2em"}}
          title={<p>{initial?.info} </p>}
          placement="right"
          arrow
        >
          <IconButton>
            <HelpOutlined/>
          </IconButton>
        </Tooltip>
      </p>
      <Formik
        initialValues={{
          [_camelCase(initial?.title)]: initial?.answer?.answer || "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form
              autoComplete="off"
              className="formCreateUser"
              noValidate
            >
              <AppTextField
                required
                label={t("question.labelDefault")}
                variant='outlined'
                id={`${initial?.id}`}
                name={_camelCase(initial?.title)}
              />
              <Box sx={styles.buttonWrapper}>
                <Button color="inherit" type="submit">
                  {t("question.btnNext")}
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export const NumericalQuestion = ({onSubmit}) => {
  const {lang, t, initial} = useContext(QuestionnaireContext);
  const styles = MultiQuestStyles();
  let flag = initial?.title.toLowerCase() === "Social security number".toLowerCase();
  let zip = _camelCase(initial?.title) === "zip";

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const questionLang = (lang === 'es') ? initial?.spanishTitle : initial?.title;

  const validationSchema = Yup.object().shape({
    [_camelCase(initial?.title)]:
      initial?.title === "PIN"
        ? Yup.string()
          .required(`${questionLang} ${t("question.requiredContinue")}`)
          .min(4, t("question.validationMinDigits"))
        : initial?.title === "Zip"
          ? Yup.number()
            .required(`${questionLang} ${t("question.requiredCustom")}`)
            .min(10000, t("question.validationDigits"))
            .max(99999, t("question.validationDigits"))
          : Yup.string().required(`${questionLang} ${t("question.requiredCustom")}`),
  });

  return (
    <>
      <p className="title">
        {questionLang}
        <Tooltip
          sx={styles.tip}
          title={<p>{initial?.info} </p>}
          placement="right"
          arrow
        >
          <IconButton>
            <HelpOutlined/>
          </IconButton>
        </Tooltip>
      </p>
      <Box sx={{position: "relative"}}>
        <Formik
          initialValues={{date: initial?.answer?.answer || ""}}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue}) => {
            return (
              <Form
                autoComplete="off"
                className="formCreateUser"
                noValidate
              >
                <AppTextField
                  required
                  label={t("question.labelDefault")}
                  variant='outlined'
                  id={`${initial?.id}`}
                  name={_camelCase(initial?.title)}
                  type={zip ? "text" : values.showNumber ? "text" : "password"}
                  value={
                    flag
                      ? values[_camelCase(initial?.title)]
                      : zip
                        ? values[_camelCase(initial?.title)]
                        : values.pin
                  }
                  sx={styles.numerical}
                />
                {flag || zip ? null : (
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setFieldValue("showNumber", !values.showNumber);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={styles.showText}
                  >
                    {values.showNumber ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                )}
                <Box sx={styles.buttonWrapper}>
                  <Button color="inherit" type="submit">
                    {t("question.btnNext")}
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </>
  );
};

export const StateFederalQuestion = ({onSubmit}) => {
  const {lang, t, initial} = useContext(QuestionnaireContext);
  const styles = CheckboxStyles();
  const questionLang = (lang === 'es') ? initial?.spanishTitle : initial?.title;
  const validationSchema = createValidationSchema(initial, t, lang);

  return (
    <>
      <p className="title">
        {questionLang}
        <Tooltip
          sx={styles.tip}
          title={<p>{initial?.info} </p>}
          placement="right"
          arrow
        >
          <IconButton>
            <HelpOutlined/>
          </IconButton>
        </Tooltip>
      </p>
      <Formik
        initialValues={{
          [_camelCase(initial?.title)]: initial?.answer?.answer || "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form className="formCreateUser">
              <AppCheckbox
                sx={styles.checkboxCtn}
                id={`${initial.id}`}
                name={_camelCase(initial.title)}
                options={[
                  {'label': 'Federal', 'value': 'federal', 'disabled': false},
                  {'label': `${(lang === 'es') ? 'Estado' : 'State'}`, 'value': 'state', 'disabled': false},
                ]}
                label={t("question.labelState")}
              />
              <Box>
                <Button color="inherit" type="submit">
                  {t("question.btnNext")}
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export const YesNoQuestion = ({onSubmit}) => {
  const {lang, t, initial} = useContext(QuestionnaireContext);
  const styles = CheckboxStyles();
  const questionLang = (lang === 'es') ? initial?.spanishTitle : initial?.title;
  const validationSchema = createValidationSchema(initial, t, lang);

  return (
    <>
      <p className="title">
        {questionLang}
        <Tooltip
          sx={styles.tip}
          title={<p>{initial?.info} </p>}
          placement="right"
          arrow
        >
          <IconButton>
            <HelpOutlined/>
          </IconButton>
        </Tooltip>
      </p>
      <Formik
        initialValues={{
          [_camelCase(initial?.title)]: initial?.answer?.answer || "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form
              autoComplete="off"
              className="formCreateUser"
              noValidate
            >
              <AppCheckbox
                sx={styles.checkboxCtn}
                id={`${initial.id}`}
                name={_camelCase(initial.title)}
                options={[
                  {'label': `${(lang === 'es') ? 'Si' : 'Yes'}`, 'value': 'yes', 'disabled': false},
                  {'label': 'No', 'value': 'no', 'disabled': false}
                ]}
                label={t("question.labelOption")
              }
              />
              <Box sx={styles.buttonWrapper}>
                <Button color="inherit" type="submit">
                  {t("question.btnNext")}
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export const YesNoUnsureQuestion = ({onSubmit}) => {
  const {lang, t, initial} = useContext(QuestionnaireContext);
  const styles = CheckboxStyles();
  let questionLang = (lang === 'es') ? initial.spanishTitle : initial.title;
  const validationSchema = createValidationSchema(initial, t, lang);
  if(initial?.code === 'Q69'){
    questionLang = initial?.title?.replaceAll(`*`,`\n *`);
  }

  return (
    <>
      <Formik
        initialValues={{
          [_camelCase(initial.title)]: initial?.answer?.answer || "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form
              autoComplete="false"
              className="formCreateUser"
              noValidate
            >
              <p className="title" style={{display: 'flex', whiteSpace: 'pre-line'}}>{questionLang}</p>
              <AppCheckbox
                sx={styles.checkboxCtn}
                id={`${initial?.id}`}
                name={_camelCase(initial?.title)}
                options={[
                  {'label': `${(lang === 'es') ? 'Si' : 'Yes'}`, 'value': 'yes', 'disabled': false},
                  {'label': 'No', 'value': 'no', 'disabled': false},
                  {'label':  `${(lang === 'es') ? 'Inseguro' : 'Unsure'}`, 'value': 'unsure', 'disabled': false},
                ]}
              />
              <Box>
                <Button color="inherit" type="submit">
                  {t("question.btnNext")}
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export const SingleList = ({onSubmit}) => {
  const {lang, t, initial} = useContext(QuestionnaireContext);
  const states = _includes(_toLower(initial?.title), "state");
  const counties = _includes(_toLower(initial?.title), "county");
  const cities = _includes(_toLower(initial?.title), "city");
  const ruralOptions = _includes(
    _toLower(initial?.title),
    "rural renewal county or empowerment zone code"
  );

  const questionLang = (lang === 'es') ? initial?.spanishTitle : initial?.title;

  const styles = SingleListStyles();
  const validationSchema = createValidationSchema(initial, t, lang);

  return (
    <>
      <Formik
        initialValues={{
          [_camelCase(initial?.title)]: initial?.answer?.answer || "none",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form
              autoComplete="false"
              className="formCreateUser"
              noValidate
            >
              <p className="title">
                {questionLang}
                <Tooltip
                  style={{color: "#80BB57", marginTop: "-0.2em"}}
                  title={<p>{initial?.info} </p>}
                  placement="right"
                  arrow
                >
                  <IconButton>
                    <HelpOutlined/>
                  </IconButton>
                </Tooltip>
              </p>
              <AppSelect
                id={`select${_startCase(initial?.title)}`}
                name={_camelCase(initial?.title)}
                state = {(states || counties || cities || ruralOptions)}
                data={initial?.options}
                autoFocus
                fullWidth
                sx={styles.selectCtn}
                placeholder={'-- Select --'}
              />
              <Box>
                <Button color="inherit" type="submit">
                  {t("question.btnNext")}
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};