import AppServiceNewApi from "./AppServiceNewApi";

class EmployeeCompanyServiceNewApi extends AppServiceNewApi{
    constructor() {
        super();
        this.path = '/api/internal/v1/employees';
    }
    //apis internals
    
    getEmployeesByCompany(id, organizationId, pagination, filters, sort){
        let filterString = '&'
        if(filters?.name)filterString = `${filterString}DynamicSearch=${encodeURIComponent(filters?.name)}&`
        if(filters?.status)filterString = `${filterString}DashboardStatus=${filters?.status}&`
        if(sort?.order)filterString = `${filterString}SortBy=${sort.order}&SortComplement=${sort?.direction}&`
        if(filterString === '&')filterString = ''
        else filterString = filterString.substring(0, filterString?.length - 1)
        const headers = id ? {'companyId':id, 'organizationId': organizationId} : {'organizationId': organizationId}
        const url = `${this.path}?PageNumber=${pagination?.page}&PageSize=${pagination?.size}${filterString}`
        return this.http.get(url, {headers});
    }
    
    getEmployeeById(employeeId, companyId){
        const headers = {'companyId':companyId};
        return this.http.get(`${this.path}/${employeeId}`, {headers});
    }
    
    addEmployee(body,id){
        const headers = {'companyId':id}
        const URL = `${this.path}`
        return this.http.post(URL, body, {headers})
    }

    donwloadTemplete(){
        return this.http.get(`${this.path}/download-employee-csv`);
    }

    chargeCsvFile(file, companyId){
        const headers = {'companyId':companyId}
        return this.http.post(`${this.path}/import-information`, file, {headers});
    }

    exportEmployeesByCompany(companyId){
        const URL = companyId ? `${this.path}/export?CompanyId=${companyId}` : `${this.path}/export`
        return this.http.get(URL)
    }

    editEmployee(body, companyId, employeeId, isLiveEz=false){
        const headers = {'companyId':companyId}
        const URL = `${this.path}/${employeeId}?EzUpdate=${isLiveEz}`
        return this.http.put(URL, body, {headers})
    }
    
    editEmployeeByProp(companyId, employeeId, prop){
        const headers = {'companyId':companyId}
        const URL = `${this.path}/dasboard/${employeeId}`
        return this.http.put(URL, prop, {headers})
    }

    sendLink(id) {
        return this.http.post(`${this.path}/${id}/questionnaire-link`);
    }

    // external api

    addEmployeeExternal(body, token){
        const headers = {'token':token}
        const URL = `/api/v1/employees/from-qr`
        return this.http.post(URL, body, {headers})
    }

}
export default EmployeeCompanyServiceNewApi