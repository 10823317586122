// @vendors
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

// @material-ui
import {
  Button,
  Paper,
  TableContainer,
} from "../../../components/shared/MaterialUI";
import {
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

// @assets
import { PayrollStyle } from '../../../assets/css/payroll-style';
import excelIcon from '../../../assets/images/excel.svg';
import editIcon from '../../../assets/images/edit.svg';

// @components
import ToolbarPayroll from './ToolbarPayroll';
import DialogNotes from './DialogNotes';
import EditItemPayroll from '../Edit/EditItemPayroll';
import GeneralTable from './GeneralTable';
import ExportToExcel from './ExportToExcel';
import InvoiceSumary from './InvoiceSumary';
import PaginatorTable from './PaginatorTable';

const PayrollTable = ({data}) => {
  const style = PayrollStyle()
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemSelected, setItemSelected] = useState(null)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [anchorElExcel, setAnchorElExcel] = useState(null);
  const state = useSelector((state) => state)
  const currentTab = state?.tabPayroll?.tab

  const handleClickNotes = (event, item) => {
    setItemSelected(item)
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
    setItemSelected(null)
    setOpenModalEdit(false)
    setAnchorElExcel(null);
  };

  const handleOpenModalEdit = (item) => {
    setItemSelected(item)
    setOpenModalEdit(true)
  }

  const handleClickExportExcel = (event) => {
    setAnchorElExcel(event.currentTarget);
  };

  return (
    <Paper elevation={4}>
      <Toolbar>
       <div style={style.containerToolbar}>
        <ToolbarPayroll/>
        <div style={style.contSearchAndExcel}>
          <FormControl variant="outlined">
              <OutlinedInput
                id="outlined-adornment-weight"
                startAdornment={<InputAdornment position="start"><Visibility sx={style.iconSearch} /></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{'aria-label': 'weight'}}
                sx={style.fieldSearch}
                placeholder='Search'
              />
            </FormControl>
            <Button
              variant="contained"
              startIcon={<img src={excelIcon} alt='Buttom for download excel' style={{width: '16px'}}/>}
              sx={style.btnExcel}
              onClick={handleClickExportExcel}
            >
              Export to Excel
            </Button>
          </div>
        </div>
      </Toolbar>
      <TableContainer sx={style.tableContainer} component={Paper}>
        {
          currentTab === 1 || currentTab === 2
            ? <div style={style.divTableAndAction}>
                <div style={style.divTable}>
                  <GeneralTable data={data}/>
                </div>
                <div>
                  <Typography sx={style.tableHeadTitleAction} >Action</Typography>
                  <Divider/>
                  {data?.data?.map((item,index) => (
                    <Box sx={style.containerIcons} key={index}>
                      <SpeakerNotesIcon
                        onClick={(e) => handleClickNotes(e, item)}
                        sx={style.iconMessageAndEdit}
                      />
                      <img
                        src={editIcon}
                        alt='Buttom for edit'
                        style={style.iconMessageAndEdit}
                        onClick={() => handleOpenModalEdit(item)}
                      />
                    </Box>
                  ))}
                </div>
              </div>
            : null
        }

        {currentTab === 3 ? <InvoiceSumary data={data}/> : null}
        {currentTab === 3
          ? null
          : <PaginatorTable/>
        }
      </TableContainer>
      <DialogNotes
        anchorEl={anchorEl}
        handleClose={handleCloseModal}
        idItemSelected={itemSelected}
      />
      <EditItemPayroll
        open={openModalEdit}
        handleClose={handleCloseModal}
        data={itemSelected}
      />
      <ExportToExcel
        anchorEl={anchorElExcel}
        handleClose={handleCloseModal}
      />
    </Paper>
  )
}

export default PayrollTable