import { makeStyles } from '@mui/styles';

export const AppStyle = makeStyles((theme) => ({
  txtBold: {
    fontWeight: 'bold',
  },
  txtTitle: {
    fontSize: 28,
  },
  mrg0T: {
    marginTop: 0,
  },
  mrg0V: {
    marginTop: 0,
    marginBottom: 0,
  },
  mrg12H: {
    marginTop: 12,
  },
  pdg0: {
    padding: 0,
  },
  pdg20: {
    padding: 20,
  },
  txtRight: {
    textAlign: 'right',
  },
  btnRight: {
    float: 'right',
  },
  pmg0: {
    padding: 0,
    margin: 0,
  },
  pdg5H: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  pdg20H: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  btnLogout: {
    height: '3rem',
    width: '100%',
  },
}));
