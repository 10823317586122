import { theme } from "../theme";

export const CreateEmployeeStyles = () => ({
  container: {
    padding: '48px 96px',
    '& h3': {
      marginBottom: '48px',
      fontSize: '3rem',
      fontWeight: 700,
    },
    [theme.breakpoints.down('md')]: {
      padding: '48px 24px',
      '& h3': {
        marginBottom: '24px',
        fontSize: '1.375rem',
        textAlign: 'center',
      },
    },
  },
  inputsCtn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: '24px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  boxItem: {
    marginBottom: '16px',
    '& > p': {
      fontSize: '1.125rem',
      margin: '0 0 12px',
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '8px',
      '& > p': {
        fontSize: '1rem',
      },
    },
  },
  input: {
    width: '100%',
    '& .MuiInputLabel-root': { padding: '0' },
    '& .MuiOutlinedInput-root': {
      height: '56px',
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: '2px',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #101010',
      },
      '& .MuiInputBase-input': { padding: '12px 20px' },
    },
    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      padding: '12px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-10px',
      left: '10px',
      background: '#FFF',
      padding: '0 10px',
      fontSize: '0.875rem',
      margin: '0',
    },
    '> .MuiFormLabel-root.Mui-focused': {
      top: '0',
      color: '#000',
    },
  },
  formButtonWrapper: {
    margin: '48px auto 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    '& .MuiButton-root': {
      height: '56px',
      width: '45%',
      textTransform: 'none',
      backgroundColor: 'rgba(36, 35, 35, 0.3)',
      opacity: '0.5',
      color: '#FFF',
      fontWeight: '600',
      fontSize: '1.25rem',
      boxShadow: 4,
      '&:hover': {
        backgroundColor: 'rgba(36, 35, 35, 0.3)',
        boxShadow: 6,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '48px',
        fontSize: '1rem',
      },
    },
    '& .MuiButton-root:last-of-type': {
      height: '56px',
      width: '45%',
      backgroundColor: '#000',
      opacity: '1',
      color: '#FFF',
      '&:hover': {
        boxShadow: 12,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '48px',
        fontSize: '1rem',
      },
    },
    [theme.breakpoints.down('md')]: {
     flexDirection: 'column-reverse',
     gap: '16px',
    },
  },
  select: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '16px 20px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #101010',
    },
  },
  selectError: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #d32f2f',
      '&:hover': {
        border: '1px solid #d32f2f',
      },
    },
  },
  stateError: {
    position: 'absolute',
    bottom: '-20px',
    left: '15px',
    background: '#FFF',
    color: '#d32f2f',
    padding: '0 8px',
    fontSize: '0.875rem !important',
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  dialog: {
    maxWidth: 'sm',
    title: {
      fontSize: '2rem',
      fontWeight: '700',
      color: '#101010',
    },
    content: {
      fontSize: '1.5rem',
      color: '#101010',
    },
    dialogBox: {
      '& .MuiDialog-paper': {
        padding: '48px',
      },
    },
    cancelButton: 'rgba(36, 35, 35, 0.5)',
    ctaButton: '#101010',
    showButton: 'none',
  },
});
