// @vendors
import React from 'react'
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// @material-ui
import {
    Box,
    Dialog,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Grid,
} from "../../../components/shared/MaterialUI";

// @assets
import { DialogFilterPayroll, EditPayrollStyle } from '../../../assets/css/payroll-style';

// @components
import FormEditHours from './FormEditHours';
import FormEditDetail from './FormEditDetail';

// @constants
import { types } from '../../../redux/types';

// @services
import PayrollService from '../../../services/newApi/PayrollService';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { callListPayroll } from '../../../helpers/FunctionsUtils';

// @state
import { useUI } from '../../../app/context/ui';


const listMonth = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

const EditItemPayroll = ({
    open,
    handleClose,
    data,
}) => {
    const style = EditPayrollStyle()
    const styleButtons = DialogFilterPayroll()
    const stateEdit = useSelector((state) => state?.editEmployeePayroll)
    const dispatch = useDispatch()
    const employeePayrollService = new PayrollService()
    const { blockUI, snackbarUI } = useUI();

    const handleSubmit = () => {
        const DATA = {
            stateCertification: stateEdit?.stateCertification,
            stateStatus: stateEdit?.stateStatus ? stateEdit?.stateStatus : data?.stateStatusId,
            paySchedule: stateEdit?.paySchedule ? stateEdit?.paySchedule : data?.payScheduleId,
            note: data?.note,
        }
        updateEmployee(DATA)
    }

    const updateStateInfoEmployee = () => {
        dispatch({
            type: types.EDIT_EMPLOYEE_PAYROLL,
            stateCertification: null,
            stateStatus: null,
            paySchedule: null
        })
    }

    const updateEmployee = async (info) => {
        try {
            blockUI.current.open(true);
            await employeePayrollService.updateEmploye(data?.employeeId, info)
            setTimeout(() => {
                updateStateInfoEmployee()
            }, 2000);
            callListPayroll(dispatch, true)
            handleClose()
            blockUI.current.open(false);
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI); 
        }
    }

  return (
    <Dialog
        sx={style.dialogEdit}
        open={open}
        fullWidth={true}
        maxWidth="xl"
    >
      <Box sx={style.body}>
        <Typography sx={style.title}>Edit</Typography>

        <Box sx={style.table}>
            <Table aria-label="table" stickyHeader >
                <TableHead sx={style.tableHead}>
                    <TableRow variant="head">
                        <Box sx={style.tableRowEdit}>
                            <TableCell sx={style.tableHeadTitleEdit}>Last Sync Date</TableCell> 
                            <TableCell sx={style.tableHeadTitleEdit}>State Cert.</TableCell> 
                            <TableCell sx={style.tableHeadTitleEdit}>State Status</TableCell>
                            <TableCell sx={style.tableHeadTitleEdit}>Employee First Name</TableCell>        
                            <TableCell sx={style.tableHeadTitleEdit}>Employee Last Name</TableCell>        
                            <TableCell sx={style.tableHeadTitleEdit}>Employeer</TableCell>        
                            <TableCell sx={style.tableHeadTitleEdit}>Employer Rate (%)</TableCell>        
                            <TableCell sx={style.tableHeadTitleEdit}>Target Group</TableCell>        
                            <TableCell sx={style.tableHeadTitleEdit}>Target Group Code</TableCell>        
                            <TableCell sx={style.tableHeadTitleEdit}>Hire Date</TableCell>   
                            <TableCell sx={style.tableHeadTitleEdit}>Pay Schedule</TableCell>
                            <TableCell sx={style.tableHeadTitleEdit}>Current YTD Gross wages ($)</TableCell>
                            <TableCell sx={style.tableHeadTitleEdit}>Current YTD Hours</TableCell>
                            <TableCell sx={style.tableHeadTitleEdit}>WOTC Credit % Multiplier</TableCell>  
                            <TableCell sx={style.tableHeadTitleEdit}>Max Wage ($)</TableCell>
                            <TableCell sx={style.tableHeadTitleEdit}>Max Tax Credit ($)</TableCell>
                            <TableCell sx={style.tableHeadTitleEdit}>Tax Credit YTD ($)</TableCell>
                            <TableCell sx={style.tableHeadTitleEdit}>Invoice YTD ($)</TableCell>
                            <TableCell sx={style.tableHeadTitleEdit}>Actual Invoice ($)</TableCell>
                        </Box>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={style.tableRowEdit}>
                        <FormEditDetail data={data}/>
                    </TableRow>   
                </TableBody>
            </Table>
        </Box>

        <Box sx={style.containerFormsHoursAndWage}>
            <Box sx={style.formsHoursAndWage}>
            <Box sx={style.containerNameMonth}>
                <Grid sx={{width: '90px'}}></Grid>
                <Grid container sx={style.nameMonth} >
                    {listMonth?.map((month) => (
                        <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm}>
                            <Typography sx={style.month}>{month}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Divider sx={style.divider}/>

            <Box sx={style.containerForms}>
                <Box sx={style.hourWage}>Hours</Box>
                <FormEditHours/>
            </Box>
            <Divider sx={style.divider}/>
            
            <Box sx={style.containerForms}>
                <Box sx={style.hourWage}>Wage ($)</Box>
                <FormEditHours/>
            </Box>
            <Divider sx={style.divider}/>
            </Box>
        </Box>

        <Box sx={styleButtons.divActions}>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={styleButtons.buttonCancel}
              >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              sx={styleButtons.buttonApply}
              >
              Save
            </Button>
          </Box>
        
      </Box>
      
    </Dialog>
  )
}

export default EditItemPayroll