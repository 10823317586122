// @vendors
import React, { useEffect, useState } from 'react'
import {
    Box,
    Container,
    Paper,
    Typography,
} from '@mui/material'
import { useParams, useLocation } from 'react-router-dom';

// @assets
import { QustionVeteranStyles, Styles } from '../../../assets/css/questionnaire-style';
import logo from '../../../assets/images/logoDashboardPublic.svg';

// @context
import { useUI } from '../../../app/context/ui';

// @services
import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';

const SavedVeteranQuestion = () => {
    const styles = Styles();
    const style = QustionVeteranStyles()
    const [companyLogo, setCompanyLogo] = useState()
    const { blockUI } = useUI();
    const newQuestionService = new QuestionnaireServiceNewApi()
    const {hash} = useParams()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang');

    const getLogo = async () => {
        try {
          blockUI.current.open(true);
          const resp = await newQuestionService.callCompanyLogo(hash)
          setCompanyLogo(resp?.data?.data?.logo)
          blockUI.current.open(false);
        } catch (e) {
          blockUI.current.open(false);
        }
    }

    useEffect(() => {
        getLogo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
        <Box sx={styles.logo}>
            {companyLogo 
                ? <img
                    src={companyLogo}
                    alt='dashboard public'
                    style={{
                        borderRadius: '5px',
                        objectFit: 'cover',
                        marginTop: '20px',
                        width: '200px',
                        maxHeight: '150px'
                    }}
                    />
                : <img src={logo} alt='dashboard public' width='250px' />
            }
        </Box>
        <Container>
            <Paper
                elevation={4}
                sx={style.paperVeterean}
            >
                <Box 
                    sx={{
                        display:"flex", 
                        flexDirection:"column", 
                        gap:{ xs: '0.5rem', md: '1rem' },
                        width: "100%",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant='h1'
                        sx={style.greatTilte}
                    >
                        {lang === "es" ? "Excelente!" : "Great!"}
                    </Typography>

                    <>
                        <Typography variant="body1" sx={style.subText} >{lang === "es" ? "La respuesta fue enviada exitosamente." : "The answer was sent succesfully"}</Typography>
                        <Typography variant="body1" sx={style.subText} >{lang === "es" ? "Puedes cerrar esta ventana" : "You can close this window"}</Typography>
                    </>
                </Box>
            </Paper>
        </Container>
    </>
  )
}

export default SavedVeteranQuestion