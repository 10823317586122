import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import Footer from '../Footer';
import Header from '../Header';
import { theme } from '../../assets/theme';

const ProtectedRoute = (props) => {
  let id = props.computedMatch.params.id;
  const Component = props.component;
  const authToken = useSelector((loadedState) => loadedState.user.accessToken);
  const classes = useStyles();

  useEffect(() => {
    document.title = `RockerBox - ${props.title}`;
  });

  return authToken ? (
    <>
      <Header title={props.name} />
      <main className={classes.content}>
        <Component id={id} />
      </main>
      <Footer title={props.name} />
    </>
  ) : (
    <Redirect to={{ pathname: '/home' }} />
  );
};

export default ProtectedRoute;

const useStyles = makeStyles((_) => ({
  content: {
    padding: '64px 0',
    width: '100%',
    height: '100%',
    background: 'rgb(249, 249, 249)',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 0',
    },
  },
}));
