/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import {
  find as _find,
  keys as _keys,
  mapValues as _mapValues,
  pick as _pick,
  toString as _toString,
  trim as _trim,
} from 'lodash';
import sx from 'mui-sx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';

// @state
import { useUI } from '../../../../app/context/ui';
import { CompanyContext } from '../CreateEmployer';

// @assets
import {CreateEmployerStyles} from '../../../../assets/css/createEmployer-style';

// @material
import {
  Box,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
} from '../../../../components/shared/MaterialUI';

// @helperst
import AppHelper from '../../../../helpers/AppHelper';
import { formatZipCode } from '../../../../helpers/FormatZipCode';
import { formatPhoneNumber } from '../../../../helpers/FormatPhoneNumber';

// @services
import HelpersServiceNewApi from '../../../../services/newApi/HelpersServiceNewApi';
import CompanyServiceNewApi from '../../../../services/newApi/CompanyServiceNewApi';
import { useSelector } from 'react-redux';

const FirstForm = () => {
  const {
    handleStepDirection,
    id,
    state,
    dispatch,
    dataCompany,
    parentId
  } = useContext(CompanyContext);
  const { firstFormValues, geoLocation } = state;
  const { states, counties, cities } = geoLocation;
  const history = useHistory();
  const { blockUI, snackbarUI } = useUI();
  const userStyle = CreateEmployerStyles();
  const [isMobile, setIsMobile] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const statesService = new HelpersServiceNewApi()
  const companyService = new CompanyServiceNewApi()
  const [listGroups, setListGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupsEmpty, setGroupsEmpty] = useState(false);
  const [selectAllLabel, setSelectAllLabel] = useState(false);
  const globalState = useSelector((state) => state.organizationReducer.organizationSelected);

  useEffect(() => {
    const data = _pick(dataCompany, _keys(firstFormValues));
    if(dataCompany?.id)preloadDataCompany();
    if(parentId)dispatch({
      type: 'setFirstFormValues',
      payload: {
        ...data,
        parentCompanyId: parentId,
        otherCity: data?.city ?? '',
        otherCounty: data?.county ?? '',
        externalCompanyId: '',
        rbAcctId: '',
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const preloadDataCompany =async () => {
    setSelectedGroups(dataCompany?.tiles)
    if(!dataCompany?.isCountyOrCityManual){
      await getStateCountyCity(dataCompany?.state);
      await getStateCountyCity(dataCompany?.state, dataCompany?.county);
    }
  }

  const getStateCountyCity = async (stateName, county) => {
    try {
      blockUI.current.open(true);
      const response = await statesService.getLocalities(stateName, county)
      const data = response?.data?.data
      dataLocalities(data, stateName, county)
      blockUI.current.open(false);
      return data
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const dataLocalities = (resp, stateName, county) => {
    if(stateName && county){
      dispatch({
        type: 'getCities',
        payload: resp,
      });
      return
    }
    if(stateName && !county){
      dispatch({
        type: 'getCounties',
        payload: resp,
      });
      return
    }
    return dispatch({
      type: 'getStates',
      payload: resp,
    });
  } 

  const getEmployerDetails = async (id) => {
    try {
      const token = companyService.getAccessToken();
      companyService.setAccessToken(token);
      if(!token)return
      blockUI.current.open(true);
      const resp = await companyService.getCompanyById(id);
      const res1 = _pick(resp?.data?.data, _keys(firstFormValues));
      const data = res1;
      setSelectedGroups(resp?.data?.data?.tiles)
      dispatch({
        type: 'setFirstFormValues',
        payload: {...data, otherCity: data?.city, otherCounty: data?.county},
      });
      blockUI.current.open(false);
      return data;
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    dispatch({
      type: 'handleFFInputs',
      payload: {
        phoneNumber: formattedPhoneNumber
      }
    });
  };

  const handleZipCodeChange = (e) => {
    const formattedZipCode = formatZipCode(e.target.value);
    dispatch({
      type: 'handleFFInputs',
      payload: {
        zipCode: formattedZipCode
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'state': {
        getCountiesForState(value)
        break;
      }
      case 'county': {
        getCityForCounty(value)
        break;
      }
      case 'city': {
        dispatch({
          type: 'setCity',
          payload: value
        });
        break;
      }
      case 'fein': {
        if (value.length > 9) {
          return;
        }
        dispatch({
          type: 'handleFFInputs',
          payload: { [e.target.name]: e.target.value }
        });
        break;
      }
      default: {
        dispatch({
          type: 'handleFFInputs',
          payload: { [e.target.name]: e.target.value }
        });
      }
    }
  };

  const handleChangeValue = (e) => {
    dispatch({
      type: 'handleFFInputs',
      payload: { [e.target.name]: e.target.checked }
    });
  }

  const getCountiesForState = (value) => {
    dispatch({
      type: 'setState',
      payload: value,
    });
    const stateSelected =_find(states, (s) => {
      return s?.name === value;
    })
    setSelectedState(stateSelected);
    dispatch({
      type: 'setStateId',
      payload: stateSelected.id,
    });
    getStateCountyCity(stateSelected?.name)
  }

  const getCityForCounty = (value) => {
    dispatch({
      type: 'setCounty',
      payload: value,
    });
    const state = selectedState?.name ? selectedState?.name : firstFormValues?.stateId
    getStateCountyCity(state, value)
  }

  const isTrueOrFalse = (value) => {
    if(value === 'true' || value === true) return true
    return false
  }

  const handleChangewebhook = (e) => {
    dispatch({
      type: 'setFirstFormValues',
      payload: {
        ...firstFormValues,
        webhookUrl: '',
        webhookSecret: '',
        isWebhookConfigured: e.target.checked
      },
    });
  }
  
  const markLikeAxiscare = (e) => {
    dispatch({
      type: 'setFirstFormValues',
      payload: {
        ...firstFormValues,
        site: '',
        agency: '',
        isAxiscare: e.target.checked
      },
    });
  }

  const convertEmailTolowercase = (e) => {
    const {value} = e.target
    const newValue = value?.toLowerCase()
    dispatch({
      type: 'setFirstFormValues',
      payload: {
        ...firstFormValues,
        email: newValue,
      },
    });
  }

  const validationSchema = Yup.object().shape({
      displayName: Yup.string('Enter your display name')
        .nullable()
        .notRequired(),
      name: Yup.string('Enter your Legal name')
        .max(50, 'Max. 50 characters')
        .required('Legal name is required'),
      tradeName: Yup.string('Enter your Trade name')
        .max(50, 'Max. 50 characters')
        .nullable(),
      phoneNumber: Yup.string()
        .required('Telephone is required')
        .typeError(`Telephone can't be empty`)
        .test('valid-length', 'Please enter a valid phone', (value) => {
          if (value) {
            return value && value?.length === 14;
          } else {
            return false;
          }
        }),
      fein: Yup.string()
        .matches(/^\d+$/, 'FEIN must be only numbers')
        .max(9, 'FEIN must be only 9 digits')
        .min(9, 'FEIN must be 9 digits')
        .required('FEIN is required'),
      address: Yup.string('Enter your Address').required('Address is required'),
      state: Yup.string('Enter your State').required('State is required'),
      stateId: Yup.string('Enter your State ID').required('State ID is required'),
      county: isTrueOrFalse(firstFormValues?.isCountyOrCityManual)
        ? null
        : Yup.string('Enter your County').required('County is required'),
      city: isTrueOrFalse(firstFormValues?.isCountyOrCityManual)
        ? null
        : Yup.string('Enter your City').required('City is required'),
      otherCounty: !isTrueOrFalse(firstFormValues?.isCountyOrCityManual)
        ? null
        : Yup.string('Enter your County').required('County is required'),
      otherCity: !isTrueOrFalse(firstFormValues?.isCountyOrCityManual)
        ? null
        : Yup.string('Enter your City').required('City is required'),
      externalCompanyId: Yup.string().nullable().notRequired(),
      rbAcctId: Yup.string().nullable().notRequired(),
      hubspotId: Yup.string().nullable().notRequired(),
      webhookUrl: !isTrueOrFalse(firstFormValues.isWebhookConfigured)
        ? null
        : Yup.string().nullable().required('This field is required'),
      webhookSecret: !isTrueOrFalse(firstFormValues.isWebhookConfigured)
        ? null
        : Yup.string().nullable().required('This field is required'),
      site: !isTrueOrFalse(firstFormValues.isAxiscare)
        ? null
        : Yup.string()
        .nullable()
        .required('This field is required')
        .matches(/^[a-zA-ZÀ-ÿ0-9 ,\s']+$/, "Not symbols allowed"),
      agency: !isTrueOrFalse(firstFormValues.isAxiscare)
        ? null
        : Yup.string()
        .nullable()
        .required('This field is required')
        .matches(/^[a-zA-ZÀ-ÿ0-9 ,\s']+$/, "Not symbols allowed"),
      zipCode: Yup.string('Enter your ZIP Code')
        .matches(/^\d+$/, 'zipCode must be only numbers')
        .max(5, 'zipCode must be only 5 digits')
        .min(5, 'zipCode must be 5 digits')
        .required('ZIP Code is required'),
      email: Yup.string('Enter your email address')
        .email('Invalid email')
        .required('Email is required'),
    },
    [[]]
  );

  const onSubmit = async () => {
    if(firstFormValues?.isWebhookConfigured === true || firstFormValues.isWebhookConfigured === 'true'){
      if(!firstFormValues?.webhookUrl || !firstFormValues?.webhookSecret)return
      const webhookIsConfigured = await checkIfWebhookIsOk()
      if(!webhookIsConfigured)return
    }
    if(groupsEmpty || !selectedGroups.length)return setGroupsEmpty(true) 
    let values = _mapValues(
      function (value, key) {
        return _trim(_toString(value));
      }
    );
    const payload = {...values, tiles: selectedGroups}
    dispatch({
      type: 'setFirstFormValues',
      payload: payload,
    });
    handleStepDirection();
  };

  const getTargetGroups = async () => {
    try {
      blockUI.current.open(true);
      const response = await statesService.targetGropus()
      const data = response?.data
      if(!id && !dataCompany?.id)markAllByDefault(data)
      setListGroups(data)
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const markAllByDefault = (list) => {
    const listId = list?.map((item) => {
      return item?.id
    })
    setSelectedGroups(listId)
  }

  useEffect(() => {
    getTargetGroups()
    getStateCountyCity()
    if (id) {
      getEmployerDetails(id)
        .then(async (data) => {
          await getStateCountyCity(data?.state);
          await getStateCountyCity(data?.state, data?.county);
        },(e)=>{
          AppHelper.checkError(e, snackbarUI);
        })
    }
  }, []);

  const verifyScreenSize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    verifyScreenSize();
    window.addEventListener('resize', verifyScreenSize);
    return () => {
      window.removeEventListener('resize', verifyScreenSize);
    };
  }, []);

  const handleChangeCheck = (e) => {
    dispatch({
      type: 'setFirstFormValues',
      payload: {
        ...firstFormValues,
        county: '',
        city: '',
        otherCity: '',
        otherCounty: '',
        isCountyOrCityManual: e.target.checked
      },
    });
  }

  const handleChangeCountyCity = (e, type) => {
    if(type === 'county'){
      dispatch({
        type: 'setFirstFormValues',
        payload: {
          ...firstFormValues,
          county: e.target.value,
          otherCounty: e.target.value
        },
      });
    }else{
      dispatch({
        type: 'setFirstFormValues',
        payload: {
          ...firstFormValues,
          city: e.target.value,
          otherCity: e.target.value
        },
      });
    }
  }

  const checkIfWebhookIsOk = async () => {
    const DATA = {
      webhookUrl: firstFormValues?.webhookUrl,
      webhookSecret: firstFormValues?.webhookSecret,
    }
    try {
      blockUI.current.open(true);
      const response = await companyService.validateWebhookInformation(DATA)
      AppHelper.showCustomMessage(response?.data?.data?.message, snackbarUI);
      blockUI.current.open(false);
      return true
    } catch (e) {
      getErrorForCalWebhook(e)
      return false
    }
  }

  const getErrorForCalWebhook = (e) => {
    const error = e?.response?.data?.errors[0]
    blockUI.current.open(false);
    if(error) return AppHelper.showCustomMessage(error, snackbarUI);
    return AppHelper.checkError(e, snackbarUI);
  }

  const handleChangeGroups = (e) => {
    if(e.target.checked){
      setSelectedGroups([...selectedGroups, parseInt(e.target.value)]);
      setGroupsEmpty(false);
    }else{
      const filtered = selectedGroups.filter((item) => item !== parseInt(e.target.value));
      setSelectedGroups(filtered);
      if(filtered?.length === 0){
        setGroupsEmpty(true);
        setSelectAllLabel(false);
      }
      if(filtered?.length > 0)setGroupsEmpty(false);
    }
  }
  
  const handleCheckAllGroups = (e) => {
    if(e.target.checked){
      setSelectAllLabel(true);
      const checkAll = listGroups?.map((x) => x?.id);
      setSelectedGroups(checkAll);
      setGroupsEmpty(false);
    }else{
      setSelectAllLabel(false);
      setSelectedGroups([]);
      setGroupsEmpty(true);
    }
  }

  const isGroupChecked = (id) => {
    const isSelected = selectedGroups?.find((item) => parseInt(item) === id)
    if(isSelected)return true
    return false
  }

  const isRockerbox = () => {
    const isRocker = globalState?.name?.toLowerCase();
    if(isRocker === 'rockerbox')return true;
    return false;
  }

  return (
    <Grid container sx={{ padding: '0px 70px 70px 70px' }}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{ textAlign: 'center', marginTop: '110px', marginBottom: '30px' }}
      >
        <Typography variant="h3">
          {id ? 'Edit employer' : 'Create new employer'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Formik
          initialValues={{...firstFormValues}}
          validationSchema={validationSchema}
          onSubmit={() => {
            onSubmit();
          }}
          enableReinitialize={true}
        >
          {(props) => {
            const { touched, errors, handleBlur } = props;
            return (
              <Form autoComplete="off" className="formCreateUser" noValidate>
                <Grid item md={12} xs={12}>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>Display name:</p>
                      <TextField
                        label="Type your display name here"
                        variant="outlined"
                        id="displayName"
                        name="displayName"
                        value={firstFormValues.displayName || ''}
                        onChange={(e) => handleChange(e, errors)}
                        onBlur={handleBlur}
                        error={touched.displayName && Boolean(errors.displayName)}
                        helperText={
                          errors.displayName && touched.displayName ? errors.displayName : ''
                        }
                        sx={userStyle.inputCustom}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <p>
                        Legal entity name:{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </p>
                      <TextField
                        label="Type your name here"
                        variant="outlined"
                        id="name"
                        name="name"
                        value={firstFormValues.name || ''}
                        onChange={(e) => handleChange(e, errors)}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={
                          errors.name && touched.name ? errors.name : ''
                        }
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>Trade name (if different):</p>
                      <TextField
                        label="Type your trade name here"
                        variant="outlined"
                        id="tradeName"
                        name="tradeName"
                        value={firstFormValues.tradeName || ''}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        error={touched.tradeName && Boolean(errors.tradeName)}
                        helperText={
                          errors.tradeName && touched.tradeName
                            ? errors.tradeName
                            : ''
                        }
                        sx={userStyle.inputCustom}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} >
                      <p style={{color: '#80BB57', fontWeight: 'bold'}}>RB acct id:</p>
                      <TextField
                        label="Type your RB acct id"
                        variant="outlined"
                        id="rbAcctId"
                        name="rbAcctId"
                        value={firstFormValues.rbAcctId || ''}
                        onChange={(e) => handleChange(e, errors)}
                        onBlur={handleBlur}
                        error={touched.rbAcctId && Boolean(errors.rbAcctId)}
                        helperText={errors.rbAcctId && touched.rbAcctId ? errors.rbAcctId : ''}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>
                        Phone number: <span style={{ color: 'red' }}>*</span>
                      </p>
                      <TextField
                        sx={userStyle.inputCustom}
                        label="Type your phone number here"
                        placeholder="(555) 555-5555"
                        variant="outlined"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={firstFormValues.phoneNumber || ''}
                        onChange={(phone) => handlePhoneChange(phone)}
                        onBlur={handleBlur}
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        helperText={
                          errors.phoneNumber && touched.phoneNumber
                            ? errors.phoneNumber
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        FEIN: <span style={{ color: 'red' }}>*</span>
                      </p>
                      <TextField
                        label="Type your FEIN here"
                        variant="outlined"
                        id="fein"
                        name="fein"
                        value={firstFormValues.fein || ''}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        error={touched.fein && Boolean(errors.fein)}
                        helperText={
                          errors.fein && touched.fein ? errors.fein : ''
                        }
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>
                        Address: <span style={{ color: 'red' }}>*</span>
                      </p>
                      <TextField
                        label="Type your address here"
                        variant="outlined"
                        id="address"
                        name="address"
                        value={firstFormValues.address || ''}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        error={touched.address && Boolean(errors.address)}
                        helperText={
                          errors.address && touched.address
                            ? errors.address
                            : ''
                        }
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        State: <span style={{ color: 'red' }}>*</span>
                      </p>
                      <FormControl required fullWidth>
                      <InputLabel>State</InputLabel>
                      <Select
                        id='state'
                        name='state'
                        value={firstFormValues.state || ''}
                        label="State *"
                        onChange={(e) => {handleChange(e)}}
                        onBlur={handleBlur}
                        sx={sx(userStyle.select, {
                          condition: Boolean(errors.state && touched.state),
                          sx: userStyle.selectError,
                        })}
                      >
                        {React.Children.toArray(
                          states &&
                            states?.map((state) => (
                              <MenuItem value={state.name}>{state.name}</MenuItem>
                            ))
                        )}
                      </Select>
                      {errors.state && touched.state && (
                        <Typography sx={userStyle.stateError}>{errors.state}</Typography>
                      )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5} sx={userStyle.formEmployerContainerCheckbox}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        id="selectEmployeeStateHs"
                        name="selectEmployeeStateHs"
                        checked={isTrueOrFalse(firstFormValues?.selectEmployeeStateHs)}
                        disabled={false}
                        control={
                          <Checkbox
                            sx={{margin: '0px'}}
                            color="success"
                            onChange={(e) => handleChangeValue(e)}
                          />
                        }
                        label="Select employee' state for hubspot"
                        labelPlacement="end"
                        sx={userStyle.checkbox}
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid container spacing={(isMobile) ? 0 : 5} sx={userStyle.formEmployerContainerCheckbox}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        checked={isTrueOrFalse(firstFormValues?.isCountyOrCityManual)}
                        disabled={false}
                        control={
                          <Checkbox
                            sx={{margin: '0px'}}
                            color="success"
                            onChange={(e) => handleChangeCheck(e)} 
                          />
                        }
                        label='Add county and city manually'
                        labelPlacement="end"
                        sx={userStyle.checkbox}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box>
                        <FormControlLabel
                          id="sendEmailToEmployeeWhenCreated"
                          name="sendEmailToEmployeeWhenCreated"
                          checked={isTrueOrFalse(firstFormValues.sendEmailToEmployeeWhenCreated)}
                          disabled={false}
                          control={
                            <Checkbox
                              sx={{ margin: '0px' }}
                              color="success"
                              onChange={(e) => handleChangeValue(e)}
                            />
                          }
                          label="Send email to employee when employee is created"
                          labelPlacement="end"
                          sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            margin: 0,
                            gap: '10px'
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  {isTrueOrFalse(firstFormValues?.isCountyOrCityManual) &&
                    <Grid container spacing={(isMobile) ? 0 : 5}>
                      <Grid item xs={12} md={6}>
                        <p>
                          County: <span style={{ color: 'red' }}>*</span>
                        </p>
                        <TextField
                          label='County *'
                          variant='outlined'
                          id='otherCounty'
                          name='otherCounty'
                          value={firstFormValues.otherCounty || ''}
                          onChange={(e) => handleChangeCountyCity(e, 'county')}
                          onBlur={handleBlur}
                          error={touched.otherCounty && Boolean(errors.otherCounty)}
                          helperText={errors.otherCounty && touched.otherCounty ? errors.otherCounty : ''}
                          sx={userStyle.inputCustom}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          City: <span style={{ color: 'red' }}>*</span>
                        </p>
                        <TextField
                          label='City *'
                          variant='outlined'
                          id='otherCity'
                          name='otherCity'
                          value={firstFormValues.otherCity || ''}
                          onChange={(e) => handleChangeCountyCity(e, 'city')}
                          onBlur={handleBlur}
                          error={touched.otherCity && Boolean(errors.otherCity)}
                          helperText={errors.otherCity && touched.otherCity ? errors.otherCity : ''}
                          sx={userStyle.inputCustom}
                        />
                      </Grid>
                    </Grid>
                  }

                  {!isTrueOrFalse(firstFormValues?.isCountyOrCityManual) &&  
                    <Grid container spacing={(isMobile) ? 0 : 5}>
                      <Grid item xs={12} md={6}>
                        <p>
                          County: <span style={{ color: 'red' }}>*</span>
                        </p>
                        <FormControl required fullWidth>
                          <InputLabel>County</InputLabel>
                          <Select
                            value={firstFormValues.county || ''}
                            id='county'
                            name='county'
                            onChange={(e) => {handleChange(e)}}
                            onBlur={handleBlur}
                            sx={sx(userStyle.select, {
                              condition: Boolean(errors.county && touched.county),
                              sx: userStyle.selectError,
                            })}
                            label="county *"
                          >
                            {React.Children.toArray(
                              counties &&
                                counties?.map((county) => (
                                  <MenuItem value={county.name}>{county.name}</MenuItem>
                                ))
                            )}
                          </Select>
                          {errors.county && touched.county && (
                            <Typography sx={userStyle.stateError}>{errors.county}</Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <p>
                          City: <span style={{ color: 'red' }}>*</span>
                        </p>
                        <FormControl required fullWidth>
                          <InputLabel>City</InputLabel>
                          <Select
                            value={firstFormValues.city || ''}
                            id='city'
                            name='city'
                            onChange={(e) => {handleChange(e)}}
                            onBlur={handleBlur}
                            sx={sx(userStyle.select, {
                              condition: Boolean(errors.city && touched.city),
                              sx: userStyle.selectError,
                            })}
                            label="city *"
                          >
                            {React.Children.toArray(
                              cities &&
                                cities?.map((city) => <MenuItem value={city.name}>{city.name}</MenuItem>)
                            )}
                          </Select>
                          {errors.city && touched.city && (
                              <Typography sx={userStyle.stateError}>{errors.city}</Typography>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  }

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>
                        ZIP Code: <span style={{ color: 'red' }}>*</span>
                      </p>
                      <TextField
                        label="ZIP Code *"
                        variant="outlined"
                        id="zipCode"
                        name="zipCode"
                        value={firstFormValues.zipCode || ''}
                        onChange={(e) => handleZipCodeChange(e)}
                        onBlur={handleBlur}
                        error={touched.zipCode && Boolean(errors.zipCode)}
                        helperText={
                          errors.zipCode && touched.zipCode
                            ? errors.zipCode
                            : ''
                        }
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>
                        Email: <span style={{ color: 'red' }}>*</span>
                      </p>
                      <TextField
                        label="example@email.com"
                        variant="outlined"
                        autoComplete="new-email"
                        id="email"
                        name="email"
                        type="email"
                        value={firstFormValues.email || ''}
                        onChange={(e) => {
                          handleChange(e, errors.email)
                          convertEmailTolowercase(e)
                        }}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={
                          errors.email && touched.email ? errors.email : ''
                        }
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6} >
                      <p>External company id:</p>
                      <TextField
                        label="External company id"
                        variant="outlined"
                        id="externalCompanyId"
                        name="externalCompanyId"
                        value={firstFormValues.externalCompanyId || ''}
                        onChange={(e) => handleChange(e, errors)}
                        onBlur={handleBlur}
                        error={touched.externalCompanyId && Boolean(errors.externalCompanyId)}
                        helperText={errors.externalCompanyId && touched.externalCompanyId ? errors.externalCompanyId : ''}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <p style={{color: '#80BB57', fontWeight: 'bold'}}>Hubspot id:</p>
                      <TextField
                        label="Type your hubspot id here"
                        variant="outlined"
                        id="hubspotId"
                        name="hubspotId"
                        value={firstFormValues.hubspotId || ''}
                        onChange={(e) => handleChange(e, errors)}
                        onBlur={handleBlur}
                        error={touched.hubspotId && Boolean(errors.hubspotId)}
                        helperText={errors.hubspotId && touched.hubspotId ? errors.hubspotId : ''}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        id="sendWotcReminders"
                        name="sendWotcReminders"
                        checked={isTrueOrFalse(firstFormValues.sendWotcReminders)}
                        disabled={false}
                        control={
                          <Checkbox
                            sx={{ margin: '0px' }}
                            color="success"
                            onChange={(e) => handleChangeValue(e)}
                          />
                        }
                        label="Receive WOTC reminders"
                        labelPlacement="end"
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          margin: '30px 0 0 0',
                          gap: '10px'
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        id="isWebhookConfigured"
                        name="isWebhookConfigured"
                        checked={isTrueOrFalse(firstFormValues.isWebhookConfigured)}
                        disabled={false}
                        control={
                          <Checkbox
                            sx={{ margin: '0px' }}
                            color="success"
                            onChange={(e) => handleChangewebhook(e)}
                          />
                        }
                        label="Has webhook?"
                        labelPlacement="end"
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          margin: '30px 0 0 0',
                          gap: '10px'
                        }}
                      />
                    </Grid>
                  </Grid>

                  {isTrueOrFalse(firstFormValues.isWebhookConfigured) &&
                    <Grid container spacing={(isMobile) ? 0 : 5}>
                      <Grid item xs={12} md={6}>
                        <p>
                          Webhook url:{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </p>
                        <TextField
                          label="Type your url here"
                          variant="outlined"
                          id="webhookUrl"
                          name="webhookUrl"
                          value={firstFormValues.webhookUrl || ''}
                          onChange={(e) => handleChange(e, errors)}
                          onBlur={handleBlur}
                          error={touched.webhookUrl && Boolean(errors.webhookUrl)}
                          helperText={errors.webhookUrl && touched.webhookUrl ? errors.webhookUrl : ''}
                          sx={userStyle.inputCustom}
                        />
                      </Grid>
                      
                      <Grid item xs={12} md={6}>
                        <p>
                          Webhook secret:{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </p>
                        <TextField
                          label="Type your secret here"
                          variant="outlined"
                          id="webhookSecret"
                          name="webhookSecret"
                          value={firstFormValues.webhookSecret || ''}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={touched.webhookSecret && Boolean(errors.webhookSecret)}
                          helperText={
                            errors.webhookSecret && touched.webhookSecret
                              ? errors.webhookSecret
                              : ''
                          }
                          sx={userStyle.inputCustom}
                        />
                      </Grid>
                    </Grid>
                  }

                  {isRockerbox()
                    ? <Grid
                        container
                        spacing={(isMobile) ? 0 : 5}
                        sx={{marginTop: '0px'}}
                      >
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            id="isAxiscare"
                            name="isAxiscare"
                            checked={isTrueOrFalse(firstFormValues.isAxiscare)}
                            disabled={false}
                            control={
                              <Checkbox
                                sx={{ margin: '0px 0px 0px 10px' }}
                                color="success"
                                onChange={(e) => markLikeAxiscare(e)}
                              />
                            }
                            label="Axiscare company"
                            labelPlacement="end"
                            sx={{
                              display: 'flex',
                              justifyContent: 'start',
                              gap: '10px'
                            }}
                          />
                        </Grid>
                      </Grid>
                    : null
                  }

                  {(isRockerbox() && isTrueOrFalse(firstFormValues.isAxiscare)) &&
                    <Grid container spacing={(isMobile) ? 0 : 5}>
                      <Grid item xs={12} md={6}>
                        <p>
                          Site:{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </p>
                        <TextField
                          label="Type your site here"
                          variant="outlined"
                          id="site"
                          name="site"
                          value={firstFormValues.site || ''}
                          onChange={(e) => handleChange(e, errors)}
                          onBlur={handleBlur}
                          error={touched.site && Boolean(errors.site)}
                          helperText={errors.site && touched.site ? errors.site : ''}
                          sx={userStyle.inputCustom}
                        />
                      </Grid>
                      
                      <Grid item xs={12} md={6}>
                        <p>
                          Referring agent:{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </p>
                        <TextField
                          label="Type your referring agent here"
                          variant="outlined"
                          id="agency"
                          name="agency"
                          value={firstFormValues.agency || ''}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={touched.agency && Boolean(errors.agency)}
                          helperText={
                            errors.agency && touched.agency
                              ? errors.agency
                              : ''
                          }
                          sx={userStyle.inputCustom}
                        />
                      </Grid>
                    </Grid>
                  }

                 {listGroups?.length
                    ? <Grid container spacing={(isMobile) ? 0 : 0}>
                        <FormControl component="fieldset" variant="standard" sx={{width: '100%'}}>
                          <p sx={{color: "#000"}}>Assign groups</p>
                          <Grid item xs={12} md={3} xl={3}>
                            <FormControlLabel
                              disabled={false}
                              checked={groupsEmpty ? false : selectAllLabel }
                              control={
                                <Checkbox
                                  sx={{ margin: '0px' }}
                                  color="success"
                                  onChange={(e) => handleCheckAllGroups(e)}
                                />
                              }
                              label={!selectAllLabel ? 'Select all' : 'Unselect all'}
                              labelPlacement="end"
                              sx={{display: 'flex', justifyContent: 'start', gap: '10px', marginBottom: '10px'}}
                            />
                          </Grid>
                          <FormGroup sx={{display: 'grid', gridTemplateColumns: isMobile ? '100%' : '33% 33% 33%'}}>
                            {listGroups?.map((item, index) => (
                              <Box
                                key={index}
                                
                              >
                                <FormControlLabel
                                  id={`group${index}`}
                                  name={`group${index}`}
                                  checked={isGroupChecked(item?.id)}
                                  disabled={false}
                                  control={
                                    <Checkbox
                                      sx={{ margin: '0px' }}
                                      color="success"
                                      onChange={(e) => handleChangeGroups(e)}
                                      value={item?.id}
                                    />
                                  }
                                  label={item?.title}
                                  labelPlacement="end"
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    gap: '10px'
                                  }}
                                />
                              </Box>
                            ))}
                          </FormGroup>
                          {groupsEmpty && <FormHelperText sx={{color: '#f44336'}}>Select at least one group</FormHelperText>}
                        </FormControl>
                      </Grid>
                    : null
                  }

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <Button
                        color="inherit"
                        onClick={() => {
                          history.goBack();
                        }}
                        sx={userStyle.formButtonWrapper}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        type="submit"
                        sx={userStyle.formButtonWrapper}
                        style={{ backgroundColor: 'black' }}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default FirstForm;
