import AppService from "./AppServiceNewApi";

class TaskService extends AppService {
  constructor() {
    super();
    this.path = "/api/internal/v1/tasks";
  }

  getTasks(pageSize = 20, page = 0, companyId) {
    const queryParams = new URLSearchParams();
    queryParams.append("PageSize", pageSize);
    queryParams.append("PageNumber", page);

    return this.http.get(`${this.path}/${companyId}?${queryParams.toString()}`);
  }

  getTask(id) {
    return this.http.get(`${this.path}/resource/${id}`);
  }
}

export default TaskService;
