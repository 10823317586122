const Navigation = [
  {
    id: 'Dashboard',
    title: 'Dashboard',
    type: 'item',
    url: 'dashboard'
  }
];

export default Navigation;
