import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/styles';
import App from './App';
import { UIProvider } from './app/context/ui';
import { theme } from './assets/theme';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <UIProvider>
          <App />
        </UIProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
