/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Button, Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useDispatch, useSelector } from 'react-redux';

// @assets
import { PayrollStyle } from '../../assets/css/payroll-style'

// @components
import PayrollTable from './PayrollTable/PayrollTable'
import PayrollFilter from './PayrollTable/PayrollFilter.js';

// @constants
import { types } from '../../redux/types/index.js';

// @helpers
import {
  callListPayroll,
  dataTestInvoice,
  dataTestMaxedOut,
  updateStateFilterPayroll,
} from '../../helpers/FunctionsUtils.js';
import AppHelper from '../../helpers/AppHelper.js';

// @service
import PayrollService from '../../services/newApi/PayrollService.js';

// @state
import { useUI } from '../../app/context/ui.js';

const PayrollView = () => {
  const style = PayrollStyle()
  const [payrollList, setPayrollList] = useState([])
  const state = useSelector(state => state)
  const currentTab = state?.tabPayroll.tab
  const pagination = state.pagination
  const filtersModal = state?.filterPayroll
  const getList = state?.getListPayroll?.canGetList
  const dispatch = useDispatch()
  const [openModalFilter, setOpenModalFilter] = useState(false)
  const payrollService = new PayrollService()
  const { blockUI, snackbarUI } = useUI();

  const breadcrumbs = [
    <Link key="1" color="inherit" sx={style.breadcrumbsText}>
      Dashboard
    </Link>,
    <Link
      key="2"
      color="inherit"
      sx={style.breadcrumbsText}
      //href="/material-ui/getting-started/installation/"
    >
      Reports
    </Link>,
    <Typography key="3" color="text.primary" sx={style.breadcrumbsTextSelected}>
      Payroll
    </Typography>,
  ];

  useEffect(() => {
    if(getList){
      getRecordsList()
      callListPayroll(dispatch, false)
    }
  }, [getList])
  

  const getRecordsList = (page, size, filters=filtersModal) => {
    setPayrollList([])
    const params = {
      page: page ? page : pagination?.page,
      size: size ? size : pagination?.size
    }
    if(currentTab === 1) getListForActiveTab(params, filters)
    if(currentTab === 2) getListForMaxedOut()
    if(currentTab === 3) getListForInvoiceSummary()
  }

  useEffect(() => {
    getRecordsList(1, 20, {})
    updateStateFilterPayroll(dispatch)
  }, [currentTab])

  const getListForActiveTab = async (params, filters) => {
    try {
      blockUI.current.open(true);
      const response = await payrollService.getEmployeesActiveTab(params, filters)
      updatePagination(response?.data?.pagination)
      setPayrollList(response?.data)
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const getListForMaxedOut = (params, filters) => {
    setPayrollList(dataTestMaxedOut)
    updatePagination(dataTestMaxedOut.pagination)
  }
  
  const getListForInvoiceSummary = (params, filters) => {
    setPayrollList(dataTestInvoice)
    updatePagination(dataTestInvoice.pagination)
  }

  const updatePagination = (pagination) => {
    dispatch({
      type: types.PAGINATION,
      page: pagination?.pageNumber ? pagination?.pageNumber : 1,
      size: pagination?.pageSize ? pagination?.pageSize : 20,
      totalRows: pagination?.totalRecords ? pagination?.totalRecords : 0
    })
  }

  const handleCloseOrOpenModalFilter = () => setOpenModalFilter((open) => !open)

  return (
    <Box style={style.containerPayroll}>
      <Box sx={style.breadcrumbs}>
        <Breadcrumbs
          separator={<NavigateNextIcon sx={style.separatorBreadcrumbs} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>

        {currentTab === 3
          ? null
          : <Button
              variant="contained"
              startIcon={<FilterAltIcon sx={style.iconBtnFilter}/>}
              sx={style.btnFilter}
              onClick={handleCloseOrOpenModalFilter}
            >
              Filters
            </Button>
        }
      </Box>
      <PayrollFilter
        handleClose={handleCloseOrOpenModalFilter}
        open={openModalFilter}
        getRecordsList={getRecordsList}
      />
        
      <Box>
        <PayrollTable data={payrollList} />
      </Box>
    </Box>
  )
}

export default PayrollView