// @vendors
import React, { useContext, useEffect, useState } from 'react';
import {
  includes as _includes,
  isEmpty as _isEmpty,
  last as _last,
} from 'lodash';

// @state
import { useUI } from '../../../app/context/ui';
import { QuestionnaireContext } from '../Prescreening';

// @assets
import { MultiQuestStyles, Styles } from '../../../assets/css/questionnaire-style';

// @material-ui
import {
  Box,
  Button,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
  makeStyles,
  IconButton,
  ArrowBackIcon,
  LinearProgress,
  Menu
} from '../../../components/shared/MaterialUI';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @services
import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';

const useStyles = makeStyles(theme => ({
  customWidth: {
    maxWidth: 500
  },
}));

const styleBoxProgress = {
  boxSizing: 'border-box',
  marginBottom: '15px'
}

const MatrixBox = () => {
  const {
    hashToken,
    settings,
    lang,
    t,
    initial,
    updateQuestion,
    employerId,
  } = useContext(QuestionnaireContext);
  const { blockUI, dialogUI, snackbarUI } = useUI();
  const [isMobile, setIsMobile] = useState(false)
  const styles = Styles();
  const classes = useStyles();
  const stylesProgress = MultiQuestStyles();
  const questionLang = (lang === 'es') ? initial?.spanishTitle : initial?.title;
  const subtitleLang = (lang === 'es') ? 'Seleccione todas las categorías enumeradas a continuación que se apliquen a usted.' : 'Please select all of the categories listed below that apply to you.';
  const [answerTemplate, setAnswerTemplate] = useState({
    questionId: initial?.id,
    type: initial?.type,
    answer: [],
    groupId: initial?.groupId,
  });
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [description, setDescription] = useState('')

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 980px)');
    const screenTest = (event) => {setIsMobile(event.matches)};
    mobileMediaQuery.addEventListener('change', screenTest);
    screenTest(mobileMediaQuery);
  }, [])

  const [open, setOpen] = useState([]);

  const groupNa = () => {
    const filtered = initial?.options?.find((x) => x.title === 'None of the above apply')
    if(filtered?.id){
      return _includes(answerTemplate.answer, filtered?.id);
    }
    return false
  }

  let disableToggle = groupNa()
  //let disableToggle = _includes(answerTemplate.answer, _last(initial?.options).id);
  const questionaireService = new QuestionnaireServiceNewApi()

  const handleChange = (e, newSelection) => {
    const naAnswer = initial?.options?.find((x) => x?.title === 'None of the above apply')
    if (_includes(newSelection, naAnswer?.id)) {
      newSelection = [_last(initial?.options).id];
      setAnswerTemplate((prev) => ({ ...prev, answer: newSelection }));
    }
    setAnswerTemplate((prev) => ({ ...prev, answer: newSelection }));
  };

  const submitResponses = async () => {
    if (_isEmpty(answerTemplate.answer))showMessageIfHasEmptyData()
    else submitData()
  };

  const showMessageIfHasEmptyData = () => {
    return dialogUI.current.open(
      'Oops!',
      'If none of the above apply to you, please select the N/A tile',
      settings
    );
  }

  const submitData = async () => {
    try {
      blockUI.current.open(true);
      const res = await questionaireService.questNext(answerTemplate, hashToken, lang);
      updateQuestion(res?.data?.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }  

  useEffect(() => {
    if (!_isEmpty(initial?.answer?.answer)) {
      setAnswerTemplate((prev) => ({ ...prev, answer: initial?.answer.answer }));
    }
  }, [initial]);

  const getPrev = async () => {
    try {
      blockUI.current.open(true);
      const resp = employerId
        ? await questionaireService.questPrev(hashToken, initial?.id, initial?.groupId, true)
        : await questionaireService.questPrev(hashToken, initial?.id, initial?.groupId)
      updateQuestion(resp?.data?.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const isDisabledTile = (opt) => {
    if(opt?.title === "SNAP recipient" ){
      if(initial?.dateOfBirth){
        const dift = new Date() - new Date(initial?.dateOfBirth)
        const diftYears = dift / (1000 * 60 * 60 *24 * 365.25)
        return Math.floor(diftYears) >= 18 && Math.floor(diftYears) < 40 ? false : true
      }
    }
  }

  const showDescriptionOnMobile = (e, description) => {
    setDescription(description)
    setAnchorEl(e.currentTarget)
    const filteredData = answerTemplate?.answer?.filter((item) => item !== e.target.value);
    setTimeout(() => {
      setAnswerTemplate((prev) => ({ ...prev, answer: filteredData }));
    }, 100);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setDescription('');
  };

  return (
    <>
      <Container maxWidth='xl' disableGutters component='section' sx={styles.container}>
        <Box sx={styles.titles}>
          <Typography variant='h3'>{questionLang}</Typography>
          <Typography variant='body1'>{initial.code === 'Q1' ? subtitleLang : questionLang}</Typography>
        </Box>
        {
          initial?.code === 'Q41'
          ? (<Box sx={stylesProgress.progress} style={styleBoxProgress} >
              <IconButton onClick={() => getPrev()}>
                <ArrowBackIcon />
              </IconButton>
              <LinearProgress variant='determinate' value={initial?.progress} />
            </Box>)
          : null
        }

        <ToggleButtonGroup
          size='medium'
          value={answerTemplate.answer}
          onChange={handleChange}
          sx={initial?.title === 'Before starting' ? styles.toggleGroup : styles.toggleGroupVocational}
        >
          {initial?.options &&
            React.Children.toArray(
              initial?.options?.map((option, index) => {
                return (
                  <ToggleButton
                    value={option?.id}
                    sx={initial?.title === 'Before starting' ? styles.toggle : styles.toggleVocational}
                    disabled={(option?.title !== 'None of the above apply' && disableToggle) || isDisabledTile(option)}
                    //disabled={(option.id !== _last(initial?.options).id && disableToggle) || isDisabledTile(option)}
                    onMouseEnter={() => setOpen(lastIndex => [...lastIndex, index])}
                    onMouseLeave={() => setOpen(lastIndex => lastIndex.filter(i => i !== index))}
                  >
                    {!isMobile
                      ? <Tooltip
                          open={open?.includes(index)}
                          classes={{ tooltip: classes.customWidth }}
                          title={
                            <Typography variant='h6'>{(lang === 'es') ? option?.spanishDescription : option?.description}</Typography>
                          }
                        >
                          <Box>
                            <img src={option?.icon} alt='' />
                            <Typography>{(lang === 'es') ? option?.spanishTitle : option?.title}</Typography>
                          </Box>
                        </Tooltip>
                      : <Box>
                          <img src={option?.icon} alt='' />
                          <Typography>{(lang === 'es') ? option?.spanishTitle : option?.title}</Typography>
                        </Box>
                    }

                    {isMobile && option?.description &&
                    <Button
                      onClick={(e) => showDescriptionOnMobile(e, (lang === 'es') ? option?.spanishDescription : option?.description)}
                      value={option.id}
                      disabled={(option?.title !== 'None of the above apply' && disableToggle) || isDisabledTile(option)}
                      sx={{
                        backgroundColor: '#81bc56',
                        color: '#FFF',
                        fontSize: 10,
                        boxShadow: 3,
                        '&.Mui-disabled': {
                          backgroundColor: '#fff',
                          boxShadow: 1,
                        },
                      }}
                    >
                      More details
                    </Button>
                  }
                  </ToggleButton>
                );
              })
            )}
        </ToggleButtonGroup>

        <Box sx={styles.buttonWrapper}>
          <Button variant='outlined' sx={styles.sendButton} onClick={() => submitResponses()}>
            {t("question.btnNext")}
          </Button>
        </Box>
      </Container>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Typography sx={{display: 'flex', padding: '20px'}}>{description}</Typography>
      </Menu>
    </>
  );
};

export default MatrixBox;