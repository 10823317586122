/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useState } from 'react'

// @material-ui
import {Checkbox, DataGrid, FormControlLabel} from "../../../components/shared/MaterialUI";

// @style
import { TableInvoiceStyle } from '../../../assets/css/payroll-style';
import CustomNoRowsOverlay from '../../../assets/CustomNoRowsOverlay';
import { componentsStyles } from '../../../assets/css/tableComponents-styles';
import Paginator from './PaginatorTable';
import ConfirmationDialog from '../../../components/dialog/DialogConfirm';

const InvoiceSumary = ({data}) => {
   const styles = TableInvoiceStyle()
   const [info, setInfo] = useState([])
   const [openDialog, setOpenDialog] = useState(false)

   const handleCloseModal = (value) => {
    console.log(value)
    setOpenDialog(false)
   }

   const handleChange = (e) => {
    if(e.target.checked)console.log(e.target.value)
    else setOpenDialog(true)
   }

  const columns = [
    {
        field: 'lastSyncDate',
        headerName:'Last Sync Date',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        flex: 1
    },
    {
        field: 'employeer',
        headerName: 'Employeer',
        disableColumnMenu: true,
        flex: 1
    },
    {
        field: 'invoiceYtd',
        headerName: 'Invoice YTD ($)',
        disableColumnMenu: true,
        flex: 1
    },
    {
        field: 'actualInvoice',
        headerName: 'Actual Invoice ($)',
        disableColumnMenu: true,
        flex: 1
    },
    {
        field: 'invoiceSent',
        headerName: 'Invoice Sent',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params) => (
            <FormControlLabel
                value="Yes"
                checked={params?.row?.invoiceSent}
                control={<Checkbox 
                    onChange={(e) => handleChange(e)}
                    sx={{
                        color: '#BAC7D5',
                        '& .MuiSvgIcon-root': {
                            fontSize: '24px',
                        },
                    }}
                />}
                label="Yes"
                labelPlacement="end"
            />
          ),
    },
  ];
  
  useEffect(() => {
    setInfo(data?.info ?? [])
  }, [data])
  
  return (
    <>
        <DataGrid
            columns={columns}
            rows={info}
            componentsProps={componentsStyles}
            components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                Pagination: Paginator
            }}
            disableSelectionOnClick
            checkboxSelection={false}
            sx={styles.tableGrid}
            pagination
        />

        <ConfirmationDialog
            id="confirm-dialog"
            onClose={handleCloseModal}
            open={openDialog}
            view="payroll"
            message="Are you sure you want to Uncheck?"
        />
    </>
  )
}

export default InvoiceSumary
