export const LISTROLES = {
    superAdmin: 'super_admin',
    admin: 'admin',
    orgManager: 'org_manager',
    orgReporter: 'org_reporter',
    orgReader:  'org_reader',
    orgProcessor: 'org_processor',
    employerManager:  'company_manager',
    employerReporter:  'company_reporter',
    employerReader:  'company_reader',
}