// @vendors
import React, {useContext, useState} from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

// @assets
import { toolbarStyles } from '../../../../assets/css/customToolbar-styles';
import { EmployeeTableStyles } from '../../../../assets/css/employeeTable-style';
import { permissionsForAddEmployer, permissionsForEditEmployer } from '../../../../helpers/FunctionsUtils';

// @components material
import {
  Button,
  ClearAllIcon,
  FileUploadRoundedIcon,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  FileDownloadOutlinedIcon,
} from '../../../../components/shared/MaterialUI';

// @components
import { DataTable } from '../../Admin';
import { AddNewEmployer } from '../AddNewEmployer';

// @helpers
import { useSelector } from 'react-redux';
import MenuFileCompany from './MenuFileCompany';

export const CustomToolbar = () => {
  const {
    getAllCompanies,
    search,
    setSearch,
  } = useContext(DataTable);
  const styles = toolbarStyles();
  const stylesDialog = EmployeeTableStyles();
  const user = useSelector((state) => state?.user);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const openMenu = Boolean(anchorElMenu);
  const [isUpload, setIsUpload] = useState(false);

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClickMenu = (event, upload) => {
    setIsUpload(upload);
    setAnchorElMenu(event.currentTarget);
  };
  
  const onSearchBarChange = (e) => {
    setSearch((current) => ({
      ...current,
      name: e.target.value
    }));
  };

  const clearSearch = () => {
    setSearch((current) => ({
      ...current,
      name: ''
    }));
    getAllCompanies({filters: {}});
  };

  const handleSearchBar = async (query) => {
    if(query){
      getAllCompanies();
    }
  };

  const actionToSelect = () => {
    if(search?.name === '')return clearSearch();
    if(search?.name?.length > 0)return handleSearchBar(search?.name);
    return
  }

  return (
    <GridToolbarContainer sx={styles.toolbar}>
      <h2>Employer list</h2>
      <Grid sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
        <TextField
          sx={styles.searchBox}
          value={search?.name}
          id='searchCompany'
          placeholder='Search'
          onChange={(e) => onSearchBarChange(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if(search?.name === '') return clearSearch()
              return handleSearchBar(search?.name);
            }
          }}
          variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  onClick={actionToSelect}
                >
                  <SearchRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title="Clear filter">
          <IconButton
            onClick={() => clearSearch()}
            sx={{width: '40px', height: '40px'}}
          >
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <GridToolbarFilterButton sx={styles.filterButton} />

      {permissionsForAddEmployer(user?.role) && <AddNewEmployer />}

      {permissionsForEditEmployer(user?.role) &&
        <>
          <Grid>
            <Button
              sx={{...stylesDialog.csvButton, ...stylesDialog.btnStyleSm}}
              endIcon={<FileDownloadOutlinedIcon />}
              onClick={(e) => handleClickMenu(e, false)}
            >
              Template
            </Button>
          </Grid>

          <Grid>
            <Button
              variant="contained"
              component="span"
              onClick={(e) => handleClickMenu(e, true)}
              sx={{...stylesDialog.csvButton, ...stylesDialog.btnStyleSm}}
            >
              Upload csv
              <FileUploadRoundedIcon />
            </Button>
          </Grid>
        </>
      }
      <MenuFileCompany
        anchorElMenu={anchorElMenu}
        openMenu={openMenu}
        handleCloseMenu={handleCloseMenu}
        isUpload={isUpload}
      />
    </GridToolbarContainer>
  );
};
