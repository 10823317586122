import { theme } from "../theme";

export const StatsStyles = () => ({
  container: {
    display: 'flex',
    gap: '30px',
    margin: '64px 0',
    width: '100% !important',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '12px 12px',
      margin: '32px 0',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      display: 'grid',
      gridTemplateColumns: 'repeat(1, 1fr)',
      gap: '30px 0',
      margin: '32px 0',
    },
  },
  box: {
    height: '210px',
    padding: '0 24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: '#FFF',
    boxSizing: 'border-box',
    color: '#101010',
    width: '100%',
    '& .MuiCardContent-root': {
      textAlign: 'center',
      padding: '0',
    },
    [theme.breakpoints.down('md')]: {
      height: '120px',
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '120px',
      padding: '0 16px',
    },
  },
  statsTitle: {
    fontSize: '1.25rem',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  statsData: {
    fontSize: '2rem',
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
  },
  icon: {
    fontSize: '4rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  buttonSearch: {
    width: '100px',
    height: '30px',
    border: '1px solid #101010',
    bgcolor: '#FFF',
    color: '#101010',
    fontSize: '0.75rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: "#FFF",
      boxShadow: 3,
    },
    [theme.breakpoints.down('md')]: {
      width: '100px',
      height: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80px',
      fontSize: '0.75rem',
      height: '25px',
    },
  },
  gridCard:{
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'center',
  },
  gridCardText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
