/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useState } from 'react'
import {
    Checkbox,
    FormControl,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TableCell,
} from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// @assets
import { DialogFilterPayroll, EditPayrollStyle } from '../../../assets/css/payroll-style'

// @helpers
import {
    MenuItemProps,
    formattDate,
    stateCeretificationList,
    targetGroupCode,
    validateEmptyString,
} from '../../../helpers/FunctionsUtils'

// @services
import PayrollService from '../../../services/newApi/PayrollService'

// @constants
import { types } from '../../../redux/types'

const FormEditDetail = ({data}) => {
    const style = EditPayrollStyle()
    const payrollService = new PayrollService()
    const styleFilter = DialogFilterPayroll()
    const dispatch = useDispatch()
    const stateEdit = useSelector((state) => state?.editEmployeePayroll)
    const initialValuesForm = {
        stateCert: validateEmptyString(data?.stateCertification),
        stateStatus: data?.stateStatusId,
        targetGroup: [],
        targetGroupCode: data?.targetGroupCode,
        paySchedule: data?.payScheduleId,
        ytdGross: data?.currentYTDGrossWages
    }
    const [initialValues, setInitialValues] = useState(initialValuesForm)
    const [groupsSelected, setGroupsSelected] = useState([])
    const [groupCodesSelected, setGroupCodesSelected] = useState([])
    const [targetGroups, setTargetGroups] = useState([])
    const [targetGroupsCode, setTargetGroupsCode] = useState()
    const [stateStatus, setStateStatus] = useState([])
    const [paySchedule, setPaySchedule] = useState([])

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            //handleSubmitEmployee(values)
        },
        validationSchema: {}
    })

    const handleChangeValues = (e) => {
        setInitialValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
        updateInfoEmployee(e.target)
    }

    const updateInfoEmployee = (field) => {
        dispatch({
            type: types.EDIT_EMPLOYEE_PAYROLL,
            stateCertification: field.name === 'stateCert' ? field.value : stateEdit?.stateCertification,
            stateStatus: field.name === 'stateStatus' ? field.value : stateEdit?.stateStatus,
            paySchedule: field.name === 'paySchedule' ? field.value : stateEdit?.paySchedule,
        })
    }

    const handleChangeGroups = (event) => {
        const value = event.target.value
        setGroupsSelected(typeof value === 'string' ? value.split(',') : value,)
    }
    
    const handleChangeGroupCode = (event) => {
        const value = event.target.value
        setGroupCodesSelected(typeof value === 'string' ? value.split(',') : value,)
    }

    useEffect(() => {
        getListStatus()
        getListPaySchedule()
        setTargetGroupsCode(targetGroupCode)
    }, [])

    const getListStatus = async () => {
        const response = await payrollService.getStateStatus()
        setStateStatus(response?.data?.data)
    }
    
    const getListPaySchedule = async () => {
        const response = await payrollService.getPaySchedule()
        setPaySchedule(response?.data?.data)
    }

    const getTargetGroups = async () => {
        const response = await payrollService.targetGroups()
        setTargetGroups(response?.data?.data)
        if(response?.data?.data && data?.targetGroup)targetGroupSelected(response?.data?.data)
    }

    const targetGroupSelected = (resp) => {
        const list = data?.targetGroup?.split(',')
        const listMap = list?.map((item) => {
            const group = resp?.find((x) => x?.targetGroup === item)
            return group?.targetGroup
        })
        const listGroups = data?.targetGroup?.includes('Veteran') ? listMap.concat('Veteran') : listMap
        const filterList = listGroups.filter((x) => x !== undefined)
        setGroupsSelected([...filterList])
    }

    useEffect(() => {
        getTargetGroups()
        return() =>{
            //if(data?.targetGroup)targetGroupSelected()
            const groupCode = data?.targetGroupCode ? data?.targetGroupCode?.split(',') : []
            setGroupCodesSelected((prev) => prev.concat(groupCode))
        }
    }, [])

  return (
    <>
        <TableCell sx={style.tableCellRow} >{formattDate(data?.lastSyncDate)}</TableCell>

        <TableCell sx={style.tableCellRow} >
            <FormControl fullWidth>
                <Select
                    labelId="stateCert"
                    id="stateCert"
                    value={initialValues.stateCert}
                    onChange={handleChangeValues}
                    name='stateCert'
                    sx={style.fieldsInput}
                    size='small'
                    error={formik.touched.stateCert && Boolean(formik.errors.stateCert)}
                    helperText={formik.touched.stateCert && formik.errors.stateCert}
                >
                    {stateCeretificationList?.map((state) => (
                        <MenuItem
                            key={state}
                            value={state}
                        >
                            {state}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>

        <TableCell sx={style.tableCellRow} >
            <FormControl fullWidth>
                <Select
                    labelId="stateStatus"
                    id="stateStatus"
                    value={initialValues.stateStatus}
                    //onBlur={handleChangeValues}
                    onChange={handleChangeValues}
                    name='stateStatus'
                    sx={style.fieldsInput}
                    size='small'
                    error={formik.touched.stateStatus && Boolean(formik.errors.stateStatus)}
                    helperText={formik.touched.stateStatus && formik.errors.stateStatus}
                >
                    {stateStatus?.map((state) => (
                        <MenuItem
                            key={state?.id}
                            value={state?.id}
                        >
                            {state?.stateStatus}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>

        <TableCell sx={style.tableCellRow} >{data?.employeeFirstName}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.employeeLastName}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.employeer}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.employeerRate}</TableCell>

        <TableCell sx={style.tableCellRow} >
            <FormControl>
                <Select
                    multiple
                    size="small"
                    name='targetGroup'
                    value={groupsSelected}
                    onChange={handleChangeGroups}
                    sx={style.fieldsInput}
                    renderValue={(selected) => {
                        if (selected.length === 0) return <em>Select</em>;
                        return selected?.length > 1
                          ? (<div>{selected[0]}, <spam style={styleFilter.otherValuesForGroups} >{`+ ${selected?.length -1} More`}</spam></div>)
                          : selected?.join(', ')
                    }}
                    MenuProps={MenuItemProps}
                    margin="dense" 
                >
                    {targetGroups?.map((group, index) => (
                    <MenuItem
                        key={group?.id}
                        value={group?.targetGroup}
                        sx={styleFilter.menuItem}
                    >
                        <Checkbox
                        checked={groupsSelected.indexOf(group?.targetGroup) > -1}
                        sx={style.checkBox}
                        color="primary"
                        />
                        <ListItemText sx={styleFilter.textSelect} primary={group?.targetGroup} />
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>

        <TableCell sx={style.tableCellRow} >
            <FormControl>
                <Select
                    multiple
                    size="small"
                    name='targetGroupCode'
                    value={groupCodesSelected}
                    onChange={handleChangeGroupCode}
                    sx={style.fieldsInput}
                    renderValue={(selected) => {
                    if (selected.length === 0) return <em>Select</em>;
                    return selected?.length > 1
                        ? (<div>{selected[0]}, <spam style={styleFilter.otherValuesForGroups} >{`+ ${selected?.length -1} More`}</spam></div>)
                        : selected?.join(', ')
                    }}
                    MenuProps={MenuItemProps}
                    margin="dense" 
                >
                    {targetGroupsCode?.map((code, index) => (
                    <MenuItem
                        key={code?.id}
                        value={code?.name}
                        sx={styleFilter.menuItem}
                    >
                        <Checkbox
                        checked={groupCodesSelected.indexOf(code?.name) > -1}
                        sx={styleFilter.checkBox}
                        color="primary"
                        />
                        <ListItemText sx={styleFilter.textSelect} primary={code?.name} />
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>

        <TableCell sx={style.tableCellRow} >{formattDate(data?.hireDate)}</TableCell>

        <TableCell sx={style.tableCellRow} >
            <FormControl fullWidth>
                <Select
                    labelId="paySchedule"
                    id="paySchedule"
                    value={initialValues.paySchedule}
                    //onBlur={(e) => handleChangeValues(e)}
                    onChange={handleChangeValues}
                    name='paySchedule'
                    sx={style.fieldsInput}
                    size='small'
                    error={formik.touched.paySchedule && Boolean(formik.errors.paySchedule)}
                    helperText={formik.touched.paySchedule && formik.errors.paySchedule}
                >
                    {paySchedule?.map((item) => (
                        <MenuItem
                            key={item?.id}
                            value={item?.id}
                        >
                            {item?.paySchedule}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>

        <TableCell sx={style.tableCellRow} >
            <FormControl fullWidth>
                <OutlinedInput
                    id="ytdGross" 
                    variant="outlined"
                    name='ytdGross'
                    defaultValue=''
                    value={initialValues.ytdGross}
                    size="small"
                    sx={style.fieldsInput}
                    onChange={handleChangeValues}
                    error={formik.touched.ytdGross && Boolean(formik.errors.ytdGross)}
                    helperText={formik.touched.ytdGross && formik.errors.ytdGross}
                />
            </FormControl>
        </TableCell>

        <TableCell sx={style.tableCellRow} >{data?.currentYTDHours}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.wotcCreditMultiplier}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.maxWage}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.maxTaxCredit}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.taxCreditYTD}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.invoiceYTD}</TableCell>

        <TableCell sx={style.tableCellRow} >{data?.actualInvoice}</TableCell>   
    </>
  )
}

export default FormEditDetail