// @vendors
import React, { useContext, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @assets
import { EmployerStyles } from '../../../../assets/css/employer-style';

// @context
import { EmployeeContext } from '../../Employer';

// @constants
import { ROUTENAME } from '../../../../navigation/RouteName';

// @components
import MenuAddBranch from './MenuAddBranch';

// @helpers
import { permissionsForEditEmployer } from '../../../../helpers/FunctionsUtils';

const ToolbarChild = () => {
    const styles = EmployerStyles();
    const history = useHistory();
    const {
        setShowChildrens,
        getAllEmployesByCompany,
        getAllChildrens,
        employerDetails,
        setRowsState
    } = useContext(EmployeeContext);
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const openMenu = Boolean(anchorElMenu);
    const user = useSelector((loadedState) => loadedState.user);

    const handleClickMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
      };
      const handleCloseMenu = () => {
        setAnchorElMenu(null);
      };

    const showEmployeesByCompany = () => {
        setRowsState((prev) => ({
            ...prev,
            pageSize: 10,
            rows: [],
            rowCount: 0,
            page: 0,
        }));
        setShowChildrens(false);
        getAllEmployesByCompany({filters: {}});
    }

  return (
    <Grid sx={styles.toolbarChilds}>
        <h2 style={{textAlign: 'start', fontSize: '1.5rem'}} >
            Children list
        </h2>

        {permissionsForEditEmployer(user?.role) &&
            <Button
                variant="contained"
                sx={styles.btnShowEmployees}
                onClick={handleClickMenu}
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
            >
                Add new branch
            </Button>
        }

        {employerDetails?.data?.mainParentCompanyId
            ?   <Button
                    variant='contained'
                    sx={styles.btnShowEmployees}
                    onClick={() => {
                        history.push(`${ROUTENAME.employer}${employerDetails?.data?.mainParentCompanyId}`)
                        getAllChildrens(employerDetails.data.mainParentCompanyId)
                    }}
                >
                    back to main parent
                </Button>
            :   null
        }
        
        {employerDetails?.data?.parentCompanyId
            ?   <Button
                    variant='contained'
                    sx={styles.btnShowEmployees}
                    onClick={() => {
                        history.push(`${ROUTENAME.employer}${employerDetails?.data?.parentCompanyId}`)
                        getAllChildrens(employerDetails.data.parentCompanyId)
                    }}
                >
                    back to parent
                </Button>
            :   null
        }

        <Button
            variant='contained'
            sx={styles.btnShowEmployees}
            onClick={() => showEmployeesByCompany()}
        >
            show employees
        </Button>

        <MenuAddBranch 
            anchorElMenu={anchorElMenu}
            openMenu={openMenu}
            handleCloseMenu={handleCloseMenu}
        />
    </Grid>
  )
}

export default ToolbarChild