import { types } from "../types";

const initialState = {
    canGetList: null
}

const getListPayroll = (state=initialState, action) => {
    switch (action.type) {
        case types.CALL_PAYROLL_LIST:
            
            return {
                ...state,
                canGetList:action.payload
            }
    
        default:
            return state
    }
}
export default getListPayroll