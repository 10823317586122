import 'date-fns';
import React, { useState } from 'react';
import { useField } from 'formik';
import {AdapterDateFns, LocalizationProvider, TextField} from "../shared/MaterialUI";
import {Grid} from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers';

export default function AppDatePicker(props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(value);

  function _onChange(date) {
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
        setValue(ISODateString);
      } catch (error) {
        setValue(date);
      }
    } else {
      setValue(date);
    }
  }

  return (
    <Grid container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          value={selectedDate}
          onChange={_onChange}
          error={isError}
          invalidDateMessage={isError && error}
          helperText={isError && error}
          allowSameDateSelection={true}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={isError}
                helperText={isError && error}
              />
            );
          }}
        />
      </LocalizationProvider>
    </Grid>
  );
}
