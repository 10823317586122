/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @state
import { useUI } from "../../app/context/ui";

// @assets
import { EmployerStyles } from "../../assets/css/employer-style";

// @components
import {
  Box,
  Button,
  Grid,
  Typography,
} from "../../components/shared/MaterialUI";
import AdminView from "./components/AdminView";
import EmployeeTable from "./components/EmployeeTable/EmployeeTable";
import EmployerStats from "./components/EmployerStats";

// @helperts
import AppHelper from "../../helpers/AppHelper";
import { permissionsForEditEmployer } from "../../helpers/FunctionsUtils";

// @services
import CompanyServiceNewApi from "../../services/newApi/CompanyServiceNewApi";
import EmployeeCompanyServiceNewApi from "../../services/newApi/EmployeeCompanyServiceNewApi";

// @constants
import { types } from "../../redux/types";
import { ROUTENAME } from "../../navigation/RouteName";
import ChildTable from "./components/childs/ChildTable";

const Employer = (props) => {
  const state = useSelector((loadedState) => loadedState);
  const user = state.user;
  const sort = state.sortEmployeeReducer
  const id = props.id ? props.id : user?.companies[0]?.id;
  const [employerDetails, setEmployerDetails] = useState({});
  const { blockUI, snackbarUI } = useUI();
  const styles = EmployerStyles();
  const comapnyServiceNewApi = new CompanyServiceNewApi()
  const employeeByCompanyService = new EmployeeCompanyServiceNewApi()
  const history = useHistory();
  const dispatch = useDispatch()
  const pageEmployer = 0;
  const organizationId = state?.organizationReducer?.organizationSelected?.id;
  const listOrganization = state?.organizationReducer?.data;
  const [showChildrens, setShowChildrens] = useState(false);
  const [childrenWasConsulted, setchildrenWasConsulted] = useState(false);
  const [listChildrens, setListChildrens] = useState({
    rows: [],
    page: 0,
  });
  const [childReachLimit, setChildReachLimit] = useState(false)
  
  const [rowsState, setRowsState] = useState({
    pageSize: 20,
    rows: [],
    rowCount: 0,
    page: pageEmployer,
  });

  const [pages, setPages] = useState({
    page:0,
    size:10
  });

  const [search, setSearch] = useState({
    name: '',
    status: '',
  });

  const getEmployerDetails = async (id) => {
    try {
      const response = await comapnyServiceNewApi.getCompanyById(id);
      const data = response?.data?.data;
      if(data?.organizationId)preloadOrganization(data?.organizationId);
      setEmployerDetails({ name: data.name, status: data.status, data: data, displayName: data?.displayName });
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const preloadOrganization = (organization) => {
    const org = listOrganization?.find((x) => x?.id === organization);
    if(org?.id !== organizationId){
      dispatch({
        type: types.ORGANIZATIONS_LIST,
        data: listOrganization,
        organizationSelected: org
      });
    }
  }

  useEffect(() => {
    if (!id) {
      getEmployerDetails(user?.companies[0]?.id);
    } else {
      getEmployerDetails(id);
    }
  }, [id]);

  const getAllChildrens = async (companyId) => {
    try {
      blockUI.current.open(true);
      const response = await comapnyServiceNewApi.getChildsByCompany(companyId);
      const data = response?.data?.data;
      const isLimit = response?.data?.child_reach_limit === null ? false : response?.data?.child_reach_limit; 
      setChildReachLimit(isLimit);
      checkIfHasChilds(data, companyId);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const checkIfHasChilds = (data, companyId) => {
    setchildrenWasConsulted(true)
    if(!data?.length)return callEmployeesList(companyId)
    setShowChildrens(true);
    setListChildrens((prev) => ({
      ...prev,
      rows: data,
      page: 0,
    }));
    blockUI.current.open(false);
    return;
  }

  const callEmployeesList = (companyId) => {
    setShowChildrens(false);
    const params = {companyId: companyId, filters: {}}
    getAllEmployesByCompany(params);
    setListChildrens((prev) => ({
      ...prev,
      rows: [],
      page: 0,
    }));
  }

  const getAllEmployesByCompany = async (params) => {
    const employer = params?.companyId ?? id;
    const paginattion = {page: params?.page ?? 1, size: params?.size ?? pages.size};
    const filters = params?.filters ?? search;
    const sortBy = {order: params?.sort ?? sort.sort, direction: params?.direction ?? sort.direction};
    try {
      blockUI.current.open(true);
      const response = await employeeByCompanyService.getEmployeesByCompany(employer, organizationId, paginattion, filters, sortBy);
      const data = response?.data
      updateListData(data)
    } catch (e) {
      blockUI.current?.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const updateListData = (data) => {
    setPages((prev) => ({
      ...prev,
      page: data?.pagination?.page_number,
      size: data?.pagination?.page_size
      ,
    }));
    setRowsState((prev) => ({
      ...prev,
      pageSize: rowsState.pageSize || 20,
      rows: data.data,
      rowCount: data?.pagination?.total_records,
      page: data?.pagination.page_number - 1,
    }));
    setTimeout(() => {
      blockUI.current.open(false);
    }, 1000);
  }

  useEffect(() => {
    dispatch({
      type: types.ID_EMPLOYEE_SELECTED,
      detail: ''
    })
    dispatch({
      type: types.SORT_EMPLOYEE,
      sort: '',
      direction: ''
    });
    getAllChildrens(id);
  }, [organizationId])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = `RockerBox - ${employerDetails.name || ""}`;
  }, [employerDetails]);

  const adminProps = {
    id,
    name: employerDetails.name,
    displayName: employerDetails.displayName,
    status: employerDetails.status,
    setEmployerDetails,
    employerDetails
  };

  return (
    <>
      <Grid
        component="section"
        disableGutters
        sx={styles.dashCtn}
      >
        {pageEmployer !== 0 && (
          <Button
            onClick={() =>
              history.push({
                pathname: ROUTENAME.dashboard,
                state: { page: pageEmployer },
              })
            }
            sx={styles.buttonLinkGoBack}
          >
            Return to dashboard
          </Button>
        )}

        <Box sx={styles.upperCtn}>
          {permissionsForEditEmployer(user?.role) ? (
            <AdminView {...adminProps} />
          ) : (
            <>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    margin: "0 0 20px",
                  }}
                >
                  {employerDetails.displayName ? employerDetails.displayName : employerDetails.name}
                </Typography>
                <Box sx={styles.statusBox}>{employerDetails.status}</Box>
              </Box>
              <Box sx={styles.buttonsBox}></Box>
            </>
          )}
        </Box>

        <EmployerStats {...adminProps} />

        {childrenWasConsulted &&
          <EmployeeContext.Provider
            value={{
              rowsState,
              pages,
              search,
              companyId: id || "",
              setRowsState,
              setPages,
              setSearch,
              getAllEmployesByCompany,
              setShowChildrens,
              listChildrens,
              setListChildrens,
              getAllChildrens,
              childReachLimit,
              employerDetails
            }}
          >
            {listChildrens?.rows?.length && showChildrens
              ? <ChildTable />
              : <EmployeeTable />
            }
          </EmployeeContext.Provider>
        }

      </Grid>
    </>
  );
};

export const EmployeeContext = createContext({});
export default Employer;
