// @vendors
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// @constants
import { ROUTENAME } from '../navigation/RouteName';

const NotFound = () => {
    const history = useHistory();

    useEffect(() => {
      history.push(ROUTENAME.dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

  return (
    <></>
  )
}

export default NotFound