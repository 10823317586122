import React from 'react';
import { Typography, Box } from '../../../../../components/shared/MaterialUI.js';

const ArrayAnswerResult = ({ question, pos }) => {
	
  const key = question.title.replace(/\s/g, "");
  
  const renderMultiQuestion = (data) => {
    return (
      <li key={`${key}_${data.title}`}>
        <Typography variant='h6'>{data.title}</Typography>
        {Array.isArray(data.answer) ? (
          <ul>
            {data.answer.map((answer) => (
              <li key={`${key}_${data.title}_${answer.title}`}>
                <Typography variant='h6'>{answer.title}</Typography>
              </li>
            ))}
          </ul>
        ) : (
          <Typography variant='h6'>
            {data.questionId === 11 ? '*********' : data.answer}
          </Typography>
        )}
      </li>
    );
  };

  const renderSingleQuestion = (data, i) => (
    <li key={i}>{data.title}&nbsp; <br /> {data.answer}</li>
  );

  return (
    <Box>
      <Typography variant='h6'>
        {pos}. {question.title}
      </Typography>
      <Typography variant='h6' style={{ display: 'flex' }}>
        <ul>
          {question?.answerList?.map((data, i) =>
            question?.type === 'multi_question'
              ? renderMultiQuestion(data)
              : renderSingleQuestion(data, i)
          )}
        </ul>
      </Typography>
    </Box>
  );
};

export default ArrayAnswerResult;
