import { types } from '../types';

const initialState = {detail: ''};

function employeeSelected(state = initialState, payload) {
  switch (payload.type) {
    case types.ID_EMPLOYEE_SELECTED:
      return {
        ...state,
        detail: payload.detail
      };
    default:
      return state;
  }
}

export default employeeSelected;