import React, { createContext, createRef, useMemo } from 'react';

import PropTypes from 'prop-types';

import BlockUI from '../../components/shared/BlockUI';
import DialogUI from '../../components/shared/DialogUI';
import SnackbarUI from '../../components/shared/SnackbarUI';
import ProgressUI from '../../components/shared/ProgressUI';

const UIContext = createContext({});

const snackbarUI = createRef();
const blockUI = createRef();
const dialogUI = createRef();
const progressUI = createRef();

const UIProvider = (props) => {
  const rootUI = useMemo(() => {
    return {
      blockUI,
      snackbarUI,
      dialogUI,
      progressUI,
    };
  }, []);

  return (
    <UIContext.Provider value={rootUI}>
      {props.children}
      <SnackbarUI ref={snackbarUI} />
      <BlockUI ref={blockUI} />
      <DialogUI ref={dialogUI} />
      <ProgressUI ref={progressUI} />
    </UIContext.Provider>
  );
};

const useUI = () => {
  return React.useContext(UIContext);
};

UIProvider.propTypes = {
  children: PropTypes.node,
};

export { UIProvider, useUI };
