import AppServiceNewApi from "./AppServiceNewApi";

class PayrollService extends AppServiceNewApi{
    constructor(){
        super();
        //this.path = 'https://backend-dev-k8s.rockerbox.tech/payroll/'
        this.path = '/payroll/'
    }

    getEmployeesActiveTab(pagination, filters){
        let filterString = '&'
        if(filters?.Employeer)filterString = `${filterString}Employeer=${filters?.Employeer}&`
        if(filters?.HireDateFrom)filterString = `${filterString}HireDateFrom=${filters?.HireDateFrom}&`
        if(filters?.HireDateTo)filterString = `${filterString}HireDateTo=${filters?.HireDateTo}&`
        if(filters?.EmployeeName)filterString = `${filterString}EmployeeName=${filters?.EmployeeName}&`
        if(filters?.StateStatus)filterString = `${filterString}StateStatus=${filters?.StateStatus}&`
        if(filters?.StateCertification || filters?.StateCertification === false)filterString = `${filterString}StateCertification=${filters?.StateCertification}&`
        if(filters?.targetGroup?.length)filterString = `${filterString}TargetGroup=${filters?.targetGroup}&`
        if(filterString === '&')filterString = ''
        else filterString = filterString.substring(0, filterString?.length - 1)
        const URL = `${this.path}payroll/employees?PageNumber=${pagination.page}&PageSize=${pagination.size}${filterString}`
        return this.http.get(URL)
    }

    getStateStatus(){
        return this.http.get(`${this.path}payroll/state-status`)
    }
    
    getPaySchedule(){
        return this.http.get(`${this.path}payroll/pay-schedules`)
    }

    updateEmploye(id, body){
        return this.http.put(`${this.path}payroll/${id}`, body)
    }

    targetGroups(){
        return this.http.get(`${this.path}TargetGroup/groups`)
    }
}
export default PayrollService