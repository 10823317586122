import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { cloneDeep as _cloneDeep } from 'lodash';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
	LinearProgress,
} from './MaterialUI.js';

const template = {
    title: 'Wait a moment ...',
    content: '',
		color: 'primary',
};

const ProgressUI = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState(template);

  const onClose = (reason) => {
		if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setOpen(false);
  };
  useImperativeHandle(ref, () => ({
    open: (title, content, color, settings = null) => {
      let defaultSettings = _cloneDeep(template);
      defaultSettings.title = title;
      defaultSettings.content = content;
			defaultSettings.color = color;
      setSettings(defaultSettings);
      setOpen(true);
    },
    close: () => setOpen(false),
  }));

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={(_, reason) => onClose(reason)}
				sx={{
					'& .MuiDialog-paper': {
						paddingX: '100px',
						paddingY: '64px',
						display: 'flex',
						gap: '24px',
					},
				}}		
      >
        <>
          <DialogTitle 
            sx={{
              fontSize: '2.4rem',
              fontWeight: 'bold',
            }}
          >
            {settings.title}
          </DialogTitle>
          <DialogContent 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
              height: '240px',
            }}
          >
            <DialogContentText>
              {settings.content}
            </DialogContentText>
            <LinearProgress 
              sx={{
                height: '12px',
                width: '100%',
                borderRadius: '100px',
                backgroundColor: '#f5f5f5',
              }}
              color={settings.color}
            />
          </DialogContent>
        </>	
      </Dialog>
    </>
  );
});

export default ProgressUI;
