import { types } from "../types";

const initialState = {
    EmployeeName: null,
    Employeer: null,
    targetGroup: null,
    HireDateFrom:null,
    HireDateTo:null,
    StateStatus: null,
    StateCertification:null
}

const filterPayroll = (state= initialState, action)=> {
  switch (action.type) {
    case types.FILTER_PAYROLL:
      return {
        ...state,
        EmployeeName: action.EmployeeName,
        Employeer: action.Employeer,
        targetGroup: action.targetGroup,
        HireDateFrom: action.HireDateFrom,
        HireDateTo: action.HireDateTo,
        StateStatus: action.StateStatus,
        StateCertification: action.StateCertification
    };

    default:
      return state;
  }
}
export default filterPayroll