// @vendors
import React from 'react';

// @assets
import { DashboardStyles } from '../../../assets/css/dashboard-style';

// @components material
import {
  AddIcon,
  Button,
  Link,
} from '../../../components/shared/MaterialUI';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

export const AddNewEmployer = (props) => {
  const styles = DashboardStyles();

  return (
    <Link href={ROUTENAME.createEmployer} sx={{ textDecoration: 'none' }}>
      <Button
        variant='contained'
        color='primary'
        size='large'
        sx={styles.button}
        endIcon={<AddIcon />}
      >
        Add New Employer
      </Button>
    </Link>
  );
};
