import { theme } from "../theme";

export const DigitalSignatureStyles = () => ({
  checkboxBox: {
    margin: '0 0 40px 0',
    position: 'relative',
    '& p': {
      fontSize: '1.25rem',
      overflowWrap: 'break-word',
      // textAlign: 'left',
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'center',
      position: 'absolute',
      width: '100%',
      bottom: '-32px',
    },
  },
  checkboxCtn: {
    margin: '32px 0 0',
    display: 'flex',
    flexDirection: 'row',
    padding: { xs: '0 16px', md: '0' },
    justifyContent: { xs: 'flex-start', md: 'space-evenly' },
    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontSize: '1.25rem',
      },
    },
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  },
  inputBox: {
    '& .inputTitle': {
      margin: '0 0 32px 0',
    },
    '& p': {
      fontSize: '1.25rem',
    },
    '& .MuiFormHelperText-root': {
      margin: '0',
      padding: '0 8px',
      fontSize: '0.875rem',
      backgroundColor: '#FFF',
      borderRadius: '4px',
      bottom: '-10px',
      left: '10px',
    },
  },
  buttonWrapper: {
    marginTop: '22px',
    width: '100%',
    '& .MuiButton-root': {
      width: '100%',
      height: '48px',
      backgroundColor: '#101010',
      color: '#FFF',
      textTransform: 'capitalize',
      fontSize: '1.125rem',
      '&:disabled': {
        color: '#fff',
      },
      '&:hover': {
        color: '#101010',
      },
    },
  },
});
