// @vendors
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// @components
import Admin from './Admin/Admin';
import Employer from './Employer/Employer';

// @helpers
import { LISTROLES } from '../helpers/ListRoles';
import { ROUTENAME } from '../navigation/RouteName';
import { permissionsForOrganization } from '../helpers/FunctionsUtils';

const Dashboard = () => {
  const user = useSelector((loadedState) => loadedState.user);
  const history = useHistory();
  const accessToken = user.accessToken;

  if (!accessToken) {
    history.push(ROUTENAME.login);
  }

  const hasPermissions = () => {
    if(user.role === LISTROLES.superAdmin)return true;
    if(user.role === LISTROLES.admin)return true;
    if(permissionsForOrganization(user?.role))return true;
    return false;
  }

  return <>{hasPermissions() ? <Admin /> : <Employer />}</>;
};

export default Dashboard;
