// @vendors
import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    Grid,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormHelperText,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Chip,
    Box,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

// @style
import { StyleAPiClientDialog } from './StyleApiClient';

// @services
import ClientServiceNewApi from '../../services/AppServiceClient/ClientService';

// @helpers
import AppHelper from '../../helpers/AppHelper';
import { authForSuperAdmin } from '../../helpers/GetTokenNewApi';

// @state
import { useUI } from '../../app/context/ui';

// @components
import ModalToken from './ModalToken';
import store from '../../redux/store';
import { MenuItemProps } from '../../helpers/FunctionsUtils';

const CreateClientModal = ({open, onClose, getAllClients}) => {
    const [openModal, setOpenModal] = useState(false);
    const [token, setToken] = useState('')
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const styles = StyleAPiClientDialog()
    const clientService = new ClientServiceNewApi()
    const { blockUI, snackbarUI } = useUI();
    const initialValues = {name: '', companyId: '', token: false}
    const dispatch = useDispatch()
    const user = store.getState()
    
    const shortListCompanies = () => {
        let list = user?.user?.client?.metadata?.companies
        const newList = list?.sort((a, b) => {
            if(a?.name?.toLowerCase() < b?.name?.toLowerCase())return -1
            if(a?.name?.toLowerCase() > b?.name?.toLowerCase())return 1
            return 0
        })
        return newList
    }
    const listCompanies = shortListCompanies()

    const handleCloseModal = () => setOpenModal(false);

    const validationForm = Yup.object({
        name: Yup.string()
          .max(50, 'Max. 50 characters')
          .required('Name is required')
          .typeError(`Name can't be empty`),
        companyId: selectedCompanies?.length ? null : Yup.string()
          .required('Company is required'),
        token: Yup.boolean().nullable().notRequired()
    });

    const formik = useFormik({
        initialValues:initialValues,
        onSubmit: (values) => {
            handleSubmitValues(values)
        },
        validationSchema:validationForm
    })

    const handleSubmitValues = () => {
        const listCompanyId = selectedCompanies?.map((item) => {return item?.id })
        const data = {
            name:formik.values?.name,
            companies:listCompanyId
        }
        sendData(data)
    }

    const sendData = async (data) => {
        try {
            blockUI.current.open(true);
            const response = await clientService.addClient(data)
            if(response?.status === 200 || response?.status === 201){
                if(formik?.values?.token)handleToken()
                getAllClients()
                handleClose()
            }
            blockUI.current.open(false);
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI);
        }
    }

    const handleToken = async () => {
        const response = await authForSuperAdmin(dispatch, snackbarUI, false)
        if(response?.access_token){
            setToken(response?.access_token)
            setTimeout(() => {
                setOpenModal(true)
            }, 200);
        }
    }

    const handleClose = () =>{
        setSelectedCompanies([])
        formik.resetForm()
        onClose()
    }

    const handleChange = (event) => {
        const value = event?.target.value
        setSelectedCompanies(
          typeof value === 'string' ? value.split(',') : value,
        );
    };
    
  return (
    <Grid item xs={12} sm={6} md={3}>
        <Dialog
            maxWidth='sm'
            open={open}
            sx={styles.dialog}
        >
            <DialogTitle sx={styles.title} >Add Api Client</DialogTitle>

            <DialogContent>
                <Grid container sx={styles.body}>
                    <form style={{width:'100%'}} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} sx={styles.fieldsForm}>
                            
                            <Grid item xs={12} md={12} sx={styles.boxItem}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Name*"
                                        id="my-input" 
                                        variant="outlined"
                                        name='name'
                                        InputLabelProps={{shrink:true}}
                                        defaultValue=''
                                        sx={styles.fieldsInput}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12} sx={styles.boxItem}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Company*</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Company*"
                                        name='companyId'
                                        multiple
                                        value={selectedCompanies}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {selected.map((value) => (
                                                <Chip key={value?.id} label={value?.name} />
                                              ))}
                                            </Box>
                                          )}
                                        sx={styles.fieldsInput}
                                        onChange={handleChange}
                                        MenuProps={MenuItemProps}
                                        error={selectedCompanies?.length ? null : formik.touched.companyId && Boolean(formik.errors.companyId)}
                                        helperText={selectedCompanies?.length ? null : formik.touched.companyId && formik.errors.companyId}
                                    >
                                        {listCompanies?.map((company, index) => (
                                            <MenuItem value={company} key={index}>{company?.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText style={{color: '#f44336'}}>
                                    {selectedCompanies?.length ? null : formik.touched.companyId && formik.errors.companyId}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12} sx={styles.boxItem}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name='token'
                                            onChange={formik.handleChange}
                                            sx={styles.checkbox}
                                            />} 
                                        label="Do you want to generate the token?"
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={12} md={12} sx={styles.formButtonWrapper}>
                                <Button
                                    sx={styles.btnCancel}
                                    onClick={() => handleClose()}
                                >
                                Cancel
                                </Button>
                                <Button type='submit' sx={styles.btnSubmit}>Create</Button>
                            </Grid>
                        </Grid>
                    </form>  
                </Grid>
            </DialogContent>
        </Dialog>

        <ModalToken openModal={openModal} onClose={handleCloseModal} token={token} />
    </Grid>
  )
}

export default CreateClientModal