import axios from "axios";
import {
  get as _get,
  has as _has,
  isNull as _isNull,
} from "lodash";

import store from "../../redux/store";

class AppServiceNewApi {
  constructor() {
    this.http = axios.create({
      baseURL: process.env.REACT_APP_API_MAIN_URL_NEW_API,
    });
    this.http.defaults.params = {};
    this.initInterceptors();
  }

  initInterceptors() {
    this.http.interceptors.request.use(
      (request) => {
        const accessToken = this.getAccessToken();
        if (!_isNull(accessToken)) {
          request.headers.common["Authorization"] = "Bearer " + accessToken;
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response.status === 401 &&
          _has(error.response, "config.url") &&
          _get(error.response, "config.url") !== undefined &&
          _get(error.response, "config.url") !== "/auth/login"
        ) {
          window.location = "/login?et=1";
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  setAccessToken(accessToken) {
    if (accessToken) {
      this.http.defaults.headers.common["Authorization"] =
        "Bearer " + accessToken;
    } else {
      delete this.http.defaults.headers.common["Authorization"];
    }
  }

  getAccessToken() {
    const state = store.getState();
    return state?.user?.tokenNewApi?.access_token ?? null;
  }

  logout() {
    return this.http.get("/auth/logout");
  }
}

export default AppServiceNewApi;
