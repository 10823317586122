import React from 'react';

import { Button } from '@mui/material';

import { AppStyle } from '../../assets/css/app/AppStyle';
import { theme } from '../../assets/theme';

const AppButton = (props) => {
  const appStyle = AppStyle();
  const { onPress, label, type, color, disabled, bgColor } = props;

  return (
    <Button
      variant='contained'
      color={color !== undefined ? color : 'default'}
      className={type === 'main' ? appStyle.txtBold : appStyle.btnLogout}
      fullWidth={type === 'main'}
      disabled={disabled}
      onClick={onPress}
      sx={{
        textTransform: 'none',
        fontSize: '1.5rem',
        color: '#FFF',
        backgroundColor: bgColor,
        '&:hover': {
          backgroundColor: bgColor,
          color: '#FFF',
          opacity: '0.8',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '1.2rem',
        },
      }}
    >
      {label}
    </Button>
  );
};

export default AppButton;
