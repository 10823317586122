// @vendors
import React, { useContext } from 'react'
import { Collapse, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/system';

// @assets
import { theme } from '../../../../assets/theme'
import { EmployeeDetailStyles } from '../../../../assets/css/employeeTable-style';
import { DashboardStyles } from '../../../../assets/css/dashboard-style';

// @constants
import { ROUTENAME } from '../../../../navigation/RouteName';

// @context
import { EmployeeContext } from '../../Employer';

const CollapseChild = ({children, open}) => {
    const styles = EmployeeDetailStyles();
    const stylesTable = DashboardStyles();
    const history = useHistory();
    const {getAllChildrens} = useContext(EmployeeContext)
    
    return (
        <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ padding: "0 100px", [theme.breakpoints.down('md')]: { padding: '0 50px' } }}
        >
            {children?.length
                ?   <Grid
                        container
                        spacing={0}
                        sx={{textAlign: 'start'}}
                    >
                        {children.map((child, index) => (
                            <Grid item xs={12} key={index}>
                                <Box
                                    onClick={() => {
                                        history.push(`${ROUTENAME.employer}${child.id}`)
                                        getAllChildrens(child.id);
                                    }}
                                    sx={{
                                        ...stylesTable.employerLink,
                                        paddingLeft: 0,
                                        display: 'inline-block',
                                        padding: '0px 0px 15px 0px'
                                    }}
                                >
                                    {child?.displayName ? child?.displayName : child?.name}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                :   <Typography sx={styles.typography}>This company has no children</Typography>
            }
        </Collapse>
    )
}

export default CollapseChild