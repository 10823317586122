import AppServiceNewApi from "./AppServiceNewApi";

class SettingsServiceNewApi extends AppServiceNewApi{

    getStates(){
        const URL = '/api/internal/v1/settings/states'
        return this.http.get(`${URL}`)
    }
    
    getDetailStates(id){
        const URL = `/api/internal/v1/settings/state/${id}`
        return this.http.get(`${URL}`)
    }

    updateSettingsForState(body, id){
        const URL = `/api/internal/v1/settings/state/${id}`
        return this.http.put(URL,body)
    }
}
export default SettingsServiceNewApi