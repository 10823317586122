import { types } from '../types';

const initialState = {
    sort: '',
    direction: ''
};

function sortEmployeeReducer(state = initialState, payload) {
  switch (payload.type) {
    case types.SORT_EMPLOYEE:
      return {
        ...state,
        sort: payload.sort,
        direction: payload.direction,
      };
    default:
      return state;
  }
}

export default sortEmployeeReducer;