import React from "react";

import sx from "mui-sx";

import { EmployeeTableStyles } from "../../../../../assets/css/employeeTable-style.js";
import {
  Button,
  TableCell,
  TableRow,
} from "../../../../../components/shared/MaterialUI.js";
import { Download } from "@mui/icons-material";
import TaskService from "../../../../../services/newApi/TaskService.js";
import { useUI } from "../../../../../app/context/ui.js";
import AppHelper from "../../../../../helpers/AppHelper.js";

const EmployeeFormRow = (props) => {
  const { row } = props;
  const styles = EmployeeTableStyles();
  const { blockUI, snackbarUI } = useUI();

  const taskService = new TaskService();

  const getEmployeeTask = async () => {
    try {
      blockUI.current.open(true);
      const r1 = await taskService.getTask(row.id);
      blockUI.current.open(false);
      return r1;
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const dowloadTask = async () => {
    getEmployeeTask().then((response) => {
      const resource = response?.data?.data?.resource;
      if (!resource) {
        return;
      }
      let a = document.createElement("a");
      a.href = resource;
      a.download = row.filename;
      a.click();
    });
  };

  return (
    <>
      <TableRow>
        <TableCell sx={styles.emptyTableCell} />
        <TableCell
          scope="row"
          sx={sx(styles.tableCell, { sx: styles.bottomBorder })}
        >
          {new Date(row.createdAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </TableCell>
        <TableCell sx={sx(styles.tableCell, { sx: styles.bottomBorder })}>
          {row.filename}
        </TableCell>
        <TableCell sx={sx(styles.tableCell, { sx: styles.bottomBorder })}>
          <Button
            variant="contained"
            onClick={() => dowloadTask()}
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
            children={
              <Download style={{ color: "#101010", fontSize: "2rem" }} />
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmployeeFormRow;
