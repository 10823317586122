import { theme } from "../theme";

export const ForgotPasswordStyles = () => ({
  bgMain: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 168px)',
  },
  forgotForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    padding: { lg: '80px 128px', md: '80px 80px', xs: '64px 32px' },
    width: { md: '50%', sm: '80%', xs: '95%' },
    minHeight: { md: '420px', xs: '400px' },
    height: { xs: 'auto' },
    boxShadow: '0px 6px 8px 4px rgb(36 35 35 / 15%)',
    borderRadius: '4px',
    '& h1': {
      fontSize: { xs: '3rem', sm: '3.5rem' },
      mb: '30px',
      fontWeight: 700,
    },
    '& h3': {
      fontSize: { xs: '3rem', sm: '1.5rem' },
      mb: '30px',
    },
  },
  wrapperBtnSubmit: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
  },
  btnSubmit: {
    width: '100%',
    borderRadius: '4px',
    fontSize: '1.75rem',
    height: '64px',
    backgroundColor: '#000',
    color: '#FFF',
    fontWeight: '600',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#393a3b',
    },
  },
  logo: {
    width: '315px',
    display: 'block',
    margin: '3% 0 0 6%',
  },

  formMain: {
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    '& h6': {
      fontSize: '1.25rem',
    },
  },
  inputEmail: {
    margin: '32px 0',
    border: 'none',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      height: '56px',
      '& fieldset': {
        border: '1px solid #101010',
        top: '0',
        '& legend': {
          display: 'none',
        },
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        padding: '0 1.25rem',
        height: '100%',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '1rem',
      '&.Mui-focused': {
        color: '#101010',
      },
    },
    '& .MuiInputLabel-shrink': {
      color: '#101010',
      fontSize: '1rem',
      backgroundColor: '#FFF',
      padding: '0 14px',
      borderRadius: '4px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-24px',
      fontSize: '0.75rem',
    },
  },
  dialog: {
    maxWidth: 'sm',
    title: {
      fontSize: '3rem',
      fontWeight: '700',
    },
    content: {
      fontSize: '1.5rem',
    },
    dialogBox: {
      '& .MuiDialog-paper': {
        padding: '2.5rem',
      },
    },
    showButton: 'none',
    ctaButton: '#101010',
  },
});

export const ResetPasswordStyles = (theme) => ({
  resetPasswordMain: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 10.5rem)',
    '& h1': {
      fontSize: { xs: '1.25rem', sm: '3rem' },
      m: '16px 0',
      fontWeight: 700,
    },
    '& h3': {
      fontSize: { xs: '1.25rem', sm: '1.5rem' },
      mb: '30px',
    },
  },
  passwordForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#FFF',
    padding: { lg: '64px 96px', md: '80px 80px', xs: '64px 32px' },
    width: { md: '40%', sm: '80%', xs: '95%' },
    minHeight: { md: '600px', xs: '400px' },
    height: { xs: 'auto' },
    boxShadow: '0px 6px 8px 4px rgb(36 35 35 / 15%)',
    borderRadius: '4px',
  },
  resetPasswordFormInput: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  input: {
    border: 'none',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      height: '56px',
      '& fieldset': {
        border: '1px solid #101010',
        top: '0',
        '& legend': {
          display: 'none',
        },
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #80BB57',
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        padding: '0 1.25rem',
        height: '100%',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '1rem',
      '&.Mui-focused': {
        color: '#101010',
      },
    },
    '& .MuiInputLabel-shrink': {
      color: '#101010',
      fontSize: '1rem',
      backgroundColor: '#FFF',
      padding: '0 14px',
      borderRadius: '4px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-24px',
      fontSize: '0.75rem',
    },
  },
  dialog: {
    maxWidth: 'sm',
    title: {
      fontSize: '2.4rem',
      fontWeight: 700,
    },
    content: {
      fontSize: '1.5rem',
    },
    dialogBox: {
      '& .MuiDialog-paper': {
        padding: '4rem 6rem',
      },
    },
    showButton: 'none',
    ctaButton: '#101010',
  },
  icoShowPassword: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    '& .MuiSvgIcon-root': {
      fontSize: '22px',
    },
  },
  formPasswordError: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    margin: '0',
    marginTop: '3px',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    fontWeight: 400,
  },
  inputWrapperConfirm: {
    margin: '0 0 16px 0',
  },
  wrapperBtnSubmit: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
  },
  btnSubmit: {
    width: '100%',
    borderRadius: '4px',
    fontSize: '1.5rem',
    height: '4rem',
    backgroundColor: '#000',
    color: '#FFF',
    fontWeight: '600',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#393a3b',
    },
  },
  logo: {
    width: '315px',
    display: 'block',
    margin: '3% 0 0 6%',
  },
});

export const LoginStyles = () => ({
  loginContainer: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 168px)',
    '& h1': {
      fontSize: '3rem',
      margin: '16px 0',
      fontWeight: 700,
    },
    '& h3': {
      fontSize: '1.5rem',
      marginBottom: '30px',
      textAlign: 'center',
    },
  },
  logo: {
    width: '315px',
    display: 'block',
    margin: '3% 0 0 6%',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: '64px 112px',
    position: 'relative',
    width: { xl: '30%', md: '50%', sm: '80%', xs: '95%' },
    minHeight: { md: '500px', xs: '560px' },
    height: { xs: 'auto' },
    boxShadow: '0px 6px 8px 4px rgb(36 35 35 / 15%)',
    borderRadius: '4px',
  },
  formMain: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  formPasswordError: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    lineHeight: '1.66',
    textAlign: 'left',
    margin: '3px 14px 0',
    fontWeight: 500,
  },
  gridForgotPassword: {
    textAlign: 'right',
  },
  input: {
    border: 'none',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      height: '56px',
      '& fieldset': {
        border: '1px solid #101010',
        top: '0',
        '& legend': {
          display: 'none',
        },
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #80BB57',
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        padding: '0 1.25rem',
        height: '100%',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '1rem',
      '&.Mui-focused': {
        color: '#101010',
      },
    },
    '& .MuiInputLabel-shrink': {
      color: '#101010',
      fontSize: '1rem',
      backgroundColor: '#FFF',
      padding: '0 14px',
      borderRadius: '4px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-24px',
      fontSize: '0.75rem',
    },
  },
  inputPassword: {
    padding: '0 14px',
    borderRadius: '4px',
    height: '56px',

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-24px',
      fontSize: '0.75rem',
    },
  },
  icoShowPassword: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    '& .MuiSvgIcon-root': {
      fontSize: '22px',
    },
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '1.25rem',
    textDecoration: 'none',
  },
  alert: {
    align: 'center',
  },
  wrapperBtnSubmit: {
    width: '100%',
    margin: '30px 0 0',
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
  },
  btnSubmit: {
    width: '100%',
    borderRadius: '4px',
    fontSize: '1.75rem',
    height: '64px',
    backgroundColor: '#80BB57',
    color: '#FFF',
    fontWeight: '600',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#393a3b',
    },
  },
});

export const ErrorStyles = (theme) => ({
  errorContainer: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '& h1': {
      fontSize: '2.5rem',
      margin: '30px 0',
      fontWeight: 700,
    },
    '& h3': {
      fontSize: '1.3rem',
      marginBottom: '25px',
    },
  },

  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: '64px 112px',
    position: 'relative',
    width: { xl: '45%', md: '50%', sm: '80%', xs: '95%' },
    minHeight: { md: '300px', xs: '360px' },
    height: { xs: 'auto' },
    boxShadow: '0px 6px 8px 4px rgb(36 35 35 / 15%)',
    borderRadius: '4px',
  },

  wrapperBtnReturn: {
    width: '100%',
    margin: '25px 0 0',
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
  },

  btnReturn: {
    width: '100%',
    borderRadius: '4px',
    fontSize: '1.75rem',
    height: '64px',
    backgroundColor: '#80BB57',
    color: '#FFF',
    fontWeight: '600',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#393a3b',
    },
  },
});
