export function createEmployerReducer(state, { type, payload }) {
  switch (type) {
    case 'setFirstFormValues': {
      return {
        ...state,
        firstFormValues: {
          ...state.firstFormValues,
          ...payload
        }
      };
    }
    case 'getStates': {
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          states: payload
        }
      };
    }
    case 'getCounties': {
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          counties: payload
        }
      };
    }
    case 'getCities': {
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          cities: payload
        }
      };
    }
    case 'handleFFInputs': {
      return {
        ...state,
        firstFormValues: {
          ...state.firstFormValues,
          ...payload
        }
      };
    }
    case 'setState': {
      return {
        ...state,
        firstFormValues: {
          ...state.firstFormValues,
          state: payload,
          county: '',
          city: ''
        },
        geoLocation: {
          ...state.geoLocation,
          counties: [],
          cities: []
        }
      };
    }
    case 'setCounty': {
      return {
        ...state,
        firstFormValues: {
          ...state.firstFormValues,
          county: payload,
          city: ''
        },
        geoLocation: {
          ...state.geoLocation,
          cities: []
        }
      };
    }
    case 'setCity': {
      return {
        ...state,
        firstFormValues: {
          ...state.firstFormValues,
          city: payload
        }
      };
    }
    case 'setStateId': {
      return {
        ...state,
        firstFormValues: {
          ...state.firstFormValues,
          stateId: payload
        }
      };
    }
    case 'setSecondFormValue': {
      return {
        ...state,
        secondFormValues: {
          ...state.secondFormValues,
          ...payload
        }
      };
    }
    case 'handleSFInputs': {
      return {
        ...state,
        secondFormValues: {
          ...state.secondFormValues,
          ...payload
        }
      };
    }
    default:
      return state;
  }
}

export const initialState = {
  firstFormValues: {
    displayName: '',
    name: '',
    tradeName: '',
    phoneNumber: '',
    fein: '',
    address: '',
    city: '',
    state: '',
    stateId: '',
    county: '',
    email: '',
    zipCode: '',
    sendEmailToEmployeeWhenCreated: false,
    otherCity: '',
    otherCounty: '',
    isCountyOrCityManual: false,
    isWebhookConfigured: false,
    webhookUrl: '',
    webhookSecret: '',
    externalCompanyId: '',
    rbAcctId: '',
    isAxiscare: false,
    site: '',
    agency: '',
    hubspotId: '',
    parentCompanyId: '',
    sendWotcReminders: false,
    selectEmployeeStateHs: false,
    status: '',
  },
  secondFormValues: {
    payrollContactName: '',
    payrollContactEmail: '',
    poaContact: '',
    poaEmail: '',
    wotcContact: '',
    wotcEmail: '',
    sponsor: '',
    sponsorEmail: '',
    signatureResponsibleName8850Form: '',
    signatureResponsibleTitle8850Form: '',
    signatureResponsibleName9061Form: '',
  },
  geoLocation: {
    states: [],
    counties: [],
    cities: [],
  }
};
