import AppServiceNewApi from "./AppServiceNewApi";

class OccupationServiceNewApi extends AppServiceNewApi{
    
    getOccupation(){
        const url = `/api/internal/v1/occupation`
        return this.http.get(`${url}`)
    }

}
export default OccupationServiceNewApi