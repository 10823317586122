export function formatSSN(ssn) {
  const area = ssn.slice(0, 3);
  const group = ssn.slice(3, 5);
  const serial = ssn.slice(5, 9);

  let formattedSSN = ssn;

  if (ssn.length >= 1) {
    formattedSSN = `${formattedSSN}`;
  }

  if (ssn.length >= 4) {
    formattedSSN = `${area}-${group}`;
  }

  if (ssn.length >= 6) {
    formattedSSN = `${area}-${group}-${serial}`;
  }

  return formattedSSN;
}

export function getRawSSN(inputValue) {
  return [...inputValue].filter((char) => /\d/.test(char)).join('');
}
