// @vendors
import React, {useContext, useState} from 'react';
import {format, set} from 'date-fns';
import {Form, Formik} from 'formik';
import {isEmpty as _isEmpty, isUndefined as _isUndefined} from 'lodash';
import * as Yup from 'yup';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

// @state
import {useUI} from '../../../app/context/ui.js';
import {QuestionnaireContext} from '../Prescreening.js';

// @assets
import { DigitalSignatureStyles} from '../../../assets/css/digitalSignature-styles.js';

// @material-ui
import {
  Box,
  Button,
  TextField,
  Typography,
} from '../../../components/shared/MaterialUI.js';

// @helpers
import AppHelper from '../../../helpers/AppHelper.js';

// @services

import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi.js';

// @components
import {AppCheckbox, AppTextField} from "../../../components/forms";

const DigitalSignature = () => {
  const {
    hashToken,
    employerId,
    lang,
    initial,
    updateQuestion,
  } = useContext(QuestionnaireContext);
  const hasEmptyAnswer = initial.hasEmptyAnswer;
  const message = hasEmptyAnswer ? initial.message : '';
  const {blockUI, snackbarUI} = useUI();
  const styles = DigitalSignatureStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabledBtnSubmit, setIsDisabledBtnSubmit] = useState(false)
  const questionnaireService = new QuestionnaireServiceNewApi()

  const readOnly = !_isEmpty(initial?.answer.answer) && Boolean(employerId);

  function todayDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    return today
  }

  const DateOfToday = todayDate();

  const onSubmit = async (values) => {
    setIsDisabledBtnSubmit(true)
    let btnSubmit = document.getElementById('buttonSubmitDigitalSignature')
    if(btnSubmit.disabled)return
    btnSubmit.disabled = true
    if (hasEmptyAnswer) {
      AppHelper.showCustomMessage(message, snackbarUI);
      setIsDisabledBtnSubmit(false)
      return;
    }
    return canSendInfo(values, btnSubmit)
  };

  const canSendInfo = async (values, btnSubmit) => {
    try {
      let payload = answerForsignature(values)
      blockUI.current.open(true);
      const res = await questionnaireService.questNext(payload, hashToken, lang);
      updateQuestion(res?.data?.data);
      blockUI.current.open(false);
      setIsDisabledBtnSubmit(false)
    } catch (e) {
      btnSubmit.disabled = false
      blockUI.current.open(false);
      setIsDisabledBtnSubmit(false)
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const answerForsignature = (values) => {
    let payload = {
      questionId: initial?.id,
      type: initial?.type,
      groupId: initial?.groupId,
      answer: {...values, date: new Date(values?.date).toISOString() }
    };
    return payload
  }

  const validationSchema = Yup.object().shape({
    authorize: Yup.string()
      .oneOf(['1', ''],
        (lang === 'es')
          ? 'Debes aceptar esto para enviar el cuestionario.'
          : 'You must accept this to send the questionnaire')
      .typeError(null)
      .required(
        (lang === 'es')
          ? 'Esto es obligatorio'
          : 'This is required'
      ),
    declare: Yup.string()
      .oneOf(['1', ''],
        (lang === 'es')
          ? 'Debes aceptar esto para enviar el cuestionario.'
          : 'You must accept this to send the questionnaire')
      .typeError(null)
      .required(
        (lang === 'es')
          ? 'Esto es obligatorio'
          : 'This is required'
      ),
    name: Yup.string('Enter your name')
      .required((lang === 'es') ? 'El nombre es obligatorio' : 'Name is required')
      .typeError((lang === 'es') ? 'El nombre no puede estar vacío' : `Name can't be empty`)
      .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 -\s]+$/,
        (lang === 'es') ? 'El nombre debe ser solo letras' : 'Name must be only letters'),
    lastName: Yup.string('Enter your last name')
      .required((lang === 'es') ? 'El apellido es obligatorio' : 'Last name is required')
      .typeError((lang === 'es') ? 'El apellido no puede estar vacío' : `Last name can't be empty`)
      .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 -\s]+$/,
        (lang === 'es') ? 'El apellido debe ser solo letras' : 'Last name must be only letters'),
    date: readOnly
      ? Yup.string().nullable()
      : Yup.date()
        .min(format(new Date(), 'MM/dd/yyyy'),
          (lang === 'es') ? `La fecha tiene que ser la de hoy` : `Date has to be today's`)
        .max(set(new Date(), {days: 1}),
          (lang === 'es') ? `La fecha tiene que ser la de hoy` : `Date has to be today's`)
        .required((lang === 'es') ? 'La fecha es obligatoria' : 'Date is required')
        .typeError(lang === 'es' ? 'Fecha inválida, debe seguir el formato "MM/dd/aaaa"' : 'Invalid date, must follow "MM/dd/yyyy" format'),
    pin: Yup.string()
      .min(6, (lang === 'es') ? 'Mín. 6 dígitos' : 'Min. 6 digits')
      .max(6, (lang === 'es') ? 'Máx. 6 dígitos' : 'Max. 6 digits')
      .required((lang === 'es') ? 'El PIN es obligatorio' : 'PIN is required')
      .matches(/^[0-9]+$/, (lang === 'es') ? 'Debe contener solo dígitos' : 'Must be only digits')
      .typeError((lang === 'es') ? 'El PIN no puede estar vacío' : 'PIN can not be empty')
      .test('pin', `PIN is not valid`, function (pin) {
        return checkValidPIN(pin);
      }),
  });

  const checkValidPIN = async (pin) => {
    if (_isEmpty(pin) || _isUndefined(pin)) return false;
    if (!pin || pin === 'rocker' || pin.length < 6) return false;
    try {
      await questionnaireService.validateSsnDigitalSignature(hashToken, pin);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Formik
        initialValues={readOnly
          ? {...initial?.answer?.answer}
          : {
            authorize: '',
            declare: '',
            name: '',
            lastName: '',
            date: DateOfToday,
            pin: '',
          }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form
              autoComplete='false'
              className='formCreateUser'
              noValidate
            >
              <input autoComplete='false' type='search' style={{overflow: 'hidden', display: 'none'}}/>
              <Box sx={styles.checkboxBox} style={{marginTop: 30}}>
                {
                  (lang === 'es')
                    ?
                    <Typography>
                      Autorizo al Departamento de Asuntos de Veteranos, Rehabilitación y Corrección,
                      Rehabilitación Vocacional o Administración de Seguridad Social
                      para SSI (Ingreso Suplementario de Seguridad) a proporcionar
                      la verificación o información solicitada por SCREEN Technologies, LLC (Rockerbox)
                      o las Agencias Estatales de Fuerza Laboral, y liberar la información a esas
                      entidades según lo solicitado.
                    </Typography>
                    :
                    <Typography>
                      I authorize the Department of Veterans Affairs, Rehabilitation and Correction,
                      Vocational Rehabilitation, or Social Security Administration for SSI to provide the
                      verification or information requested by SCREEN Technologies, LLC (Rockerbox) or the
                      State Workforce Agencies and release the information to those entities as requested.
                    </Typography>
                }
                <AppCheckbox
                  sx={styles.checkboxCtn}
                  id={`${initial?.id}`}
                  name='authorize'
                  options={[
                    {'label': `${(lang === 'es') ? 'Estoy de acuerdo' : 'I agree'}`, 'value': '1', 'disabled': false},
                    {'label': `${(lang === 'es') ? 'No acepto' : 'I do not accept'}`, 'value': '0', 'disabled': false}
                  ]}
                />
              </Box>
              <Box sx={styles.checkboxBox}>
                {
                  (lang === 'es')
                    ?
                    <Typography>
                      Bajo pena de perjurio, declaro que proporcioné la información anterior al empleador en
                      o antes del día en que se me ofreció un trabajo, y que, según mi leal saber y entender,
                      es verdadera, correcta y completa.
                    </Typography>
                    :
                    <Typography>
                      Under penalties of perjury, I declare that I gave the above information to the employer
                      on or before the day I was offered a job, and it is, to the best of my knowledge, true,
                      correct and complete.
                    </Typography>
                }
                <AppCheckbox
                  sx={styles.checkboxCtn}
                  id={`${initial?.id}`}
                  name='declare'
                  options={[
                    {'label': `${(lang === 'es') ? 'Estoy de acuerdo' : 'I agree'}`, 'value': '1', 'disabled': false},
                    {'label': `${(lang === 'es') ? 'No acepto' : 'I do not accept'}`, 'value': '0', 'disabled': false}
                  ]}
                />
              </Box>
              <Box sx={styles.inputBox}>
                {
                  (lang === 'es')
                    ?
                    <Typography className={'inputTitle'}>
                      Necesitamos que ingreses los siguientes datos para registrar la firma digital y enviar el
                      cuestionario.
                    </Typography>
                    :
                    <Typography className={'inputTitle'}>
                      We need you to enter the following data to register the digital signature and send the
                      questionnaire.
                    </Typography>
                }

                <Box>
                  <Typography>
                    {
                      (lang === 'es') ? 'Nombres' : 'First Name'
                    }
                  </Typography>
                  <AppTextField
                    label={(lang === 'es') ? 'Por favor, escribe tu nombre aquí' : 'Type your first name here'}
                    name='name'
                    id='name'
                    disabled={readOnly}
                    type='text'
                    autoComplete='false'
                  />
                </Box>
                <Box>
                  <Typography>
                    {
                      (lang === 'es') ? 'Apellidos' : 'Last name'
                    }
                  </Typography>
                  <AppTextField
                    label={(lang === 'es') ? 'Por favor, escribe tu apellido aquí' : 'Type your last name here'}
                    name='lastName'
                    id='lastName'
                    disabled={readOnly}
                    required
                    autoComplete='false'
                    type='text'
                  />
                </Box>
                <Box>
                  <Typography>
                    {
                      (lang === 'es') ? 'Fecha' : 'Date'
                    }
                  </Typography>
                  <AppTextField
                    label={(lang === 'es') ? 'Por favor, escribe la fecha de hoy aquí' : `Type today's date here`}
                    name='date'
                    id='date'
                    disabled={readOnly}
                    type='text'
                    autoComplete='false'
                  />
                </Box>
                <TextField type='text' name='hidden' autoComplete='off' sx={{display: 'none'}}/>
                <Box>
                  {
                    (lang === 'es')
                      ?
                      <Typography>PIN (Usa los últimos 6 dígitos de tu SSN)</Typography>
                      :
                      <Typography>PIN (Use last 6 digits of your SSN)</Typography>
                  }
                  <AppTextField
                    label={(lang === 'es') ? 'Escribe tu PIN aquí' : 'Type your pin here'}
                    name='pin'
                    id='pin'
                    disabled={readOnly}
                    type={!showPassword ? 'password' : 'text'}
                    inputProps={{maxLength: 6}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {showPassword && <Visibility />}
                            {!showPassword && <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box sx={styles.buttonWrapper}>
                <Button
                  color='inherit'
                  type='submit'
                  disabled={isDisabledBtnSubmit}
                  id='buttonSubmitDigitalSignature'
                >
                  {
                    (lang === 'es') ? 'Enviar' : 'Submit'
                  }
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};
export default DigitalSignature;