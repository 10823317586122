import AppServiceNewApi from "./AppServiceNewApi"

class HelpersServiceNewApi extends AppServiceNewApi{
    constructor() {
        super();
        this.path = '/api/internal/v1/helpers';
    }
    
    getLocalities(state, county){
        let params = '?'
        if(state)params = `${params}StateName=${state}&`
        if(county)params = `${params}CountyName=${county}&`
        if(params === '?')params = ''
        else params = params.substring(0, params?.length - 1)
        const url = `${this.path}${params}`
        return this.http.get(`${url}`)
    }

    targetGropus(){
        return this.http.get(`${this.path}/tiles`)
    }
    
    getHourlyStartWage(state, county, city){
        return this.http.get(`${this.path}/minimum-wage?state=${state}&county=${county}&city=${city}`)
    }

}
export default HelpersServiceNewApi