import { types } from '../types';

const initialState = {tab:1};

function tabPayroll(state = initialState, payload) {
  switch (payload.type) {
    case types.TAB_PAYROLL:
      return {
        ...state,
        tab: payload.tab
      };
    default:
      return state;
  }
}

export default tabPayroll;