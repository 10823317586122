// @vendors
import * as React from "react";
import { TablePagination } from "@mui/material";

// @components
import { DataTable } from "../../Admin";
import { TablePaginationActions } from "./TablePaginationActions";

export const CustomPagination = (props) => {
  const {
    rowsState,
    pages,
    setPages,
    getAllCompanies,
  } = React.useContext(DataTable);

  const handleChangePage = (event, newPage) => {
    const page = newPage === 0 ? 1 : newPage === pages.page ? newPage + 1 : newPage
    setPages((prev) => ({
      ...prev,
      page: parseInt(page),
      size:pages.size
    }));
    const params = { page: page, size: parseInt(pages.size)}
    getAllCompanies(params);
  };

  const handleChangeRowsPerPage = (event) => {
    setPages((prev) => ({
      ...prev,
      page: 1,
      size: event.target.value
    }));
    const params = {size: event.target.value}
    getAllCompanies(params);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[10,20, 50, 100]}
      colSpan={3}
      count={rowsState.rowCount || 0}
      rowsPerPage={parseInt(pages.size)}
      page={pages.page < 0 || pages.page === 0 ? 0 : pages.page - 1}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page',
        },
        native: true,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={() => TablePaginationActions(rowsState.rowCount, pages.page, pages.size, handleChangePage)}
    />
  );
};
