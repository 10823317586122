import { Typography, Box } from '../../../../../components/shared/MaterialUI.js';

const StringAnswerResult = ({question, pos}) => {
	return (
		<Box>
		<Typography variant='h6'>{pos}. {question.title}</Typography>
		<Typography variant='h6'>{question.answers}</Typography>
		</Box>
	)
}

export default StringAnswerResult;
