// @vendors
import React from 'react';
import { useLocation } from 'react-router-dom';

// @assets
import { ErrorStyles } from '../../assets/css/auth-style';
import logo from '../../assets/images/logoDashboardPublic.svg';

// @components material
import {
  Box,
  Button,
  Link,
  Typography,
} from '../../components/shared/MaterialUI';

// @constants
import { ROUTENAME } from '../../navigation/RouteName';

const ErrorPage = (props) => {
  const styles = ErrorStyles();
  const location = useLocation();

  return (
    <>
      <Link href='/'>
        <img src={logo} alt='dashboard public' className='loginImage' />
      </Link>
      <Box sx={styles.errorContainer}>
        <Box sx={styles.errorBox}>
          <Typography variant='h1'>We are sorry</Typography>
          <Typography variant='h3'>{location?.state?.message ?? 'Return to login'}</Typography>
          <Link sx={styles.wrapperBtnReturn} href={ROUTENAME.login}>
            <Button fullWidth variant='contained' color='primary' sx={styles.BtnReturn}>
              Return To Login Page
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default ErrorPage;
