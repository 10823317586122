import Banner from '../../assets/images/Banner.png';
import HomeCover from '../../assets/images/home_cover.png';
import { theme } from '../theme';

export const HomeStyles = () => ({
  home_ImageContainer: {
    minWidth: '80%',
    width: '80%',
    height: '100vh',
    backgroundImage: `url(${Banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  home_cover: {
    height: '100vh',
    backgroundImage: `url(${HomeCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
  },
  logo_container: {
    width: '60%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& img': {
      width: '100%',
      maxWidth: '600px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '500px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
  },
  option_container: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    padding: '20px 0px',
    borderWidth: '0px 0px 2px 0px',
    borderColor: 'grey',
    borderStyle: 'dashed',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
  },
  option_title: {
    fontSize: '3.5rem',
    fontWeight: 800,
    fontStyle: 'italic',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.2rem',
    },
  },
  option_subtitle: {
    fontSize: '3.5rem',
    fontWeight: 800,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.2rem',
    },
  },
  option_icon: {
    fontSize: '2.2rem',
    fontWeight: 800,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
  },
  servicesBoxCtn: {
    paddingTop: { xs: 4, sm: 6 },
    paddingBottom: { xs: 4, sm: 6 },
    maxWidth: { lg: '90%' },
  },
  serviceCardsCtn: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: { md: 'row', xs: 'column' },
    width: '100%',
    m: '0 auto',
    justifyContent: 'space-between',
    alignItems: { xs: 'center' },
    maxWidth: { lg: '100%' },
  },
  servicesBoxTitle: {
    mt: { xs: 4, sm: 4 },
    mb: { xs: 4, sm: 4 },
    fontSize: '3rem',
    fontWeight: '700',
  },
  cardContainer: {
    maxWidth: { xs: '80%', md: 340, lg: 300, xl: 380 },
    position: 'relative',
    margin: { lg: 0, md: '1rem 0', xs: '1rem 0' },
    p: { xs: '30px 40px', sm: '40px 50px', lg: '30px 40px' },
    height: 420,
    boxShadow: '2px 5px 4px 5px rgba(128, 187, 87, 0.2)',
    borderBottom: `6px solid ${theme.palette.primary.main}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
  },
  cardContent: {
    p: 0,
    height: 'calc(100% - 50px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    '& h2': {
      mb: 1.5,
      fontSize: '1.75rem',
    },
    '& p': {
      fontSize: '1.25rem',
    },
  },
  cardBtnContainer: {
    p: 0,
    position: 'absolute',
    bottom: { xs: '30px', sm: '40px', lg: '30px' },
  },
  cardReadMoreBtn: {
    color: 'inherit',
    textTransform: 'none',
    fontSize: '1.25rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: `underline ${theme.palette.primary.main} solid`,
      color: 'inherit',
    },
  },

  experienceContainer: {
    padding: { xs: 4, sm: '32px 32px 0 32px', md: '64px 64px 0 64px' },
    maxWidth: { lg: '80%', xs: '100%' },
  },
  experienceImageContainer: {
    margin: { xs: '16px 0', md: '48px 0' },
  },
  experienceHeadText: {
    fontSize: { xs: '3.2rem', md: '4rem' },
    fontWeight: 700,
    marginTop: { xs: '16px', md: '48px' },
  },
  experienceLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  experienceBodyText: {
    fontSize: '1.75rem',
    marginTop: { xs: '1.2rem', md: '1.8rem' },
    fontWeight: 400,
  },
  experienceBoxCtn: {
    marginTop: { xs: '1.2rem', md: '1.8rem' },
    display: 'flex',
    justifyContent: 'space-between',
    '& h1': {
      fontSize: '4.5rem',
      fontWeight: 800,
      color: '#657270',
    },
    '& h6': { fontSize: '1.4rem' },
  },
  experienceReadMoreBtn: {
    width: '35%',
    color: '#101010',
    fontSize: '1.25rem',
    textDecoration: 'none',
    fontWeight: 600,
    marginBottom: { xs: '32px', md: '48px' },
    marginTop: { xs: '20px', md: '28px' },
    '&:hover': { textDecoration: 'underline solid #101010' },
  },
});
