
import { theme } from "../theme";

export const HeaderStyles = (_) => ({
  toolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: '#000',
  },
  appBar: {
    //height: { xs: '80px', md: '85px' },
    minHeight: '85px',
    maxHeight: '85px',
    padding: '0 16px',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#fff',
    boxShadow: 'none',
    position: 'unset',
    [theme.breakpoints.down('sm')]: {
      minHeight: '85px',
      maxHeight: '125px',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& img": {
      [theme.breakpoints.down('sm')]: {
        width: '128px',
      },
    },
  },
  companyLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    height: '150px',
    "& img": {
      borderRadius: '5px',
      objectFit: 'cover',
      marginTop: '20px',
      width: '200px',
      maxHeight: '150px',
      [theme.breakpoints.down('sm')]: {
        width: '128px',
      },
    },
  },
  organizationLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px',
    "& img": {
      borderRadius: '3px',
      objectFit: 'fill',
      width: '200px',
      maxHeight: '70px',
      [theme.breakpoints.down('sm')]: {
        width: '128px',
        maxHeight: '50px',
      },
    },
  },
  wrapperLogout: {
    display: 'flex',
    alignItems: 'center',
    color: '#101010',
    fontSize: '1rem',
  },
  dialog: {
    maxWidth: 'md',
    title: {
      fontSize: '2.5rem',
      fontWeight: '700',
      color: '#101010',
    },
    content: {
      fontSize: '1.5rem',
      color: '#101010',
    },
    dialogBox: {
      '& .MuiDialog-paper': {
        padding: '56px',
      },
    },
    cancelButton: 'rgba(36, 35, 35, 0.5)',
    ctaButton: '#101010',
  },
  containerImg:{
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '5px'
    },
  }
});

export const PublicHeaderStyles = (theme) => ({
  appBar: {
    backgroundColor: '#FFF',
    height: { md: '85px', xs: '80px' },
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: { xl: '0 192px', md: '0 2rem', xs: '0 16px' },
  },
  toolbar: {
    justifyContent: { xs: 'space-between' },
    width: '100%',
  },
  mobileMenu: {
    display: { xs: 'flex', md: 'none' },
  },
  menuItems: {
    display: { xs: 'none', md: 'flex' },
    width: { md: '80%' },
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  wrapperLog: {
    display: 'flex',
    gap: '8px',
  },
  btnSignIn: {
    width: { lg: '192px', xs: '112px' },
    height: { lg: '56px', md: '48px', xs: '40px' },
    fontSize: '1.5rem',
    textTransform: 'none',
    backgroundColor: '#101010',
    color: '#FFF',
    '&:hover': { backgroundColor: 'rgba(240, 240, 240, 0.6)', color: '#101010' },
  },
});
