export const SettingsStyles = (theme) => ({

    boxButtons: {
        display:'flex', 
        justifyContent: 'space-evenly',
        paddingTop: '2.5em'
    },

    button: {
        width: '20em',
        maxHeight: '45px',
        height: '40px',
        fontSize: '1.2rem',
        textTransform: 'none',
        borderRadius: '0.5em',
        backgroundColor: '#101010',
        color: '#FFF',
        '&:hover': {
          backgroundColor: '#101010',
        },
      },

    buttonCancel: {
        width: '20em',
        maxHeight: '45px',
        height: '40px',
        fontSize: '1.2rem',
        textTransform: 'none',
        borderRadius: '0.5em',
        backgroundColor: 'rgba(36,35,35, 0.3)',
        color: '#FFF',
        '&:hover': {
          backgroundColor: 'rgba(36,35,35, 0.3)',
        },
      },

      dialog: {
        maxWidth: 'sm',
        title: {
          fontSize: '2rem',
          fontWeight: '700',
          color: '#101010',
        },
        content: {
          fontSize: '1.5rem',
          color: '#101010',
        },
        dialogBox: {
          '& .MuiDialog-paper': {
            padding: '48px',
          },
        },
        cancelButton: 'rgba(36, 35, 35, 0.5)',
        ctaButton: '#101010',
        showButton: 'none',
      },
})