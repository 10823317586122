// @vendors
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';

// @assets
import { unsubscribeEmailStyles } from '../../../../assets/css/unsubscribe-style'

// @context
import { useUI } from '../../../../app/context/ui';

// @helpers
import AppHelper from '../../../../helpers/AppHelper';

// @services
import UnsubscribeService from '../../../../services/newApi/UnsubscribeService';

const UnsubscribeEmail = () => {
    const [validRequest, setValidRequest] = useState(false)
    const [messageView, setMessageView] = useState({ title: null, subtitle: null })
    const styles = unsubscribeEmailStyles()
    const maxWidth = useMediaQuery('(min-width:600px)');
    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tkn = searchParams.get("tkn");
    const { blockUI, snackbarUI } = useUI();
    const unsubscribeService = new UnsubscribeService();

    const onSubmit = async () => {
        try {
            blockUI.current.open(true);
            const response = await unsubscribeService.unsubscribe(id, tkn);
            setMessageView({ title: response?.data?.message, subtitle: response?.data?.subMessage })
            setValidRequest(true)
            blockUI.current.open(false);
        } catch (e) {
            blockUI.current.open(false);
            AppHelper.checkError(e, snackbarUI);
        }
    }

    useEffect(() => {
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={styles.containerUnsubscribe}>
            {validRequest ? <div style={maxWidth ? styles.containerBodyUnsubscribe : styles.containerBodyUnsubscribeSx}>
                <h4 style={styles.title}>{messageView?.title ?? 'You’ve been unsubscribed'}</h4>
                <p style={styles.subtitle}>{messageView?.subtitle ?? 'We won’t send you any more emails.'}</p>
            </div> : null}
        </div>
    )
}

export default UnsubscribeEmail