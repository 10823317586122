// @vendors
import React from 'react'
import { Box, TablePagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';

// @helpers
import {TablePaginationActions} from '../../../helpers/TablePaginationAction';
import { callListPayroll } from '../../../helpers/FunctionsUtils';

// @constants
import { types } from '../../../redux/types';
import { PayrollStyle } from '../../../assets/css/payroll-style';

const Paginator = () => {
    const statePagination = useSelector((state) => state?.pagination)
    const dispatch = useDispatch()
    const style = PayrollStyle()

    const handleChangePage = (event, newPage) => {
        const page = newPage === 0 ? 1 : newPage === statePagination.page ? newPage + 1 : newPage
        dispatch({
            type: types.PAGINATION,
            page: page,
            size: statePagination.size,
            totalRows: statePagination.totalRows
        })
        callListPayroll(dispatch, true)
    };
    
    const handleChangeRowsPerPage = (event) => {
        const rows = event.target.value
        dispatch({
            type: types.PAGINATION,
            page: 1,
            size: rows,
            totalRows: statePagination.totalRows
        });
        callListPayroll(dispatch, true)
    };

  return (
    <Box sx={style.contaierPagination}>
        <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 20, 50]}
        colSpan={3}
        count={statePagination.totalRows || 0}
        rowsPerPage={statePagination.size}
        page={statePagination.page < 0 || statePagination.page === 0 ? 0 : statePagination.page - 1}
        SelectProps={{
        inputProps: {'aria-label': 'rows per page'},
        native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={() => TablePaginationActions(statePagination, handleChangePage)}
    />
    </Box>
  )
}

export default Paginator