import * as Yup from 'yup';

export const validationEmployee = Yup.object({
  firstName: Yup.string('Enter your first name')
    .max(50, 'Max. 50 characters')
    .required('First name is required')
    .matches(/^[a-zA-ZÀ-ÿ -\s']+$/, "Not numbers allowed")
    .typeError(`First name can't be empty`),
  lastName: Yup.string('Enter your last name')
    .max(50, 'Max. 50 characters')
    .required('Last name is required')
    .matches(/^[a-zA-ZÀ-ÿ -\s']+$/, "Not numbers allowed")
    .typeError(`Last name can't be empty`),
  email: Yup.string('Enter the employee Email')
    .email('Invalid email')
    .max(50, 'Max. 50 characters')
    .required()
    .typeError(`Email can't be empty`),
  externalEmployeeId: Yup.string().nullable().notRequired()
});
