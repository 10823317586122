import { loadedState } from '../state';
import { types } from '../types';

const initialState = { ...loadedState };

function user(state = initialState, { type, payload }) {
  switch (type) {
    case types.USER_ADD:
      return { ...state, ...payload };
    default:
      return state;
  }
}

export default user;
