import React, { forwardRef, useImperativeHandle, useState } from "react";

import { theme } from "../../../../assets/theme.js";

import AppButton from "../../../../components/forms/AppButton.js";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "../../../../components/shared/MaterialUI.js";
import { useUI } from "../../../../app/context/ui.js";
import AppHelper from "../../../../helpers/AppHelper.js";
import TaskService from "../../../../services/newApi/TaskService.js";

const CustomDialog = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [messageToShow, setMessageToShow] = useState("");
  const [idZipToDownload, setIdZipToDownload] = useState("");
  const { blockUI, snackbarUI } = useUI();

  const taskService = new TaskService();

  const onClose = (reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
    props.onUpdate();
  };

  useImperativeHandle(ref, () => ({
    open: (message, data) => {
      setIdZipToDownload(data.id);
      setQuestionnaires(data.states);
      setMessageToShow(message);
      setOpen(true);
    },
    close: () => {
      setOpen(false);
      setIdZipToDownload("");
    },
  }));

  const onZipDownload = async () => {
    try {
      blockUI.current.open(true);

      const response = await taskService.getTask(idZipToDownload);
      const data = response.data.data;
      if (!data.resource) {
        return;
      }
      let a = document.createElement("a");
      a.href = data.resource;
      a.download = data.filename;
      a.click();

      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={(_, reason) => onClose(reason)}
        disableEscapeKeyDown={true}
        sx={{
          "& .MuiDialog-paper": {
            paddingX: "120px",
            paddingY: "64px",
            display: "flex",
            gap: "24px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "2.5rem",
            fontWeight: "bold",
          }}
        >
          Congratulations !
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            minHeight: "210px",
          }}
        >
          <DialogContentText>{messageToShow}</DialogContentText>
          <Grid container rowSpacing={2} columnSpacing={4} marginTop={2}>
            {questionnaires.map((questionnaire, index) => {
              return (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "6px",
                  }}
                  item
                  xs={4}
                  key={questionnaire.state}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                      width: "50%",
                      paddingX: "16px",
                      paddingY: "4px",
                      textAlign: "start",
                      borderRadius: "2px",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {questionnaire.stateAbbr}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50%",
                      paddingY: "4px",
                      paddingX: "16px",
                      borderRadius: "2px",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                      }}
                    >
                      {questionnaire.count}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {idZipToDownload && (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Grid item xs={6} sx={{ display: "flex" }}>
                <AppButton
                  onPress={onZipDownload}
                  label="Download"
                  color={"primary"}
                  bgColor={theme.palette.primary.main}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            paddingY: "24px",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={6} sx={{ display: "flex" }}>
              <AppButton
                onPress={onClose}
                label="Close"
                color={"primary"}
                bgColor={"black"}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default CustomDialog;
