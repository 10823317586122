// @vendors
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';

// @components
import {
    Box,
    TableCell,
    TableRow,
    KeyboardArrowUpIcon,
    KeyboardArrowDownIcon,
    IconButton,
    Collapse,
    Typography,
    Grid,
    Button,
} from "../../components/shared/MaterialUI";
import ModalToken from './ModalToken';

// @style
import { StyleAPiClient } from './StyleApiClient';

// @state
import { useUI } from '../../app/context/ui';

// @helpers
import AppHelper from '../../helpers/AppHelper';
import { authForSuperAdmin } from '../../helpers/GetTokenNewApi';

// @services
import ClientServiceNewApi from '../../services/AppServiceClient/ClientService';
import { Divider } from '@mui/material';

const RowClient = ({row}) => {
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [client, setClient] = useState({})
    const [showToken, setShowToken] = useState('')
    const styles = StyleAPiClient()
    const { blockUI, snackbarUI } = useUI();
    const clientService = new ClientServiceNewApi()
    const dispatch = useDispatch()
    
    const handleCloseModal = () => setOpenModal(false)

    const openDetail = (canSend) => {
        setOpen(!open)
        if(canSend === 'yes')getClient()
    }   

    const getClient = async () => {
       try {
        blockUI.current.open(true);
        const response = await clientService.getClientById(row?.id)
        setClient(response?.data)
        blockUI.current.open(false);
       } catch (error) {
        blockUI.current.open(false);
        AppHelper.checkError(error, snackbarUI);
       }
    }   
    
    const handleToken = async () => {
        const response = await authForSuperAdmin(dispatch, snackbarUI, false)
        if(response?.access_token){
            setShowToken(response?.access_token)
            setOpen(!open)
            setTimeout(() => {
                setOpenModal(true)
            }, 200);
        }
    }

  return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={!open ? styles.tableCell : styles.tableCellNoBorder}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => openDetail(open ? 'no' : 'yes')}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={!open ? styles.tableCell : styles.tableCellNoBorder}>
                    {row?.name}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: '0px 5%'}} colSpan={6} >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container sx={styles.containerBtnToken}>
                            <Button
                                sx={styles.btnReturn}
                                onClick={() => handleToken()}
                                >
                                Create token
                            </Button>
                        </Grid>

                        <Box sx={styles.bodyCollapse}>
                            <Box sx={styles.bodyCollapseMiddle}>
                                <Typography variant="h6" sx={styles.titleCollapse}>
                                    Client secret
                                </Typography>
                                <Typography variant="h6" sx={styles.subtitleCollapse}>
                                    {client?.secret}
                                </Typography>
                            </Box>
                            <Box sx={styles.bodyCollapseMiddle}>
                                <Typography variant="h6" sx={styles.titleCollapse}>
                                    Client id
                                </Typography>
                                <Typography variant="h6" sx={styles.subtitleCollapse}>
                                    {client?.id}
                                </Typography>
                            </Box>                        
                        </Box>

                        <Divider sx={styles.divider}/>

                        <Box sx={styles.bodyCollapse} style={{marginTop:'20px'}}>
                            <Box sx={styles.bodyCollapseMiddle}>
                                <Typography variant="h6" sx={styles.titleCollapse}>
                                    Associated companies
                                </Typography>
                            </Box>                      
                            <Box sx={styles.bodyCollapseMiddle}>
                                <Typography variant="h6" sx={styles.titleCollapse}>
                                    Associated company id
                                </Typography>
                            </Box>                      
                        </Box>

                        {client?.companies?.map((company, index) => (
                            <>
                                <Box sx={styles.bodyCollapse} key={index}>
                                <Box sx={styles.bodyCollapseMiddle} >
                                    <Typography variant="h6" sx={styles.subtitleCollapse}>
                                        {company?.name}
                                    </Typography>
                                </Box>                      
                                <Box sx={styles.bodyCollapseMiddle}>
                                    <Typography variant="h6" sx={styles.subtitleCollapse}>
                                        {company?.id}
                                    </Typography>
                                </Box>  
                            </Box>
                            <Divider sx={styles.divider}/>  
                            </>                 
                        ))}
                    </Collapse>
                </TableCell>
            </TableRow>

            <ModalToken openModal={openModal} onClose={handleCloseModal} token={showToken} />
        </React.Fragment>
    )
}

export default RowClient