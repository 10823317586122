// @vendors
import React from 'react';
import QRCode from 'react-qr-code';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	Grid,
	Typography,
	Backdrop,
	CircularProgress,
} from '@mui/material';

// @assets
import { EmployeeTableStyles } from '../../../../../assets/css/employeeTable-style';

// @components
import { AddIcon } from '../../../../../components/shared/MaterialUI';
import AppHelper from '../../../../../helpers/AppHelper';
import { useUI } from '../../../../../app/context/ui';

export const AddNewEmployeeModal = (props) => {
	const {
		open,
		onClose,
		onNewEmployee,
		onImageDownload,
		qrValue,
		companyId,
	} = props;
	const styles = EmployeeTableStyles();
	const qrValueOb365 = 'https://app.rockerbox.tech/ob365';
	const { snackbarUI } = useUI();

	const copyLink = (e) => {
		navigator.clipboard.writeText(`${qrValue}`).then(() => {
			snackbarUI.current.open("Link Copied");
			setTimeout(() => {
			  snackbarUI.current.close();
			}, 1500);
		}).catch((e) => {
			AppHelper.checkError(e, snackbarUI);
		});
	  };

	return (
		<>
			<Dialog
				maxWidth='sm'
				open={open}
				onClose={onClose}
				onBackdropClick={onClose}
				sx={styles.dialog.dialogBox}
			>
				<DialogTitle textAlign="center" sx={styles.dialog.title}>Add New Employee</DialogTitle>

				<DialogContentText sx={styles.dialog.content} whiteSpace="pre-line">
					Scan or download this
					QR code to share with your potential employees and get them to register.
				</DialogContentText>

				<DialogActions>
					<Grid container direction='row' alignItems='center' spacing={3}>
						<Grid item xs={12} align='center'>
							<QRCode
								id="QRCode"
								title={'Download or scan this code to create an employee'}
								value={qrValue ?? ''} 
							/>
							<QRCode
								id="QRCodeOb365"
								title={'Download or scan this code to go ob365 page'}
								value={qrValueOb365 ?? ''}
								style={{display: 'none'}}
							/>
						</Grid>

						<Grid item xs={12}>
							<Button
								variant='outlined'
								endIcon={<AddIcon />}
								sx={styles.button}
								onClick={() => onImageDownload('QRCode')}
							>
								Download QR
							</Button>
						</Grid>

						{/* this button only is showed on prod for the user with this id, if the user id is changed the button is hidden */}
						{companyId === process.env.REACT_APP_API_ID_ATLANTIT_SOL &&
							<Grid item xs={12}>
								<Button
									variant='outlined'
									endIcon={<AddIcon />}
									sx={styles.button}
									onClick={() => onImageDownload('QRCodeOb365')}
								>
									Download QR for OB365
								</Button>
							</Grid>
						}
						<Grid item xs={12}>
							<Typography variant="body1" sx={{textAlign:'center'}}>
								OR
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Button
								variant='outlined'
								endIcon={<AddIcon />}
								sx={styles.button}
								onClick={onNewEmployee}
							>
								Add employee manually
							</Button>
						</Grid>
						
						{qrValue &&
							<Grid item xs={12}>
								<Typography sx={{marginBottom: '20px'}}>{qrValue}</Typography>
								<Button
									variant='outlined'
									sx={styles.button}
									onClick={() => copyLink()}
								>
									copy link
								</Button>
							</Grid>
						}

						<Grid item xs={12} align='center'>
							<Backdrop
								sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
								open={!qrValue ? true : false}
							>
								<CircularProgress color="inherit" />
							</Backdrop>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	)
}
