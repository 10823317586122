import AppServiceNewApi from './AppServiceNewApi';

class OB365ServiceNewApi extends AppServiceNewApi {
  constructor() {
    super();
    this.path = '/api/v1/ob/ob365/validate-email';
  }

  validateEmail(payload) {
    return this.http.post(`${this.path}`, payload);
  }
  
  getLogoOb() {
    return this.http.get('/api/internal/v1/ob/logo');
  }

}

export default OB365ServiceNewApi;