// @vendors
import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { isEmpty as _isEmpty } from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

// @context
import { useUI } from "../../app/context/ui";

// @assets
import { ResetPasswordStyles } from "../../assets/css/auth-style";
import logo from "../../assets/images/logoDashboardPublic.svg";

// @componnets material
import {
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Visibility,
  VisibilityOff,
} from "../../components/shared/MaterialUI";

// @helpers
import AppHelper from "../../helpers/AppHelper";

// @services
import AuthService from "../../services/newApi/AuthServiceNewApi";

// @constants
import { ROUTENAME } from "../../navigation/RouteName";

const ResetPassword = (props) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let token = params.token;
  const { dialogUI } = useUI();
  const { blockUI, snackbarUI } = useUI();
  const history = useHistory();
  const styles = ResetPasswordStyles();
  const authService = new AuthService();

  if (_isEmpty(token) || token.length < 64) {
    history.push("/home");
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required("Confirm Password is required"),
  });

  const onSubmit = async (values) => {
    let { password, password_confirmation } = values;
    let payload = {
      password,
      password_confirmation,
      token,
    };

    try {
      blockUI.current.open(true);
      await authService.resetPassword(payload);

      const settings = {
        confirm: true,
        btn: {
          confirm: "OK",
          close: "Cancel",
        },
        onConfirm: () => {
          dialogUI.current.close();
          history.push(ROUTENAME.login);
        },
        styles: { ...styles.dialog },
        type: "logout",
      };
      dialogUI.current.open(
        "Success",
        "Your password was updated successfully",
        settings
      );

      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    document.title = `RockerBox - ${props.title}`;
  });

  return (
    <>
      <CssBaseline />
      <Link href="/" sx={styles.logo}>
        <img src={logo} alt="dashboard public" className="loginImage" />
      </Link>

      <Container
        component="main"
        disableGutters
        maxWidth="100%"
        sx={styles.resetPasswordMain}
      >
        <Typography variant="h1">Reset your password</Typography>
        <Typography variant="h3">Enter your new password.</Typography>
        <Box component="div" sx={styles.passwordForm}>
          <CssBaseline />
          <Box component="div" sx={styles.resetPasswordFormInput}>
            <Formik
              initialValues={{
                password: "",
                password_confirmation: "",
                showPassword: false,
                showPasswordConfirm: false,
              }}
              onSubmit={(values) => {
                onSubmit(values).then(() => {});
              }}
              validationSchema={ResetSchema}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                } = props;
                return (
                  <Form className="passwordForm" autoComplete="off">
                    <Box sx={{ mb: 4, width: "100%" }}>
                      <label htmlFor="new-password" className="inputLabels">
                        New password:
                      </label>
                      <Box sx={{ position: "relative" }}>
                        <TextField
                          required
                          fullWidth
                          autoComplete="new-password"
                          label="Type your new password here"
                          name="password"
                          id="new-password"
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Type you password here"
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : ""
                          }
                          error={errors.password && touched.password}
                          sx={styles.input}
                        />
                        <InputAdornment position="end">
                          <IconButton
                            sx={styles.icoShowPassword}
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setFieldValue(
                                "showPassword",
                                !values.showPassword
                              );
                            }}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </Box>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                      <label htmlFor="confirm-password" className="inputLabels">
                        Confirm your password:
                      </label>
                      <Box sx={{ position: "relative" }}>
                        <TextField
                          required
                          fullWidth
                          autoComplete="new-password"
                          label="Type your new password here"
                          name="password_confirmation"
                          id="confirm-password"
                          type={
                            values.showPasswordConfirm ? "text" : "password"
                          }
                          value={values.password_confirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Type you new password here"
                          helperText={
                            errors.password_confirmation &&
                            touched.password_confirmation
                              ? errors.password_confirmation
                              : ""
                          }
                          error={
                            errors.password_confirmation &&
                            touched.password_confirmation
                          }
                          sx={styles.input}
                        />
                        <InputAdornment position="end">
                          <IconButton
                            sx={styles.icoShowPassword}
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setFieldValue(
                                "showPasswordConfirm",
                                !values.showPasswordConfirm
                              );
                            }}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPasswordConfirm ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </Box>
                    </Box>
                    <Box sx={styles.wrapperBtnSubmit}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={styles.btnSubmit}
                      >
                        Reset my password
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default connect(null)(ResetPassword);
