// @vendors
import React from 'react'
import { Divider, Fade, Menu, MenuItem } from '@mui/material'

// assets
import { MenuExcelStyle } from '../../../assets/css/payroll-style';

const ExportToExcel = ({anchorEl, handleClose}) => {
    const style = MenuExcelStyle()
    const open = Boolean(anchorEl);
    
  return (
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}
        sx={style.containerMenu}
        transitionDuration={25}
        TransitionComponent={Fade}
        disableAutoFocusItem={true}
      >
        <MenuItem onClick={handleClose} sx={style.menuItem}>Entire Data</MenuItem>
        <Divider/>
        <MenuItem onClick={handleClose} sx={style.menuItem}>Filtered Data Only</MenuItem>
      </Menu>
  )
}

export default ExportToExcel