// @vendors
import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

const Pageheader = ({title, subtitle}) => {
  return (
    <Box>
      <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "bold" }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: { xs: "16px", md: "24px" } }}>
        {subtitle}
      </Typography>
    </Box>
  )
}

export default Pageheader