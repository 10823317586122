// @vendors
import React from 'react'

// @material-ui
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from "../../../components/shared/MaterialUI";
import { styled } from "@mui/material/styles";

// @assets
import { PayrollStyle } from '../../../assets/css/payroll-style';

// @helpers
import {
    formattDate,
    validateEmptyString
} from '../../../helpers/FunctionsUtils';
import { tooltipClasses } from '@mui/material';


const GeneralTable = ({data}) => {
  const style = PayrollStyle()

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 350,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const getTargetGroup = (item) => {
    return item?.split(',')[0]
    //return item?.replaceAll(`,`,`, `);
  }
  
  const getListTargetGroup = (item) => {
    return item?.replaceAll(`,`,`, `);
  }

  return (
    <Table aria-label="collapsible table" stickyHeader>
        <TableHead sx={style.tableRow}>
            <TableRow sx={style.tableRow}>
                <TableCell sx={style.tableHeadTitle}>Last Sync Date</TableCell> 
                <TableCell sx={style.tableHeadTitle}>State Cert.</TableCell> 
                <TableCell sx={style.tableHeadTitle}>State Status</TableCell>
                <TableCell sx={style.tableHeadTitle}>Employee First Name</TableCell>        
                <TableCell sx={style.tableHeadTitle}>Employee Last Name</TableCell>        
                <TableCell sx={style.tableHeadTitle}>Employeer</TableCell>        
                <TableCell sx={style.tableHeadTitle}>Employer Rate (%)</TableCell>        
                <TableCell sx={style.tableHeadTitle}>Target Group</TableCell>        
                <TableCell sx={style.tableHeadTitle}>Target Group Code</TableCell>        
                <TableCell sx={style.tableHeadTitle}>Hire Date</TableCell>   
                <TableCell sx={style.tableHeadTitle}>Pay Schedule</TableCell>
                <TableCell sx={style.tableHeadTitle}>Current YTD Gross wages ($)</TableCell>
                <TableCell sx={style.tableHeadTitle}>Current YTD Hours</TableCell>
                <TableCell sx={style.tableHeadTitle}>WOTC Credit % Multiplier</TableCell>  
                <TableCell sx={style.tableHeadTitle}>Max Wage ($)</TableCell>
                <TableCell sx={style.tableHeadTitle}>Max Tax Credit ($)</TableCell>
                <TableCell sx={style.tableHeadTitle}>Tax Credit YTD ($)</TableCell>
                <TableCell sx={style.tableHeadTitle}>Invoice YTD ($)</TableCell>
                <TableCell sx={style.tableHeadTitle}>Actual Invoice ($)</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
        {data?.data?.map((item, index) => (
            <TableRow key={index} sx={{display:'flex', padding: '0 0 0 5px'}}>
            <TableCell sx={style.tableCellRow} >{formattDate(item?.lastSyncDate)}</TableCell>
            <TableCell sx={style.tableCellRow} >{validateEmptyString(item?.stateCertification)}</TableCell>
            <TableCell sx={style.tableCellRow} >{validateEmptyString(item?.stateStatus)}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.employeeFirstName}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.employeeLastName}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.employeer}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.employeerRate}</TableCell>
            <HtmlTooltip title={getListTargetGroup(item?.targetGroup) ?? ''}>
                <TableCell sx={style.tableCellRow} >
                    {getTargetGroup(item?.targetGroup)}
                </TableCell>
            </HtmlTooltip>
            <TableCell sx={style.tableCellRow} >{item?.targetGroupCode}</TableCell>
            <TableCell sx={style.tableCellRow} >{formattDate(item?.hireDate)}</TableCell>
            <TableCell sx={style.tableCellRow} >{validateEmptyString(item?.paySchedule)}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.currentYTDGrossWages}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.currentYTDHours}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.wotcCreditMultiplier}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.maxWage}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.maxTaxCredit}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.taxCreditYTD}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.invoiceYTD}</TableCell>
            <TableCell sx={style.tableCellRow} >{item?.actualInvoice}</TableCell>
            </TableRow> 
        ))}   
        </TableBody>
        
    </Table>
  )
}

export default GeneralTable