/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @store
import { useUI } from "../../app/context/ui";

// @image
import { DashboardStyles } from "../../assets/css/dashboard-style";

// @components
import {
  Box,
  Container,
  CssBaseline,
} from "../../components/shared/MaterialUI.js";
import { ClientTable } from "./components/ClientTable";
import { StatsBoxes } from "./components/StatsBoxes";
import Pageheader from "../../components/PageHeader.js";

// @service
import CompanyServiceNewApi from "../../services/newApi/CompanyServiceNewApi.js";

// @helpers
import AppHelper from "../../helpers/AppHelper";
import { ROUTENAME } from "../../navigation/RouteName.js";

const Dashboard = () => {
  const state = useSelector((loadedState) => loadedState);
  const history = useHistory();
  const { blockUI, snackbarUI } = useUI();
  const [filtered, setFiltered] = useState(false);
  const [status, setStatus] = useState("");
  const user = state.user;
  const styles = DashboardStyles();
  const dispatch = useDispatch()
  const companySNewApi = new CompanyServiceNewApi()
  const [sortModel, setSortModel] = useState([]);
  const organizationId = state?.organizationReducer?.organizationSelected?.id;
  const organizationName = state?.organizationReducer?.organizationSelected?.name;

  if (!user?.accessToken) {
    history.push(ROUTENAME.login);
  }

  const [rowsState, setRowsState] = useState({
    pageSize: 0,
    rows: [],
    rowCount: 0,
    page: 0,
  });

  const [pages, setPages] = useState({
    page: 0,
    size: 10,
    hasOrder: null,
    orderBy:null
  });

  const [search, setSearch] = useState({
    name: '',
    status: '',
  });

  //get companies for new api
  const getAllCompanies = async (params) => {
    const pagination = {page: params?.page ?? 1, size: params?.size ?? pages.size}
    const filters = params?.filters ?? search;
    const sort = {order: params?.order ?? pages.hasOrder, direction: params?.orderBy ?? pages.orderBy }
    try {
      blockUI.current.open(true);
      const response = await companySNewApi.getCompanies(pagination, organizationId, filters, sort)
      setSortModel(params?.sort)
      successRequestCompanies(response)
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const successRequestCompanies = (response) => {
    blockUI.current.open(false);
    const data = response?.data
    setPages((prev) => ({
      ...prev,
      page: data?.pagination?.page_number,
      size: data?.pagination?.page_size,
      order: pages.order
    }));
    setRowsState((prev) => ({
      ...prev,
      pageSize: data?.pagination?.page_size || 10,
      rows: data?.data,
      rowCount: data?.pagination?.total_records,
      page: data?.pagination.page_number - 1,
    }));
  }

  useEffect(() => {
    blockUI.current.open(true);
    if(!organizationId)return blockUI.current.open(false);
    setTimeout(() => {
      getAllCompanies()
    }, 200);
  }, [organizationId]) 

  useEffect(() => {
    if(user?.hasNewToken && !user?.expiryTime){
      dispatch({ type: 'LOGOUT' })
    };
  }, [])

  return (
    <>
      <CssBaseline />
      <Container
        component="section"
        maxWidth="xl"
        disableGutters
        sx={styles.dashCtn}
      >
        <Container
          component="div"
          disableGutters
          maxWidth="xl"
          justify="space-between"
          sx={styles.upperCtn}
        >
          <Pageheader
            title={'Dashboard'}
            subtitle={`Welcome to ${organizationName} dashboard`}
          />
        </Container>
        <Box
          maxWidth="xl"
          sx={styles.buttonCtn}
        >
        <StatsBoxes />
        </Box>
        <DataTable.Provider
          value={{
            rowsState,
            pages,
            filtered,
            getAllCompanies,
            setFiltered,
            setRowsState,
            setPages,
            status,
            setStatus,
            search,
            setSearch,
            setSortModel,
            sortModel
          }}
        >
          <ClientTable accessToken={user?.accessToken} />
        </DataTable.Provider>
      </Container>
    </>
  );
};

export const DataTable = createContext({});
export default Dashboard;
