export const componentsStyles = {
  panel: {
    sx: {
      transform: "translate(35%, 680px) !important",
      width: "60%",
      "& .MuiDataGrid-filterForm": {
        "& .MuiInput-root": {
          border: "1px solid rgba(224,224,224,1)",
          marginTop: "24px",
          borderRadius: "6px",
          "&::before, &::after": {
            content: "unset",
          },
        },
      },
      "& .MuiPaper-root": {
        minHeight: "8rem",
        padding: "1rem 4rem 0",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        ".statusSelect": {
          width: "80%",
          ".selectLabel": {
            color: "rgba(128, 187, 87, 1)",
            fontSize: "1.5rem",
            top: "-25px",
          },
        },
        "& .MuiNativeSelect-select": {
          height: "46px",
          padding: "0px 18px",
          "&:hover": {
            bgcolor: "rgba(224,224,224,0.6)",
            borderRadius: "4px",
          },
        },
        "& .MuiInput-root": {
          height: "46px",
          width: "100%",
          border: "1px solid rgba(224,224,224,1)",
          marginTop: "0",
          borderRadius: "4px",
          "&::before, &::after": {
            content: "unset",
          },
        },
        "& 	.MuiButton-root": {
          textTransform: "capitalize",
          height: "46px",
          border: "1px solid rgba(224,224,224,1)",
          color: "#101010",
          flexDirection: "row-reverse",
          justifyContent: "space-around",
          "& span": {
            margin: "0",
          },
          ":hover": {
            background: "rgba(0,0,0,0.1)",
          },
        },
      },
    },
  },
};

export const toolbarPropStyles = {
  sx: {
    marginTop: "32px",
    padding: "1rem 4rem 0",
    height: "128px",
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& .MuiDataGrid-filterForm": {
      "& .MuiInput-root": {
        border: "1px solid rgba(224,224,224,1)",
        marginTop: "24px",
        borderRadius: "6px",
        "&::before, &::after": {
          content: "unset",
        },
      },
    },
    ".statusSelect": {
      width: "80%",
      ".selectLabel": {
        color: "rgba(128, 187, 87, 1)",
        fontSize: "1.5rem",
        top: "-25px",
      },
    },
    "& .MuiNativeSelect-select": {
      height: "46px",
      padding: "0px 18px",
      "&:hover": {
        bgcolor: "rgba(224,224,224,0.6)",
        borderRadius: "4px",
      },
    },
    "& .MuiInput-root": {
      height: "46px",
      width: "100%",
      border: "1px solid rgba(224,224,224,1)",
      marginTop: "0",
      borderRadius: "4px",
      "&::before, &::after": {
        content: "unset",
      },
    },
    "& 	.MuiButton-root": {
      textTransform: "capitalize",
      height: "46px",
      border: "1px solid rgba(224,224,224,1)",
      color: "#101010",
      flexDirection: "row-reverse",
      justifyContent: "space-around",
      "& span": {
        margin: "0",
      },
      ":hover": {
        background: "rgba(0,0,0,0.1)",
      },
    },
  },
};

export const dialogStyles = {
  maxWidth: "sm",
  title: {
    fontSize: "2rem",
    fontWeight: "700",
    color: "#101010",
  },
  content: {
    fontSize: "1.5rem",
    color: "#101010",
  },
  box: {
    "& .MuiDialog-paper": {
      padding: "3rem",
    },
  },
  cancelButton: "rgba(36, 35, 35, 0.5)",
  ctaButton: "#101010",
  display: "none",
};

export const MenuProps = {
  sx: {
    "& .MuiMenu-list": {
      "& .Mui-disabled": {
        display: "none",
      },
    },
  },
};
