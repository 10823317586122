import * as React from 'react';

import { NavLink } from 'react-router-dom';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import Navigation from './Navigation';
import NavigationPublic from './NavigationPublic';

const MainListItems = (props) => {
  const classes = useStyles();
  let nav = props?.type ? NavigationPublic : Navigation;

  return (
    <Box sx={classes.menuItems}>
      {nav.map((nav, i) => {
        return <ChildrenItems key={i} menu={nav} />;
      })}
    </Box>
  );
};

const ChildrenItems = ({ menu, context }) => {
  return (
    <NavLink
      exact={true}
      to={menu.url}
      className='liNavLink'
      activeStyle={{
        borderBottom: '4px solid #80BB57',
        color: '#80BB57',
      }}
    >
      <Typography variant='h2' sx={{ cursor: 'pointer', fontSize: '1.5rem' }}>
        {menu.title}
      </Typography>
    </NavLink>
  );
};

export { MainListItems };

const useStyles = (theme) => ({
  menuItems: {
    display: { xs: 'none', md: 'flex' },
    width: { md: '80%' },
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
