/* eslint-disable react/jsx-no-duplicate-props */
// @vendors
import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from '@mui/material'

// @style
import { StyleAPiClientModal } from './StyleApiClient'

const ModalToken = ({openModal, onClose, token}) => {
    const stylesModal = StyleAPiClientModal()

  return (
    <>
        <Dialog
            open={openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth
            maxWidth='lg'
            sx={stylesModal.dialogToken}
        >
            <Grid container sx={stylesModal.container}>
            <DialogTitle sx={stylesModal.DialogTitle} >
                <Grid sx={stylesModal.divTitle}>
                    <h6 style={stylesModal.title}>Please copy this token, it is not possible to review it later</h6>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{overflowX: 'hidden', margin:0, padding:0}}>
                <Grid container sx={stylesModal.modal}>
                    <div style={stylesModal.containerBody}>
                        <p style={stylesModal.token}>
                            {token ?? ''}
                        </p>

                    </div>
                </Grid>
            </DialogContent>
            <DialogActions sx={stylesModal.DialogTitle}>
                <Grid sx={stylesModal.divButton}>
                    <Button
                        sx={stylesModal.button}
                        onClick={() => onClose()}
                        >
                    Done
                    </Button>
                </Grid>
            </DialogActions>
            </Grid>
        </Dialog>
    </>
  )
}

export default ModalToken