import { types } from "../types";

const initialState = {
    page: 1,
    size: 20,
    totalRows: 0
}

const paginationReducer = (state= initialState, action)=> {
    switch (action.type) {
        case types.PAGINATION:
          return {
            ...state,
            page: action.page,
            size: action.size,
            totalRows: action.totalRows
        };

        default:
          return state;
        }
}
export default paginationReducer