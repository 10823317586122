import AppServiceNewApi from "./AppServiceNewApi";

class SubmitterServiceNewApi extends AppServiceNewApi {
  constructor() {
    super();
    this.path = "/api/internal/v1";
  }

  sendFormToState(id, userId) {
    return this.http.get(
      `${this.path}/submitter/send-form-to-state/${id}/${userId}`
    );
  }
}
export default SubmitterServiceNewApi;
