// @vendors
import React, { useEffect, useState } from 'react';

// @context
import { useUI } from '../../../app/context/ui';

// @assets
import { EmployerStyles } from '../../../assets/css/employer-style';
import { StatsStyles } from '../../../assets/css/statsBoxes-style.js';

// @components material
import {
  AssignmentTurnedInOutlinedIcon,
  Card,
  CardContent,
  Grid,
  PersonAddAlt1OutlinedIcon,
  PersonRemoveOutlinedIcon,
  Typography,
} from '../../../components/shared/MaterialUI.js';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @components
import EmployerProfilePic from './EmployerProfilePic';

// @services
import StatsServiceNewApi from '../../../services/newApi/StatsServiceNewApi.js';

const EmployerStats = ({ id }) => {
  const statsServices = new StatsServiceNewApi();
  const [stats, setStats] = useState({});
  const { snackbarUI } = useUI();
  const styles = EmployerStyles();
  const stylesStas = StatsStyles();
  let boxes = [
    {
      title: 'Number of employees',
      type: 'employees',
      icon: <PersonAddAlt1OutlinedIcon sx={styles.statsIcon} />,
    },
    {
      title: 'Completed questionnaires',
      type: 'questionnaireCompleted',
      icon: <AssignmentTurnedInOutlinedIcon sx={styles.statsIcon} />,
    },
    {
      title: 'Abandoned questionnaires',
      type: 'questionnaireAbandoned',
      icon: <PersonRemoveOutlinedIcon sx={styles.statsIcon} />,
    },
  ];

  const getStats = async (id) => {
    try {
      const response = await statsServices.getStats('', id);
      const data = response?.data?.data;
      setStats({ ...data });
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getStats(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Grid
      component={'section'}
      disableGutters 
      sx={stylesStas.container}
    >
      <EmployerProfilePic id={id}/>
      {React.Children.toArray(
      boxes.map((box) => {
        return (
        
            <Card elevation={3} sx={styles.statsBox}>
              {box.icon}
              <CardContent>
                <Typography>{box.title}</Typography>
                <Typography>{stats[box.type] || 0}</Typography>
              </CardContent>
            </Card>
        );
      })
    )}
    </Grid>
  );
};

export default EmployerStats;
