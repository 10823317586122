// @vendors
import React, { useContext, useState } from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

// @components material
import {
    AccountCircleRoundedIcon,
    Box,
    Button, 
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    PhotoCamera,
    TextField,
    Typography,
    Visibility,
    VisibilityOff,
} from '../../../components/shared/MaterialUI';

// @assets
import { EmployeeTableStyles, OrganizationStyle } from '../../../assets/css/employeeTable-style';
import { CreateEmployeeStyles } from '../../../assets/css/createEmployee-style';
import { CreateEmployerStyles } from '../../../assets/css/createEmployer-style';

// @components
import { AppTextField } from '../../../components/forms';
import { Organization } from '../OrganizationScreen';

// @context
import { useUI } from '../../../app/context/ui';

// @services
import OrganizationServiceNewApi from '../../../services/newApi/OrganizationService';

// @helperts
import AppHelper from '../../../helpers/AppHelper';

const AddNewOrganization = ({
    open,
    onClose,
    isEdit,
    data
}) => {
    const styles = EmployeeTableStyles();
    const styleForm = CreateEmployeeStyles();
    const userStyle = CreateEmployerStyles();
    const styleOrganization = OrganizationStyle();
    const {dialogUI, blockUI, snackbarUI} = useUI();
    const organizationService = new OrganizationServiceNewApi();
    const {getListOrganization } = useContext(Organization);
    const [updateLogo, setUpdateLogo] = useState(false);
    const [currentLogo, setCurrentLogo] = useState('');
    const [logoToSend, setLogoToSend] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [enablePassword, setEnablePassword] = useState(false);

    const initialValues = {
        name: isEdit ? data?.name : '',
        owner: isEdit ? data?.owner : '',
        infoEmail: isEdit ? data?.infoEmail : '',
        senderEmail: isEdit ? data?.senderEmail : '',
        homeUrl: isEdit ? data?.homeUrl : '',
        password: '',
    }

    const validationEmployee = Yup.object({
        name: Yup.string('Enter your name')
          .required('Name is required')
          .nullable()
          //.matches(/^[a-zA-ZÀ-ÿ -\s']+$/, "Not numbers allowed")
          .typeError(`Name can't be empty`),
        owner: Yup.string('Enter your owner')
          .required('Owner is required')
          .nullable()
          .typeError(`Owner can't be empty`),
        infoEmail: Yup.string('Enter the info email')
          .email('Invalid email')
          .max(50, 'Max. 50 characters')
          .required('Info email is required')
          .typeError(`Info email can't be empty`),
        senderEmail: Yup.string('Enter the sender email')
          .email('Invalid email')
          .max(50, 'Max. 50 characters')
          .required('Sender email is required')
          .typeError(`Sender email can't be empty`),
        homeUrl: Yup.string('Enter your name')
            .nullable()
            .required('Home url is required')
            .typeError(`Home url can't be empty`),
        password: !isEdit || enablePassword
            ? Yup.string('Enter your password')
                .min(8, 'Minimum 8 characters')
                .matches(
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/,
                  'Must have at least one Uppercase, one symbol and one number'
                )
                .required('Password is required')
            : Yup.string()
                .nullable()
                .notRequired(),
    });

    const onSubmit = (values) => {
        const OBJ = {
            name: values?.name,
            status: 'enable',
            owner: values?.owner,
            infoEmail: values?.infoEmail,
            senderEmail: values?.senderEmail,
            homeUrl: values?.homeUrl,
            password: passwordValue(values.password)
        }
        return adOrUpdateOrganization(OBJ);
    }

    const passwordValue = (value) => {
        if(isEdit && !enablePassword)return null;
        if(!value)return null
        return value;
    }
    
    const adOrUpdateOrganization = async (valuesForm) => {
        try {
            blockUI.current.open(true);
            const response = isEdit ? await organizationService.updateOrganization(data?.id, valuesForm) : await organizationService.createOrganization(valuesForm);
            if(updateLogo)editLogo(response?.data?.data?.id);
            if(!updateLogo)getListOrganization(true);
            handleCloseModal();
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI);
        }
    }

    const editLogo = async (id) => {
        const formdata = new FormData();
        formdata.append('logo', logoToSend); 
        try {
            blockUI.current.open(true);
            await organizationService.addLogo(id, formdata)
            getListOrganization(true);
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI);
        }
    }

    const handleCloseModal = () => {
        setCurrentLogo();
        setUpdateLogo(false);
        setLogoToSend();
        dialogUI.current?.close();
        onClose();
    }

    const uploadFile = (e, id) => {
        const img = e.target.files[0];
        setUpdateLogo(true);
        setLogoToSend(img);
        const image = URL.createObjectURL(img);
        setCurrentLogo(image)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

  return (
    <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        sx={styles.dialog.dialogBox}
    >
        <DialogTitle
            textAlign="center"
            sx={styles.dialog.title}
        >
            {isEdit ? 'Edit Organization' : 'Add New Organization'}
        </DialogTitle>
        <DialogContent>
            <Grid sx={styleOrganization.containerFormOrganization} >
                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        margin: '0 0 30px 0'
                    }}>
                    <Box sx={styleOrganization.uploadProfilePic}>
                        {data?.logo || currentLogo
                            ?   <img src={currentLogo ? currentLogo : data?.logo} alt='Organization logo'/>
                            :   <AccountCircleRoundedIcon sx={styleOrganization.iconBackground}/>
                        }
                        
                        <label htmlFor='icon-button-file'>
                            <input
                                accept='image/*'
                                id='icon-button-file'
                                type='file'
                                style={{ display: 'none', zIndex:100 }}
                                onChange={(e) => uploadFile(e)}
                            />
                            <IconButton
                                aria-label='upload picture'
                                component='span'
                                sx={styleOrganization.iconCamera}
                            >
                                <PhotoCamera />
                            </IconButton>
                        </label>
                    </Box>
                </Grid>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationEmployee}
                    onSubmit={(values) => { onSubmit(values) }}
                    enableReinitialize={true}
                >
                    {(props) => {
                        const { touched, errors, handleBlur, handleChange, setFieldValue } = props;
                        return (
                            <Form autoComplete="off" className="formCreateUser">
                                <Box sx={{...styleForm.inputsCtn, gridGap: '10px',}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                                <p>Name: <span style={{color: "red"}}>*</span> </p>
                                                <AppTextField
                                                    sx={styleForm.input}
                                                    label="Type your name here"
                                                    variant="outlined"
                                                    id="name"
                                                    name="name"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                                <p>Owner: <span style={{color: "red"}}>*</span> </p>
                                                <AppTextField
                                                    sx={styleForm.input}
                                                    label="Type your owner here"
                                                    variant="outlined"
                                                    id="owner"
                                                    name="owner"
                                                    />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                                <p>Home Url: <span style={{color: "red"}}>*</span> </p>
                                                <AppTextField
                                                    sx={styleForm.input}
                                                    label="Type your home url here"
                                                    variant="outlined"
                                                    id="homeUrl"
                                                    name="homeUrl"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                                <p>Sender Email: <span style={{color: "red"}}>*</span> </p>
                                                <AppTextField
                                                    sx={styleForm.input}
                                                    label="example@email.com"
                                                    variant="outlined"
                                                    autoComplete="new-password"
                                                    id="senderEmail"
                                                    name="senderEmail"
                                                    type="email"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                                <p>Info Email: <span style={{color: "red"}}>*</span> </p>
                                                <AppTextField
                                                    sx={styleForm.input}
                                                    label="example@email.com"
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        handleChange(e);
                                                        if(!isEdit)return;
                                                        if(isEdit && value?.toString() !== data?.infoEmail?.toString())return setEnablePassword(true);
                                                        if(isEdit && value?.toString() === data?.infoEmail?.toString()){
                                                            setFieldValue('password', '');
                                                            setEnablePassword(false);
                                                            return
                                                        }
                                                        return setEnablePassword(false);
                                                    }}
                                                    autoComplete="new-password"
                                                    id="infoEmail"
                                                    name="infoEmail"
                                                    type="email"
                                                />
                                            </Box>
                                        </Grid>

                                        {!isEdit || enablePassword
                                            ?   <Grid item xs={12} md={6}>
                                                    <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                                        <p>Password: <span style={{color: "red"}}>*</span> </p>
                                                        <Box sx={{ position: 'relative'}}>
                                                            <TextField
                                                                label="Password"
                                                                variant="outlined"
                                                                autoComplete="new-password"
                                                                id="password"
                                                                name="password"
                                                                type={showPassword ? 'text' : 'password'}
                                                                onChange={(e) => handleChange(e)}
                                                                onBlur={handleBlur}
                                                                error={touched.password && Boolean(errors.password)}
                                                                sx={{...styleForm.input }}
                                                            />

                                                            <IconButton
                                                                tabIndex={-1}
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword((prev) => !prev)}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                sx={userStyle.showPassword}
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>

                                                            {errors.password && touched.password && (
                                                                <Typography sx={userStyle.stateError}>{errors.password}</Typography>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            :   null
                                        }
                                        
                                    </Grid>
                                </Box>
                                <Box sx={styleForm.formButtonWrapper}>
                                    <Button
                                        color="inherit"
                                        onClick={() => handleCloseModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit">{isEdit ? 'Edit' : 'Create'}</Button>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </DialogContent>
    </Dialog>
  )
}

export default AddNewOrganization