// @vendors
import React, { createContext, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// @components
import SearchEmployeeTable from './SearchEmployeeTable';
import { StatsBoxes } from '../Admin/components/StatsBoxes';

// @context
import { useUI } from '../../app/context/ui';

// @services
import EmployeeCompanyServiceNewApi from "../../services/newApi/EmployeeCompanyServiceNewApi";

// @helpers
import AppHelper from '../../helpers/AppHelper';

//@assets
import { EmployerStyles } from '../../assets/css/employer-style';

// @constants
import { types } from '../../redux/types';

const SearchEmployees = () => {
  const { blockUI, snackbarUI } = useUI();
  const employeeByCompanyService = new EmployeeCompanyServiceNewApi()
  const styles = EmployerStyles();
  const pageEmployer = 0;
  const dispatch = useDispatch()
  const state = useSelector((loadedState) => loadedState);
  const sort = state.sortEmployeeReducer;
  const organizationId = state?.organizationReducer?.organizationSelected?.id

  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    page: pageEmployer,
  });

  const [pages, setPages] = useState({
    page:0,
    size:10
  });

  const [search, setSearch] = useState({
    name: '',
    status: '',
  });

  const getAllEmployesByCompany = async (params) => {
    const paginattion = {page: params?.page ?? 1, size: params?.size ?? pages.size};
    const filters = params?.filters ?? search;
    const sortBy = {order: params?.sort ?? sort.sort, direction: params?.direction ?? sort.direction};
    try {
      blockUI.current.open(true);
      const response = await employeeByCompanyService.getEmployeesByCompany('', organizationId, paginattion, filters, sortBy);
      const data = response?.data
      updateListData(data)
    } catch (e) {
      blockUI.current?.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const updateListData = (data) => {
    setPages((prev) => ({
      ...prev,
      page: data?.pagination?.page_number,
      size: data?.pagination?.page_size
      ,
    }));
    setRowsState((prev) => ({
      ...prev,
      pageSize: data?.pagination?.page_size || 10,
      rows: data.data,
      rowCount: data?.pagination?.total_records,
      page: data?.pagination.page_number - 1,
    }));
    setTimeout(() => {
      blockUI.current.open(false);
    }, 1000);
  }

  useEffect(() => {
    dispatch({
      type: types.ID_EMPLOYEE_SELECTED,
      detail: ''
    })
    dispatch({
      type: types.SORT_EMPLOYEE,
      sort: '',
      direction: ''
    })
    getAllEmployesByCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      component="section"
      disableGutters
      sx={styles.dashCtn}
    >
      <Grid>
        <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "bold" }}>
          List all employees
        </Typography>
      </Grid>
      <Grid>
        <StatsBoxes />
      </Grid>
      <SearchEmployeeContext.Provider
        value={{
            rowsState,
            pages,
            search,
            setRowsState,
            setPages,
            setSearch,
            getAllEmployesByCompany
        }}
      >
        <SearchEmployeeTable />
      </SearchEmployeeContext.Provider>
    </Grid>
  )
}

export const SearchEmployeeContext = createContext({});
export default SearchEmployees