import AppServiceNewApi from "./AppServiceNewApi";

class UnsubscribeService extends AppServiceNewApi{
    constructor() {
        super();
        this.path = '/api/internal/v1/employees/subscribe';
    }
    unsubscribe(id, token) {
        return this.http.patch(`${this.path}/${id}`, {}, {headers: {'token': token}});
    }
}
export default UnsubscribeService