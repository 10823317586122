// @vendors
import React, {useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";

// @material-ui
import {Box} from '../../../components/shared/MaterialUI';

// @state
import {QuestionnaireContext} from '../Prescreening';
import { useUI } from '../../../app/context/ui';

// @assets
import { EmployeeDetailStyles } from '../../../assets/css/employeeTable-style';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

const styles = EmployeeDetailStyles();

const GratRb = () => {
  const {initial, t} = useContext(QuestionnaireContext);
  const {messages} = initial;
  const { dialogUI } = useUI();
  const history = useHistory();

  useEffect(() => {
    dialogUI.current.open(
      t("question.great"),
      messages,
      {
        confirm: true,
        btn: {
          confirm: "Close",
          close: "",
        },
        onConfirm: () => {
          dialogUI.current.close();
          history.push(ROUTENAME.dashboard);
        },
        styles: { ...styles.dialog },
      }
    );
  }, [messages, t, dialogUI, history]);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          left: '0',
          top: '8vh',
          display: 'flex',
          height: '84vh',
          width: '100vw',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgb(249, 249, 249)',
          padding: '200px 24px',
        }}
      >
      </Box>
      <Box
        sx={{
          position: 'fixed',
          left: '0',
          top: '92vh',
          height: '8vh',
          width: '100vw',
          background: 'black',
        }}
      />
    </>
  );
};

export default GratRb;