import React, {
  forwardRef,
  useImperativeHandle,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

const SnackBarUI = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const onClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: (message) => {
      setOpen(true);
      setMessage(message);
    },
    close: () => onClose(),
  }));

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      message={message}
      action={
        <React.Fragment>
          <IconButton size='small' aria-label='close' color='inherit' onClick={onClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </React.Fragment>
      }
      sx={{
        '& .MuiSnackbarContent-root': {
          padding: '16px 65px 16px 20px',
          position: 'relative',
        },
        '& .MuiSnackbarContent-message': {
          fontSize: '1.25rem',
        },
        '& .MuiSnackbarContent-action': {
          position: 'absolute',
          margin: '0',
          padding: '0',
          right: '20px',
          top: '20px',
          backgroundColor: 'rgba(255,255,255, 0.2)',
          borderRadius: '50%',
          '& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
            fontWeight: 700,
          },
        },
      }}
    />
  );
});

export default SnackBarUI;
