export function editEmployerReducer(state, { type, payload }) {
  switch (type) {
    case 'setStates': {
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          states: payload,
        },
      };
    }
    case 'setCounties': {
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          counties: payload,
        },
      };
    }
    case 'setCities': {
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          cities: payload,
        },
      };
    }
		case 'setOccupations': {
			return {
				...state,
				information: {
					...state.information,
					occupations: payload,
				},
			};
		}
		case 'setInitialInformation': {
			return {
				...state,
				geoLocation: {
					...state.geoLocation,
					...payload.geoLocation,
				},
				information: {
					...state.information,
					...payload.information,
				},
			};
		}	
    default:
      return state;
  }
}

export const initialState = {
  geoLocation: {
    states: [],
    counties: [],
    cities: [],
  },
	information: {
		occupations: [],
	}
};
