import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';

import HeaderPublic from '../../components/HeaderPublic';

const Services = (props) => {
  const classes = useStyles();

  useEffect(() => {
    document.title = `RockerBox - ${props.title}`;
  });

  return (
    <>
      <HeaderPublic />
      <div className={classes.wrapperBody}>Services</div>
    </>
  );
};

export default Services;

const useStyles = makeStyles((theme) => ({
  wrapperBody: {
    marginTop: '100px',
  },
}));
