import React from 'react';
import {
  Checkbox,
  FormControlLabel, FormGroup, FormHelperText,
} from '@mui/material';
import {useField} from 'formik';
import {at as _at } from 'lodash';

export default function AppCheckbox(props) {
  const {
    options = [
      {'label': 'Yes', 'value': 'yes', 'disabled': false},
      {'label': 'No', 'value': 'no', 'disabled': false},
    ],
    label, ...rest
  } = props;
  const [field, meta, helper] = useField(props);
  const {value: formikValue} = field;
  const {setValue} = helper;
  const {sx} = rest;

  function _renderHelperText() {
    const [touched, error] = _at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText error={true}>{error}</FormHelperText>;
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <FormGroup {...rest}>
        {options.map(({value, disabled, label, ...rest}, i) => {
          return (
            <FormControlLabel key={value}
                              value={value}
                              checked={formikValue ? formikValue.indexOf(value) !== -1: false}
                              disabled={disabled}
                              control={<Checkbox sx={{...sx, margin: '0px', width: 50}} color="success" onChange={handleChange} />}
                              label={label}
                              {...rest}
            />
          );
        })}
      </FormGroup>
      {_renderHelperText()}
    </>
  );
}
