import { theme } from "../theme";

export const PayrollStyle = () => ({
    containerPayroll: {
        color: '#101010',
        padding: '0 48px',
        minHeight: '90vh',
        [theme.breakpoints.down('md')]: {
          padding: '0px',
        },
    },
    breadcrumbs: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 0 30px 0'
    },
    breadcrumbsText:{
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '12px',
        color: '#252A31',
        textDecoration: "none",
    },
    separatorBreadcrumbs:{
        color: '#BAC7D5',
        width: '18px'
    },
    breadcrumbsTextSelected:{
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '12px',
        color: '#252A31'
    },
    btnFilter:{
        backgroundColor: '#fff',
        color: '#000',
        display: 'flex',
        height: 32,
        border: 'none',
        textTransform: 'none',
        boxShadow: '0px 2px 2px 0px rgb(36 35 35 / 15%)',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '12px',
        '&:hover':{
            backgroundColor: '#fff'
        }
    },
    iconBtnFilter:{
        color: '#00A58E',
        width: '15px'
    },
    title: {
        fontSize: { xs: "20px", md: "28px" },
        fontWeight: "bold" 
    },
    containerToolbar:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    contSearchAndExcel: {
        display: 'flex',
        gap: 20
    },
    fieldSearch:{
        height: 32,
        color:'#5F738C',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Inter'
    },
    iconSearch:{
        color:'#5F738C',
        width: '17px',
    },
    btnExcel:{
        display: 'flex',
        height: 32,
        border: 'none',
        backgroundColor: '#ECF8F7',
        color: '#007F6D',
        textTransform: 'none',
        fontSize: '12px',
        fontWeight: 500,
        fontFamily: 'Inter',
        boxShadow: 'none',
        '&:hover':{
            backgroundColor: '#ECF8F7'
        }
    },
    toolbar: {
        color: '#4caf50 !important',
        "& .MuiTab-textColorInherit":{
            color: '#4F5E71'
        },
        '& .Mui-selected':{
            color: '#252A31',
            fontWeight: 500
        },
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#00A58E',
        },
    },
    toolbarItem: {
        textTransform: 'none',
        fontSize: '14px', 
    },
    tableContainer: {
        minHeight: "700px",
        maxHeight: "800px",
    },
    divTableAndAction:{
        display: "grid",
        maxWidth: "100%",
        gridTemplateColumns: "90% 10%",
        overflowX: "hidden !important"
    },
    divTable:{
        overflow: "auto",
    },
    divTableCellAction:{
        backgroundColor: "#DAF8EE !important"
    },
    tableRow: {
        height: "90px",
        display: 'flex',
        padding: '0'
    },
    tableHeadTitle: {
        display: 'flex !important',
        justifyContent: 'start',
        fontFamily: 'Inter',
        fontWeight: 700,
        alignItems: "center !important",
        fontSize: "14px",
        color:'#252A31',
        width: '200px !important',
    },
    tableCellRow: {
        fontFamily: 'Inter',
        fontWeight: 400,
        textAlign: "start !important",
        fontSize: "14px",
        flex: 10,
        height: '53px',
    },
    tableHeadTitleAction:{
        fontFamily: 'Inter',
        textAlign: "center !important",
        fontSize: "14px",
        fontWeight: 700,
        height: '89px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color:'#252A31',
    },
    containerIcons:{
        display: 'flex',
        gap: '15px',
        justifyContent: 'center',
        alignItems: 'center',
        //height: {xs: '52.8px', md: '52.8px', lg:'53px'},
        height: '53px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    icons:{ },
    iconMessageAndEdit:{
        margin:0,
        color: '#00A58E',
        width: '20px'
    },
   contaierPagination:{
        width: '100%',
        display:'flex',
        justifyContent: 'end',
        marginTop: '20px'
   }
})

export const DialogFilterPayroll = () => ({
    dialog:{
        '& .MuiDialog-paper': {
            width: '350px',
            backgroundColor: '#fff',
            minHeight: '550px',
            padding: '24px',
            borderRadius: '9px'
        },
    },
    title:{
        margin: '0 0 20px 0',
        color: '#252A31',
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '16px'
    },
    body: {
        minHeight: '500px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column'
    },
    form: {
        flex: 1
    },
    marginDown:{
        marginBottom: '15px'
    },
    divActions: {
        height: '50px',
        gap: '20px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'end'
    },
    buttonCancel:{
        display: 'flex',
        height: '32px',
        padding: '8px 12px',
        backgroundColor: '#E8EDF1',
        color: '#252A31',
        borderRadius: '3px',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '12px',
        textTransform: 'none',
        "&:hover":{
            backgroundColor: '#E8EDF1'
        }
    },
    buttonApply:{
        display: 'flex',
        height: '32px',
        padding: '8px 12px',
        backgroundColor: '#00A58E',
        color: '#FFFFFF',
        borderRadius: '3px',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '12px',
        textTransform: 'none',
        "&:hover":{
            backgroundColor: '#00A58E'
        }
    },
    fieldsInput:{
        marginTop: '10px',
        width: '100%',
        '& .MuiTextField-root':{
            borderRadius:'3px',
            height: 22,
            border: '1px solid #BAC7D5',
        },
        '& .MuiInputBase-input':{
            borderRadius:'3px',
            height: 22,
            border: '1px solid #BAC7D5',
        },
        '.MuiSelect-outlined':{
            height: 22,
        }
    },
    label:{
        margin: 0,
        width: '100%',
        color: '#252A31',
        fontFamily: 'Inter',
        fontWeight:500,
        fontSize: '14px'
    },
    menuItem: {
       
    },
    formGroup:{
        display: 'flex'
    },
    checkBox:{
        color: '#BAC7D5',
        '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
    },
    textSelect:{
        
        '& .MuiListItemText-root':{
            fontSize:'43px',
            
        },
        '.MuiListItemText-primary':{
            fontSize:'13px',
            fontFamily: 'Inter',
            fontWeight:400,
            color: '#252A31',
        }
    },
    to:{
       margin:0,
       height:'32px',
       color: '#5F738C',
       fontFamily: 'Inter',
       fontWeight: 400,
       fontSize: '14px',
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
    },
    labelCheckbox:{
        '& .MuiFormControlLabel-label':{
            color: '#252A31',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px'
        }
    },
    radio:{
        color: '#BAC7D5',
    },
    datePicker:{
        backgroundColor: 'aqua !important',
        '& .MuiCalendarPicker-root': {
            backgroundColor: 'blue',
            height: 50
        }
    },
    otherValuesForGroups:{
        color: '#00A58E'
    }
})

export const NotesPayrollStyle = () => ({
    containerNotes: {
        backgroundColor: '#FFF',
        width: {xs: '80%', md: '250px', lg: '250px'},
        minHeight: '180px',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px'
    },
    title:{
        color: '#252A31',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize:'14px',
        margin: 0
    },
    textTarea:{
        border: '1px solid #BAC7D5',
        borderRadius: '3px',
        marginTop: '10px',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        color: '#697D95',
        padding: 10,
        '&:active':{
            border: '1px solid #BAC7D5',
            backgroundColor: 'aqua'
        }
    }
})

export const EditPayrollStyle = () => ({
    dialogEdit:{
        '& .MuiDialog-paper': {
            width: '100%',
            backgroundColor: '#fff',
            minHeight: '350px',
            padding: '24px',
            borderRadius: '9px',
            boxSizing: 'border-box !important',
            overflow: 'hidden'
        },
    },
    body:{
        display:'flex',
        width: '100%',
        boxSizing: 'border-box !important',
        flexDirection: 'column'
    },
    title:{
        color: '#252A31',
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '16px',
        margin: '0 0 10px 0'
    },
    table:{
        overflow: 'auto',
    },
    tableRowEdit: {
        minHeight: "40px", 
        display: 'flex',
        minWidth: '100%'
    },
    tableHeadTitleEdit: {
        display: 'block !important',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: "14px",
        color:'#252A31',
        minWidth: '200px !important',
    },
    tableCellRow: {
        fontFamily: 'Inter',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        textAlign: "start !important",
        fontSize: "13px",
        width: '100%',
        color: '#252A31'
    },
    containerFormsHoursAndWage:{
        width:'100%',
        overflowX: {xs: 'scroll', md: 'hidden', lg: 'hidden'}
    },
    formsHoursAndWage:{
        width:{xs:'800px', md: '100%', lg:'100%'}
    },
    containerNameMonth:{
        display:'flex',
        margin: '25px 0 0 0',
    },
    nameMonth:{
        width: '100%',
        boxSizing: 'border-box',
    },
    month: {
        display: 'block !important',
        width: '100% !important',
        color: '#252A31',
        fontWeight: 600,
        fontFamily: 'Inter',
        fontSize: '14px',
    },
    hourWage:{
        display:'flex',
        width: '80px !important',
        color: '#252A31',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '14px',
        justifyContent: 'end',
        alignItems: 'end',
        padding: '0 10px 10px 0'
    },
    fieldsInput:{
        fontSize: '13px',
        fontWeight: 400,
        fontFamily: 'Inter',
        width: '100%',
        color: '#252A31',
        '& .MuiTextField-root':{
            borderRadius:'3px',
            height: 12,
            border: '1px solid #BAC7D5',
        },
        '.MuiSelect-outlined':{
            height: 22,
            width: '100px',
        }
    },
    containerForms:{
        display:'flex',
        margin: '0px 0 0 0 ',
    },
    containerFormHours:{
        display:'flex',
        margin:0,
        justifyContent: 'start'
    },
    spacingItemForm:{
        padding: '0 10px 0 0'
    },
    divider: {
        width: '100%',
        color: '#BAC7D5',
        margin: '10px 0'
    }
})

export const MenuExcelStyle = () => ({
    containerMenu:{
        '& .MuiMenu-paper':{
            backgroundColor: 'FFFFFF',
            borderRadius: '3px',
        }
    },
    menuItem:{
        margin:'0px 10px',
        '&.MuiMenuItem-root':{
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: 500,
            color: '#252A31'
        },
        '&:hover':{
            borderRadius: '3px'
        }
    }
})

export const TableInvoiceStyle = () => ({
    table:{
        display: 'flex',
        flexDirection: 'column',
    },
    tableRow:{
        height: "60px",
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    tableHeadTitleInvoice: {
        display: 'flex !important',
        justifyContent: 'start',
        fontFamily: 'Inter',
        fontWeight: 700,
        alignItems: "center !important",
        fontSize: "14px",
        color:'#252A31',
        width: '100%'
    },
    tableCellRow: {
        fontFamily: 'Inter',
        fontWeight: 400,
        textAlign: "start !important",
        fontSize: "14px",
        flex: 10,
        height: '53px'
    },
    tableGrid: {
        minHeight: 500,
        fontSize: '14px',
        width: '100%',
        display: 'flex',

        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeaders' : {
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        },
        '& .MuiDataGrid-columnHeadersInner': {
          fontSize: '14px',
          [theme.breakpoints.down('md')]: {
            fontSize: '14px',
          },
          '& .MuiDataGrid-columnHeader': {
            padding: '0',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
            paddingLeft: '22px',
            color: '#252A31',
            fontFamily: 'Inter'
          },
        },
        '& .MuiDataGrid-row': {
          fontSize: '14px',
          paddingLeft: '15px',
          fontFamily: 'Inter',
          color: '#252A31',
          fontWeight: 400,
          [theme.breakpoints.down('md')]: {
            fontSize: '14px',
          },
        },
        '& .MuiDataGrid-cell': {
          display: 'flex',
          alignItems: 'center',
          lineHeight: '30px',
        },
        '& .MuiDataGrid-virtualScroller': {
          [theme.breakpoints.down('sm')]: {
            marginTop: '0 !important',
          },
          '::-webkit-scrollbar': {
            width: '10px',
          },
          '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'rgba(16, 16, 16, 0.5)',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
        '& 	.MuiDataGrid-checkboxInput': {
          '& .MuiSvgIcon-root': {
            fontSize: '14px',
            [theme.breakpoints.down('md')]: {
              fontSize: '12px',
            },
          },
        },
        '& .MuiDataGrid-sortIcon':{
            color: '#0172CB',
        }
      },
})

export const ModalConfirmPayrollStyle = () => ({
    dialog:{
        '& .MuiDialog-paper': { 
            width: '80%', maxHeight: 435,
            borderRadius: '9px'
        }
    },
    body:{
        padding: '40px'
    },
    title:{
        fontSize: "14px",
        display: 'flex',
        fontWeight: 700,
        color: '#252A31',
        fontFamily: 'Inter',
        margin: '0px',
        textAlign: 'center',
    },
    containerButtons:{
        width: '100%',
        display: 'flex',
        gap: '15px',
        justifyContent: 'end',
        marginTop: '30px'
    },
    styleCommontButtom: {
        fontSize: '12px',
        textTransform: 'none',
        fontWeight: 500,
        fontFamily: 'Inter',
        width: '80px',
        height: '32px',
        padding: '8px 12px',
        letterSpacing: '0.03333em',
        border: 'none',
        borderRadius: 4
    },
    buttonAccept: {
        color: '#fff',
        backgroundColor: '#00A58E',
        '&:hover': {
            backgroundColor: '#00A58E !important',
            boxShadow: 'none'
        },
    },
    buttonClose: {
        color: '#252A31',
        backgroundColor: '#E8EDF1',
        '&:hover': {
            backgroundColor: '#BAC7D5',
        },
    },
})