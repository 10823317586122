import React from 'react';

import {CreateEmployeeStyles} from "../../assets/css/createEmployee-style";
import {
  Box,
  Button,
  Container,
  Paper,
} from "@mui/material";

const AccountCreated = ({message}) => {
  const styles = CreateEmployeeStyles();
  
  return (
    <Container
      maxWidth='xl'
      sx={{display: 'flex', minHeight: '80vh', justifyContent: 'center', alignItems: 'center'}}
    >
      <Paper
        elevation={0}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          padding: {xs: '0 24px', md: '0 48px'},
          width: {xs: '90%', md: '50%'},
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h1>Account Created!</h1>
        </Box>
        <Box>
          <>
            <p>The account has been created successfully.</p>
            {message?.sendEmailWhenCreated && <p>An email with the link to the questionnaire has been sent.</p>}
          </>
        </Box>
        <Box sx={styles.formButtonWrapper}>
          <Button
            color="inherit"
            onClick={() => {
              window.location.href = message?.link;
            }}
          >
            Close
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default AccountCreated;
